// TODO: isDisplayed = true / false for questions in field data table
// TODO: Solve for case where table is not in currentSection
// TODO: Offline quick notes - various scenarios
// TODO: Copy over 0 / 2/ 4 decimal regex from old questionnaire to new questionnaire master
// TODO: Remove row from linked table on removal action
// TODO: UOM & Other question to be handled in getAllDisplayedQuestions - modify the backend logic accordngly
// TODO: which_crop_intercropping_surv_crop, crops_intercropping_with_surv_crop - handle via some displayFunction - DO MORE TESTING!!
// TODO: Handle comma-separated displayFunction key id updates when creating survey
// TODO: Handle migration of data for which_crop_intercropping_surv_crop, crops_intercropping_with_surv_crop
import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { FarmerService } from '../../services/farmer.service';
import { v4 as uuidv4 } from 'uuid';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import Compressor from 'compressorjs';
var DynamicQuestionnaireComponent = /** @class */ (function () {
    function DynamicQuestionnaireComponent(dynamicQuestionnaireService, projectService, commonService, route, toastr, surveyService, userService, farmerService, router, translate, modalService, localeService, spinner, deviceService, locale, http) {
        var _this = this;
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.projectService = projectService;
        this.commonService = commonService;
        this.route = route;
        this.toastr = toastr;
        this.surveyService = surveyService;
        this.userService = userService;
        this.farmerService = farmerService;
        this.router = router;
        this.translate = translate;
        this.modalService = modalService;
        this.localeService = localeService;
        this.spinner = spinner;
        this.deviceService = deviceService;
        this.locale = locale;
        this.http = http;
        this.showSectionForm = false; // Boolean to show / hide the section form
        this.checkedObject = {}; // Object that stores checked = true / false for checkbox questions (non-table)
        this.checkedObjectSecondaryKeys = {}; // Object that stores checked = true / false for checkbox questions (table)
        this.secondary_asset_keys = {}; // Stores the secondary_asset_keys for each table. Format {tableId: [sak1, sak2], table2: [sak3, sak4]}
        this.secondary_asset_key__options = {}; // Stores the secondary_asset_key against the checkbox option that triggered creation of that sak. Format {optionId1: sak1, optionId2: sak2}
        this.notActiveSurvey = 2;
        this.currentUser = this.userService.getCurrentUser();
        this.revisionsInSurvey = [];
        this.selectedRevision = '';
        this.tableOtherQuestionsIsDisplayed = {};
        this.unsavedAnswerCount = 0;
        this.dynamicOptionsObject = {};
        this.subject = new Subject();
        this.displayLogicKeyValues = [];
        this.showSurveyManualIcon = false;
        this.surveyHasReference = false;
        this.showReferenceTab = false;
        this.assetTypes = [
            { type: 'tab', subTypes: [] },
            { type: 'section', subTypes: [] },
            { type: 'subsection', subTypes: [] },
            { type: 'table', subTypes: [{ key: 'question_rows', label: 'Questions in rows' }, { key: 'question_cols', label: 'Questions in columns' }] },
            { type: 'question', subTypes: [{ key: 'text', label: 'Text' }, { key: 'number', label: 'Number' }, { key: 'select', label: 'Select' }, { key: 'multiselect', label: 'Multiselect' }] },
            { type: 'option', subTypes: [] },
            { type: 'uom_question', subTypes: [{ key: '1', label: 'Length' }, { key: '2', label: 'Volume' }, { key: '3', label: 'Weight' }, { key: '4', label: 'Area' }, { key: '5', label: 'Quantity' }] },
            { type: 'other_question', subTypes: [] },
            { type: 'grand_total_question', subTypes: [] },
        ];
        this.processedUOMs = {};
        this.askUserToConfirmExit = false;
        this.farmProfitabilitySummary = { income: { source: 'NA', amount: 'NA' }, expense: { source: 'NA', amount: 'NA' }, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA' };
        this.farmProfitabilitySummaryArray = [];
        this.canDoFarmerMgmt = false;
        this.showHideBackButton = true;
        this.showHideNextButton = false;
        this.totalSectionAnswered = true;
        this.tableAnswersSAKs = {};
        this.tableQuestionAnswered = true;
        this.newlyAddedKeys = [];
        this.cropNameQuestionAnswered = true;
        this.dateFormat = 'DD-MM-YYYY';
        this.dateOpen = false;
        this.monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        this.mandatoryQuestionAnswered = true;
        this.surveysInfo = null;
        this.activeSnapshot = null;
        this.uploadedFiles = [];
        this.translate.get('farmer_unavailable_profiling').subscribe(function (result) {
            _this.farmerUnavailableforProfiling = result;
        });
        this.translate.get('answer_can_not_be_confirmed').subscribe(function (result) {
            _this.answerCanNotBeConfirmed = result;
        });
        this.translate.get('answer_can_not_be_mark_na').subscribe(function (result) {
            _this.answerCanNotMarkNA = result;
        });
        this.translate.get('answer_can_not_be_validate').subscribe(function (result) {
            _this.answerCanNotBeValidate = result;
        });
        this.localeService.use(this.locale);
    }
    DynamicQuestionnaireComponent.prototype.canDeactivate = function () {
        if (this.askUserToConfirmExit) {
            this.saveChangesConfirmModal = this.modalService.show(this.exitConfirmModalTemplate, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
            return this.subject.asObservable();
        }
        return true;
    };
    DynamicQuestionnaireComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.route.paramMap.subscribe(function (params) {
                    _this.projectId = params.get('projectId');
                    _this.route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var request, request2, result, request3, qAnswersForFarmer, paginationLimitsArray, paginationLimits, surveyReq, activeSurveyId, activeSnapshot, snapshotSurveys, getQIds, surveyReq, farmertmpobj;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    // Get the 'raw' farmerid - this can be the farmer id (online registration) or a temporary offline id
                                    this.rawFarmerId = params.get('farmerId');
                                    // Get the farmer id (online registration), if it exists, or set this.farmerId to 0
                                    this.farmerId = +params.get('farmerId') || 0;
                                    if (!(queryParams.get('campaignId') && +queryParams.get('campaignId') !== 0 && !isNaN(+queryParams.get('campaignId')))) return [3 /*break*/, 2];
                                    this.campaignId = queryParams.get('campaignId');
                                    return [4 /*yield*/, this.campaignQuestionnaireFlow()];
                                case 1:
                                    request = _a.sent();
                                    return [3 /*break*/, 10];
                                case 2:
                                    this.spinner.show();
                                    if (!(this.farmerId != 0)) return [3 /*break*/, 8];
                                    if (!navigator.onLine) return [3 /*break*/, 4];
                                    return [4 /*yield*/, Promise.all([
                                            this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                            this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                            this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                            this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                                            this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
                                            this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise(),
                                            this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                                        ])];
                                case 3:
                                    // If user is online, get the answers for farmer
                                    request = _a.sent();
                                    return [3 /*break*/, 7];
                                case 4: return [4 /*yield*/, Promise.all([
                                        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                        this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                        this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                                        this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise(),
                                        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                        this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                                    ])];
                                case 5:
                                    // If user is offline, get the pagination limits and farmer basic data for the project
                                    request = _a.sent();
                                    return [4 /*yield*/, Promise.all([
                                            this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
                                        ])];
                                case 6:
                                    request2 = _a.sent();
                                    _a.label = 7;
                                case 7: return [3 /*break*/, 10];
                                case 8: return [4 /*yield*/, Promise.all([
                                        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                        this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                        this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise()
                                    ])];
                                case 9:
                                    // If farmerId == 0, the farmer has been registered offline
                                    request = _a.sent();
                                    _a.label = 10;
                                case 10:
                                    if (!(request[0]['msg'] !== 'success' || request[1]['msg'] !== 'success' || request[2]['msg'] !== 'success')) return [3 /*break*/, 11];
                                    this.commonService.showToast('error', 'generic_error', {});
                                    return [3 /*break*/, 28];
                                case 11:
                                    this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0], request[2].data[0]);
                                    this.canDoFarmerMgmtFn();
                                    this.setCurrencyCode();
                                    if (request[3]['message'] === 'notActive') {
                                        this.notActiveSurvey = 1;
                                        this.commonService.showToast('warning', 'No Active Survey Found', {});
                                    }
                                    else if (request[3]['message'] === 'notReleasedForLanguage') {
                                        this.notReleasedSurveyInLanguage = 1;
                                    }
                                    else {
                                        // Set the questionnaire object
                                        this.questionnaire = request[3]['message'];
                                        this.setQuestionnaireAttributes();
                                        this.notActiveSurvey = 0;
                                    }
                                    result = void 0;
                                    request3 = void 0;
                                    if (request[6]) {
                                        this.surveysInfo = request[6].message;
                                    }
                                    if (!(this.farmerId != 0)) return [3 /*break*/, 22];
                                    if (!navigator.onLine) return [3 /*break*/, 12];
                                    result = request[4];
                                    return [3 /*break*/, 21];
                                case 12:
                                    // If offline, get answers from getQAnswersForFarmer (for this.farmerId) - this is coming from request2
                                    result = { code: 'success' };
                                    qAnswersForFarmer = void 0;
                                    if (!(request2 && request2[0] && request2[0]['message'])) return [3 /*break*/, 13];
                                    // If request2[0] has a message attribute, getQAnswersForFarmer has been called for this farmer before
                                    qAnswersForFarmer = request2[0];
                                    return [3 /*break*/, 20];
                                case 13:
                                    paginationLimitsArray = request[4].message;
                                    paginationLimits = this.getPaginationLimits(paginationLimitsArray, this.farmerId);
                                    if (!this.campaignId) return [3 /*break*/, 15];
                                    return [4 /*yield*/, Promise.all([
                                            this.dynamicQuestionnaireService.getQAnswersForCampaignPaginated(this.campaignId, paginationLimits[0], paginationLimits[1]).toPromise()
                                        ])];
                                case 14:
                                    request3 = _a.sent();
                                    return [3 /*break*/, 19];
                                case 15: return [4 /*yield*/, Promise.all([
                                        this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                                    ])];
                                case 16:
                                    surveyReq = _a.sent();
                                    activeSurveyId = void 0;
                                    if (surveyReq[0]['code'] === 'success' && surveyReq[0]['message'].length > 0) {
                                        this.surveysInfo = surveyReq[0].message;
                                        activeSnapshot = void 0;
                                        snapshotSurveys = surveyReq[0]['message'].filter(function (item) { return item.survey_type == 'snapshot'; });
                                        if (snapshotSurveys && snapshotSurveys.length > 0) {
                                            activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
                                            if (activeSnapshot) {
                                                activeSurveyId = activeSnapshot.id;
                                            }
                                        }
                                    }
                                    return [4 /*yield*/, Promise.all([
                                            this.dynamicQuestionnaireService.getSurveyQAssetIdsDashboard(activeSurveyId).toPromise()
                                        ])];
                                case 17:
                                    getQIds = _a.sent();
                                    if (!(getQIds[0]['code'] === 'success')) return [3 /*break*/, 19];
                                    return [4 /*yield*/, Promise.all([
                                            this.dynamicQuestionnaireService.getQAnswersForProjectPaginatedDashboard(this.projectId, paginationLimits[0], paginationLimits[1], getQIds[0]['message']).toPromise()
                                        ])];
                                case 18:
                                    request3 = _a.sent();
                                    _a.label = 19;
                                case 19:
                                    // console.log('request3', request3);
                                    // If getQAnswersForFarmer has not been called for this farmer, get the answer values from getQAnswersForProject by filtering on farmerId
                                    result['message'] = request3[0]['msg'] !== 'blank' ? request3[0]['message'].filter(function (item) { return item.farmer_id === _this.farmerId; }) : [];
                                    _a.label = 20;
                                case 20:
                                    // If getQAnswersForFarmer has been called for this farmer before, compare the timestamp of the two API calls (farmer specific & project-wide data fetch)
                                    // Set the results array as the latest API data fetch
                                    if (qAnswersForFarmer && request3) {
                                        // getQAnswersForFarmer fetch      getQAnswersForProject fetch
                                        if (qAnswersForFarmer['ts'] > request3[0]['ts']) {
                                            // Set as farmer-specific API fetch
                                            result['message'] = qAnswersForFarmer['message'];
                                        }
                                        else {
                                            // Set as project-wide fetch, filtered by farmerId
                                            result['message'] = request3[0]['message'].filter(function (item) { return item.farmer_id === _this.farmerId; });
                                        }
                                    }
                                    else if (qAnswersForFarmer && !request3) {
                                        result['message'] = qAnswersForFarmer['message'];
                                    }
                                    else if (!qAnswersForFarmer && request3) {
                                        result['message'] = request3[0]['message'].filter(function (item) { return item.farmer_id === _this.farmerId; });
                                    }
                                    _a.label = 21;
                                case 21: return [3 /*break*/, 24];
                                case 22:
                                    result = [];
                                    if (!!navigator.onLine) return [3 /*break*/, 24];
                                    return [4 /*yield*/, Promise.all([
                                            this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                                        ])];
                                case 23:
                                    surveyReq = _a.sent();
                                    if (surveyReq[0]['code'] === 'success' && surveyReq[0]['message'].length > 0) {
                                        this.surveysInfo = surveyReq[0].message;
                                    }
                                    _a.label = 24;
                                case 24:
                                    if (!(this.farmerId !== 0 && request[5]['msg'] === 'success')) return [3 /*break*/, 25];
                                    if (navigator.onLine) {
                                        this.farmer = request[5]['data'];
                                    }
                                    else {
                                        this.farmer = request[5]['data'].find(function (item) { return item.id === _this.farmerId; });
                                    }
                                    return [3 /*break*/, 27];
                                case 25: return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                                case 26:
                                    farmertmpobj = _a.sent();
                                    this.farmer = farmertmpobj.find(function (x) { return x.tmp_id === _this.rawFarmerId; });
                                    _a.label = 27;
                                case 27:
                                    if (this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
                                        this.notes = this.farmer.FarmerQuickNotes[0];
                                    }
                                    else {
                                        this.notes = { notes: '', farmer_id: this.farmerId };
                                    }
                                    // if(navigator.onLine && request[5].code === 'success') {
                                    //   this.revisionsInSurvey = request[5].message;
                                    // }
                                    // Set quick notes
                                    this.setFarmerQuickNotes();
                                    // Set the qAnswers array
                                    this.setQAnswersAndInitialize(result);
                                    this.preProcessProjectUOMs();
                                    _a.label = 28;
                                case 28:
                                    this.spinner.hide();
                                    this.setBreadcrumbText();
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                });
                this.isDesktopDevice = this.deviceService.isDesktop();
                return [2 /*return*/];
            });
        });
    };
    /**
     * Set the qAnswers array using the result array - answers returned by the API, and the indexedDB entries
     * @param result = answers array
     */
    DynamicQuestionnaireComponent.prototype.setQAnswersAndInitialize = function (result) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var indexedDBEntries, farmerNA, farmertmpobj, qAnswersForThisFarmerId, i, i, _loop_1, this_1, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.qAnswers = [];
                        indexedDBEntries = [];
                        farmerNA = false;
                        return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        farmertmpobj = _a.sent();
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            qAnswersForThisFarmerId = [];
                            qAnswersForThisFarmerId = farmertmpobj.filter(function (item) {
                                return (String(item.tmp_id) === String(_this.rawFarmerId) && String(item.campaignId) === String(_this.campaignId));
                            });
                            if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
                                for (i = 0; i < qAnswersForThisFarmerId.length; i++) {
                                    indexedDBEntries = indexedDBEntries.concat(JSON.parse(qAnswersForThisFarmerId[i].values));
                                }
                            }
                        }
                        // Cached values
                        if (result.code === 'success' && result.message.length > 0) {
                            for (i = 0; i < result.message.length; i++) {
                                if (!result.message[i].deleted) {
                                    this.qAnswers.push({
                                        id: result.message[i].id.toString(),
                                        key: result.message[i].questionnaire_asset_id.toString(),
                                        sak_of_modified: result.message[i].sak_of_modified,
                                        secondary_asset_key: result.message[i].secondary_asset_key,
                                        value: result.message[i].answer.toString(),
                                        flex3: result.message[i].flex3
                                    });
                                    if (result.message[i].flex2 == 'NA') {
                                        farmerNA = true;
                                    }
                                }
                            }
                        }
                        // IndexedDB values
                        if (indexedDBEntries.length > 0) {
                            _loop_1 = function (i) {
                                // If the indexedDB entry has an id, find the same id from qAnswers and update the value, mark as touched
                                if (indexedDBEntries[i].id) {
                                    var x = this_1.qAnswers.find(function (item) { return item.id == indexedDBEntries[i].id; });
                                    if (x) {
                                        var xIndex = this_1.qAnswers.indexOf(x);
                                        if (this_1.validIndex(xIndex)) {
                                            this_1.qAnswers[xIndex]['value'] = indexedDBEntries[i]['value'];
                                            this_1.qAnswers[xIndex]['flex3'] = indexedDBEntries[i]['flex3'];
                                            this_1.qAnswers[xIndex]['touched'] = true;
                                            if (indexedDBEntries[i]['checked']) {
                                                this_1.qAnswers[xIndex]['checked'] = indexedDBEntries[i]['checked'];
                                            }
                                            if (indexedDBEntries[i]['unchecked']) {
                                                this_1.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['unchecked'];
                                            }
                                            if (indexedDBEntries[i]['deleted']) {
                                                this_1.qAnswers[xIndex]['deleted'] = indexedDBEntries[i]['deleted'];
                                            }
                                        }
                                    }
                                }
                                else {
                                    // Push into qAnswers
                                    var answerToInsert = {
                                        key: indexedDBEntries[i].key.toString(),
                                        sak_of_modified: indexedDBEntries[i].sak_of_modified,
                                        secondary_asset_key: indexedDBEntries[i].secondary_asset_key,
                                        value: indexedDBEntries[i].value.toString(),
                                        touched: true,
                                        checked: indexedDBEntries[i].checked,
                                        flex3: indexedDBEntries[i].flex3
                                    };
                                    if (indexedDBEntries[i]['checked']) {
                                        answerToInsert['checked'] = indexedDBEntries[i]['checked'];
                                    }
                                    if (indexedDBEntries[i]['unchecked']) {
                                        answerToInsert['unchecked'] = indexedDBEntries[i]['unchecked'];
                                    }
                                    if (indexedDBEntries[i]['deleted']) {
                                        answerToInsert['deleted'] = indexedDBEntries[i]['deleted'];
                                    }
                                    this_1.qAnswers.push(answerToInsert);
                                    if (indexedDBEntries[i].flex2 == 'NA') {
                                        farmerNA = true;
                                    }
                                }
                            };
                            this_1 = this;
                            for (i = 0; i < indexedDBEntries.length; i++) {
                                _loop_1(i);
                            }
                            this.askUserToConfirmExit = false;
                        }
                        if (this.notActiveSurvey === 0) {
                            // Initialize the questionnaire
                            this.initialize();
                            // Set the sak_of_modified values in this.secondary_asset_key__options object (which checkbox impacted which row / column uuid in a table)
                            this.setSecondaryAssetKeyOptionValues();
                            // Set dynamic options (which crop intercropping questions)
                            this.setDynamicOptionsObject();
                        }
                        if (farmerNA && typeof this.campaignId === "undefined") {
                            this.toastr.error(this.farmerUnavailableforProfiling);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // Naviate to 1st section inside the clicked tab
    DynamicQuestionnaireComponent.prototype.tabClicked = function (tabIndex) {
        if (tabIndex == -1) {
            this.showReferenceTab = true;
            this.docUrl = environment.apiBaseUrl + "/" + this.locale.substr(0, 2) + "/assets/uploads/surveyManual/" + this.activeSnapshot.flex4;
            console.log('docUrl', this.docUrl);
            this.currentTabIndex = tabIndex;
            return;
        }
        if (this.currentTabIndex != tabIndex) {
            this.currentTab = this.questionnaire[tabIndex];
            this.currentTabIndex = tabIndex;
            this.showReferenceTab = false;
            // this.currentSectionIndex = 0;
            this.sectionClicked(0, true);
            setTimeout(function () {
                var x = document.querySelector('ul.sub-tab-fixed');
                if (x) {
                    x.scrollLeft -= 1500;
                }
            }, 300);
        }
    };
    // To select last section on click of prev button
    DynamicQuestionnaireComponent.prototype.tabClickedSectionLast = function (tabIndex) {
        if (this.currentTabIndex != tabIndex) {
            this.currentTab = this.questionnaire[tabIndex];
            this.currentTabIndex = tabIndex;
            this.currentSectionIndex = this.currentTab.sections.length - 1;
            this.sectionClicked(this.currentSectionIndex, true);
            setTimeout(function () {
                var x = document.querySelector('ul.sub-tab-fixed');
                if (x) {
                    x.scrollLeft += 1500;
                }
            }, 300);
        }
    };
    // Navigate to the section clicked
    DynamicQuestionnaireComponent.prototype.sectionClicked = function (sectionIndex, comingFromTabClicked) {
        if (comingFromTabClicked || this.currentSectionIndex != sectionIndex) {
            window.scrollTo(0, 0);
            if (this.currentTab) {
                this.currentSection = undefined;
                if (this.currentTab.sections && this.currentTab.sections.length > 0) {
                    this.currentSection = this.currentTab.sections[sectionIndex];
                    this.currentSectionIndex = sectionIndex;
                    // Set the Section Form
                    this.setForm();
                    this.showSummaryForCurrentTabFn();
                    this.runTabSummaryCalculate();
                }
            }
            this.disableButton(sectionIndex);
            this.totalSectionAnswered = false;
            this.tableQuestionAnswered = true;
            this.checkAllQuestionAnswered();
            this.checkMandatoryQuestions();
            // if (this.surveysInfo && this.surveysInfo.length > 0) {
            //   if(this.campaignId){
            //     this.surveysInfo = this.surveysInfo.filter((item) => item.name === "Campaign" && item.flex1 == this.campaignId);
            //   } else{
            //     this.surveysInfo = this.surveysInfo.filter((item) => item.name === "Snapshot");
            //   }
            //   this.activeSnapshot = this.surveysInfo[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? this.surveysInfo[0] : undefined;
            // }
            if (parseInt(this.currentSection.flex1) === 1) {
                this.showSurveyManualIcon = true;
            }
            else {
                this.showSurveyManualIcon = false;
            }
        }
    };
    // Initialize the questionnaire by navigating to the 1st tab
    DynamicQuestionnaireComponent.prototype.initialize = function () {
        var _this = this;
        // this.currentTabIndex = 0;
        if (this.surveysInfo && this.surveysInfo.length > 0) {
            if (this.campaignId) {
                this.surveysInfo = this.surveysInfo.filter(function (item) { return item.name === "Campaign" && item.flex1 == _this.campaignId; });
            }
            else {
                this.surveysInfo = this.surveysInfo.filter(function (item) { return item.name === "Snapshot"; });
            }
            this.activeSnapshot = this.surveysInfo[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? this.surveysInfo[0] : undefined;
        }
        if (this.activeSnapshot && this.activeSnapshot.flex4) {
            this.surveyHasReference = true;
        }
        this.tabClicked(0);
        this.collectDisplayLogicKeyValues();
    };
    DynamicQuestionnaireComponent.prototype.keyDownFunction = function (event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            // rest of your code
        }
    };
    /**
     * Main function to create & set values in the section form
     */
    DynamicQuestionnaireComponent.prototype.setForm = function () {
        var _this = this;
        this.showSectionForm = false;
        this.sectionForm = new FormGroup({});
        // For each question in the section
        if (this.currentSection.questions && this.currentSection.questions.length > 0) {
            var _loop_2 = function (i) {
                // Find if the question has any answer(s) in the qAnswers array
                var existsInQAnswers = this_2.existsInQAnswers(this_2.currentSection.questions[i]);
                if (this_2.currentSection.questions[i].qa_subtype === 'multiselect' && existsInQAnswers && existsInQAnswers.length > 0) {
                    // Set the object which determines whether a checkbox should appear as checked or unchecked (question.id + option.id combination is true / false)
                    this_2.setCheckedObject(existsInQAnswers, this_2.currentSection.questions[i]);
                    // Create a form control for first option
                    this_2.sectionForm.addControl(this_2.currentSection.questions[i].id, new FormControl(existsInQAnswers[0].value));
                }
                else {
                    // Create a form control for each question
                    this_2.sectionForm.addControl(this_2.currentSection.questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
                }
                // Set the question's validations
                this_2.setQuestionValidations(this_2.currentSection.questions[i]);
                // Disable user input if required
                if (this_2.currentSection.questions[i].disable_input) {
                    this_2.sectionForm.controls[this_2.currentSection.questions[i].id].disable();
                }
                // Set the question's UOM dropdown (value of dropdown select formcontrol + the option in the dropdown) if required
                if (this_2.currentSection.questions[i].include_uom_question) {
                    this_2.setUOMQuestion(this_2.currentSection.questions[i], this_2.currentSection.questions[i].disable_input);
                }
                this_2.setOtherQuestion(this_2.currentSection.questions[i]);
                //set the file name if previously uploaded and saved
                if (this_2.currentSection.questions[i].qa_subtype == 'file' && existsInQAnswers) {
                    var obj_1 = {
                        "id": existsInQAnswers.key,
                        "image_file": '',
                        "image_for": existsInQAnswers.value,
                        "sak": existsInQAnswers.secondary_asset_key
                    };
                    var f = this_2.uploadedFiles.find(function (item) { return item.id == obj_1.id && item.image_for == obj_1.image_for; });
                    if (!f) {
                        this_2.uploadedFiles.push(obj_1);
                    }
                }
            };
            var this_2 = this;
            for (var i = 0; i < this.currentSection.questions.length; i++) {
                _loop_2(i);
            }
        }
        // For each subsection in the section, do the same steps as above (done for each question within each subsection)
        if (this.currentSection.subsections && this.currentSection.subsections.length > 0) {
            var _loop_3 = function (k) {
                var _loop_5 = function (i) {
                    var existsInQAnswers = this_3.existsInQAnswers(this_3.currentSection.subsections[k].questions[i]);
                    if (this_3.currentSection.subsections[k].questions[i].qa_subtype === 'multiselect' && existsInQAnswers && existsInQAnswers.length > 0) {
                        this_3.setCheckedObject(existsInQAnswers, this_3.currentSection.subsections[k].questions[i]);
                        // Create a form control for first option
                        existsInQAnswers.forEach(function (item) {
                            _this.sectionForm.addControl(_this.currentSection.subsections[k].questions[i].id, new FormControl(existsInQAnswers[0].value));
                        });
                    }
                    else {
                        this_3.sectionForm.addControl(this_3.currentSection.subsections[k].questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
                    }
                    this_3.setQuestionValidations(this_3.currentSection.subsections[k].questions[i]);
                    if (this_3.currentSection.subsections[k].questions[i].disable_input) {
                        this_3.sectionForm.controls[this_3.currentSection.subsections[k].questions[i].id].disable();
                    }
                    if (this_3.currentSection.subsections[k].questions[i].include_uom_question) {
                        this_3.setUOMQuestion(this_3.currentSection.subsections[k].questions[i], this_3.currentSection.subsections[k].questions[i].disable_input);
                    }
                    this_3.setOtherQuestion(this_3.currentSection.subsections[k].questions[i]);
                    //set the file name if previously uploaded and saved
                    if (this_3.currentSection.subsections[k].questions[i].qa_subtype == 'file' && existsInQAnswers) {
                        var obj_2 = {
                            "id": existsInQAnswers.key,
                            "image_file": '',
                            "image_for": existsInQAnswers.value,
                            "sak": existsInQAnswers.secondary_asset_key
                        };
                        var f = this_3.uploadedFiles.find(function (item) { return item.id == obj_2.id && item.image_for == obj_2.image_for; });
                        if (!f) {
                            this_3.uploadedFiles.push(obj_2);
                        }
                    }
                };
                for (var i = 0; i < this_3.currentSection.subsections[k].questions.length; i++) {
                    _loop_5(i);
                }
            };
            var this_3 = this;
            for (var k = 0; k < this.currentSection.subsections.length; k++) {
                _loop_3(k);
            }
        }
        // For each table within the section
        if (this.currentSection.tables && this.currentSection.tables.length > 0) {
            for (var k = 0; k < this.currentSection.tables.length; k++) {
                if (this.currentSection.tables[k].displayFunction && this.currentSection.tables[k].displayFunction.indexOf('addDefaultCropEntry') > -1) {
                    this.addDefaultCropEntry(this.currentSection.tables[k]);
                }
                // For each question in the table
                for (var i = 0; i < this.currentSection.tables[k].questions.length; i++) {
                    // Check if answers exist for this question
                    var existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(this.currentSection.tables[k].questions[i]);
                    // Add secondary asset key of each unique row / column in the table to the secondary_asset_keys object
                    this.secondary_asset_keys[this.currentSection.tables[k].id] = existsInQAnswersForTableQuestion.map(function (item) { return item.secondary_asset_key; })
                        .filter(function (value, index, self) { return self.indexOf(value) === index; });
                    // Either non-blank or "" answer should exist for each question in each row / column in the table
                    if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
                        var _loop_4 = function (p) {
                            // For multiselect questions, set the checkedObjectSecondaryKeys value (to indicate whether the checkbox should appear checked or not)
                            if (this_4.currentSection.tables[k].questions[i].qa_subtype === 'multiselect'
                                && existsInQAnswersForTableQuestion[p].value && existsInQAnswersForTableQuestion[p].value.length > 0) {
                                this_4.setCheckedObjectSecondaryKeys(existsInQAnswersForTableQuestion[p], this_4.currentSection.tables[k].questions[i]);
                            }
                            // Add the form control (question.id + '_' + secondary_asset_key of the row / column)
                            this_4.sectionForm.addControl(this_4.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key'], new FormControl(existsInQAnswersForTableQuestion[p]['value']));
                            // Set the question's validations, disable the input if required & add UOM questions if required
                            this_4.setQuestionValidations(this_4.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]['secondary_asset_key']);
                            if (this_4.currentSection.tables[k].questions[i].disable_input) {
                                this_4.sectionForm.controls[this_4.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key']].disable();
                            }
                            if (this_4.currentSection.tables[k].questions[i].include_uom_question) {
                                this_4.setUOMQuestion(this_4.currentSection.tables[k].questions[i], this_4.currentSection.tables[k].questions[i].disable_input, existsInQAnswersForTableQuestion[p]);
                            }
                            this_4.setOtherQuestion(this_4.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]['secondary_asset_key']);
                            //set the file name if previously uploaded and saved
                            if (this_4.currentSection.tables[k].questions[i].qa_subtype == 'file' && existsInQAnswersForTableQuestion[p].value) {
                                var obj_3 = {
                                    "id": existsInQAnswersForTableQuestion[p].key,
                                    "image_file": '',
                                    "image_for": existsInQAnswersForTableQuestion[p].value,
                                    "sak": existsInQAnswersForTableQuestion[p].secondary_asset_key
                                };
                                var f = this_4.uploadedFiles.find(function (item) { return item.id == obj_3.id && item.image_for == obj_3.image_for && item.sak == obj_3.sak; });
                                if (!f) {
                                    this_4.uploadedFiles.push(obj_3);
                                }
                            }
                        };
                        var this_4 = this;
                        // For each unique secondary asset key
                        for (var p = 0; p < existsInQAnswersForTableQuestion.length; p++) {
                            _loop_4(p);
                        }
                    }
                }
                for (var i = 0; i < this.currentSection.tables[k].grand_total_questions.length; i++) {
                    var existsInQAnswers = this.existsInQAnswersForTableQuestion(this.currentSection.tables[k].grand_total_questions[i]);
                    // console.log('324 existsInQAnswers', existsInQAnswers);
                    this.sectionForm.addControl(this.currentSection.tables[k].grand_total_questions[i].id, existsInQAnswers && existsInQAnswers.length > 0 ? new FormControl(existsInQAnswers[0]['value']) : new FormControl(''));
                    if (this.currentSection.tables[k].grand_total_questions[i].disable_input) {
                        this.sectionForm.controls[this.currentSection.tables[k].grand_total_questions[i].id].disable();
                    }
                }
                if (this.currentSection.tables[k].displayFunction && this.currentSection.tables[k].displayFunction.indexOf('addDefaultCropEntry') > -1) {
                    this.sortDefaultCropToTop(this.currentSection.tables[k].id);
                }
            }
        }
        // Based on toggle logic if applicatble, set which elements in the form should be displayed (isDisplayed attribute = true/false)
        this.setSectionFormDisplay();
        this.showSectionForm = true;
    };
    //clear inputed date by calling following function
    DynamicQuestionnaireComponent.prototype.clearCalendarDate = function (e, question) {
        this.sectionForm.controls[question.id].setValue('');
        this.sectionFormChangeHandler(e, question);
    };
    /**
     * Handle input change events
     * Section & subsection (NON TABLE) questions (type = text, number, select)
     * @param args
     * @param question
     */
    DynamicQuestionnaireComponent.prototype.sectionFormChangeHandler = function (args, question) {
        if (this.questionHasNoErrors(question)) {
            if (!this.qAnswers) {
                this.qAnswers = [];
            }
            // if question subtype is date convert it to readable format
            if (question.qa_subtype == 'date') {
                if (this.sectionForm.value[question.id] == undefined) {
                    return;
                }
                var dateSelected = void 0;
                if (this.sectionForm.value[question.id] == '') {
                    dateSelected = '';
                }
                else {
                    var dateValue = this.sectionForm.value[question.id];
                    var date = void 0;
                    if (typeof dateValue == "string") {
                        var dateParts = dateValue.split("-");
                        date = new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);
                    }
                    else {
                        date = new Date(dateValue);
                    }
                    // const dateSelected = date.getDate() + '-' + this.monthShortNames[date.getMonth()] + '-' + date.getFullYear();
                    dateSelected = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                }
                this.sectionForm.value[question.id] = dateSelected;
            }
            // See if this object already exists in qAnswers
            var x = this.qAnswers.find(function (item) { return item.key == question.id; });
            // If object exists, find the index     
            if (x) {
                var index = this.qAnswers.indexOf(x);
                // Set this object's touched parameter to true so that it is picked up to be sent to server
                this.qAnswers[index]['touched'] = true;
                // If this object has an id (previously value was set), set the form control's value
                if (this.qAnswers[index].id && this.qAnswers[index].id > 0) {
                    this.qAnswers[index]['value'] = this.sectionForm.value[question.id] != null ? this.sectionForm.value[question.id] : '';
                    if (this.qAnswers[index]['deleted']) {
                        this.qAnswers[index]['deleted'] = false;
                    }
                }
                else {
                    // If this object does NOT have an id
                    // If the form control has a not-null not-blank value, set it
                    if (this.sectionForm.value[question.id] != null && this.sectionForm.value[question.id] !== '') {
                        this.qAnswers[index]['value'] = this.sectionForm.value[question.id];
                        if (this.qAnswers[index]['deleted']) {
                            this.qAnswers[index]['deleted'] = false;
                        }
                    }
                    else {
                        // If the form control has a null / blank value, remove the object from this.qAnswers (it has been set and unset in the same session)
                        this.qAnswers[index]['value'] = '';
                        this.qAnswers[index]['deleted'] = true;
                    }
                }
            }
            else {
                // If this object does not exist in qAnswers
                // If the form control has a not-null not-blank value, add the object to qAnswers with touched = true
                if (this.sectionForm.value[question.id] != null && this.sectionForm.value[question.id] !== '') {
                    this.qAnswers.push({ key: question.id.toString(), value: this.sectionForm.value[question.id].toString(), touched: true });
                }
                else {
                    // console.log('211', question.id, this.sectionForm.value[question.id]);
                }
            }
            // Check if the question has a non-trivial toggle logic (NTTL) associated with it. If so, run the toggle logic functionality
            // if (question.table_to_modify != null && question.table_to_modify != '') {console.log('216');this.runDisplayLogic(question, this.sectionForm.value[question.id]);}
            // Based on toggle logic if applicatble, set which elements in the form should be displayed (isDisplayed attribute = true/false)
            this.setSectionFormDisplay();
            this.unsetOtherAnswerValue(question, null, null);
            if (question.uom_questions && question.uom_questions.length > 0) {
                this.setUOMValuesInQAnswers(question);
            }
            // If this question is modifying another through an auto calculate formula, run the auto calculate function
            if (question.modifies_qas) {
                this.runAutoCalculate(question, null, true);
            }
            this.unsetDependentQuestions(question);
            this.askUserToConfirmExit = true;
            if (this.showSummaryForCurrentTab) {
                this.runTabSummaryCalculate();
            }
            this.checkAllQuestionAnswered();
            this.checkMandatoryQuestions();
            // Added by SBJY
            var record = this.qAnswers.find(function (item) { return item.key == question.id; });
            if (typeof record !== "undefined" && typeof record.value !== "undefined" && record.value) {
                this.confirmAnswer("", question, true);
            }
            else {
                this.confirmAnswer("", question, false);
            }
        }
        else if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) {
            /* If question is mandatory then disable navigation buttons */
            for (var j = 0; j < question.survey_q_asset_validations.length; j++) {
                if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
                    this.mandatoryQuestionAnswered = false;
                    var record = this.qAnswers.find(function (item) { return item.key == question.id; });
                    var ind = this.qAnswers.indexOf(record);
                    this.qAnswers[ind]['value'] = "";
                }
            }
        }
    };
    DynamicQuestionnaireComponent.prototype.sectionFormChangeHandlerFile = function (fileObj, question) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var x, index, record, j;
            return tslib_1.__generator(this, function (_a) {
                if (this.questionHasNoErrors(question)) {
                    if (!this.qAnswers) {
                        this.qAnswers = [];
                    }
                    x = void 0;
                    if (fileObj.sak == "") {
                        x = this.qAnswers.find(function (item) { return item.key == question.id; });
                    }
                    else {
                        x = this.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == fileObj.sak; });
                    }
                    // If object exists, find the index
                    if (x) {
                        index = this.qAnswers.indexOf(x);
                        // Set this object's touched parameter to true so that it is picked up to be sent to server
                        this.qAnswers[index]['touched'] = true;
                        // If this object has an id (previously value was set), set the form control's value
                        if (this.qAnswers[index].id && this.qAnswers[index].id > 0) {
                            this.qAnswers[index]['value'] = fileObj.image_for;
                            this.qAnswers[index]['imgBase64'] = fileObj.image_file;
                            if (this.qAnswers[index]['deleted']) {
                                this.qAnswers[index]['deleted'] = false;
                            }
                        }
                        else {
                            // If this object does NOT have an id
                            // If the form control has a not-null not-blank value, set it
                            if (fileObj && fileObj.image_for != '') {
                                this.qAnswers[index]['value'] = fileObj.image_for;
                                this.qAnswers[index]['imgBase64'] = fileObj.image_file;
                                if (this.qAnswers[index]['deleted']) {
                                    this.qAnswers[index]['deleted'] = false;
                                }
                            }
                            else {
                                // If the form control has a null / blank value, remove the object from this.qAnswers (it has been set and unset in the same session)
                                this.qAnswers[index]['value'] = '';
                                this.qAnswers[index]['deleted'] = true;
                            }
                        }
                    }
                    else {
                        // If this object does not exist in qAnswers
                        // If the form control has a not-null not-blank value, add the object to qAnswers with touched = true
                        if (fileObj && fileObj.image_for != '') {
                            if (fileObj.sak == "") {
                                this.qAnswers.push({ key: question.id.toString(), value: fileObj.image_for, touched: true, imgBase64: fileObj.image_file });
                            }
                            else {
                                this.qAnswers.push({ key: question.id.toString(), value: fileObj.image_for, touched: true, imgBase64: fileObj.image_file, secondary_asset_key: fileObj.sak });
                            }
                        }
                        else {
                            // console.log('211', question.id, this.sectionForm.value[question.id]);
                        }
                    }
                    this.commonService.showToast('success', 'file_uploaded_successfully', {});
                    // Check if the question has a non-trivial toggle logic (NTTL) associated with it. If so, run the toggle logic functionality
                    // if (question.table_to_modify != null && question.table_to_modify != '') {console.log('216');this.runDisplayLogic(question, this.sectionForm.value[question.id]);}
                    // Based on toggle logic if applicatble, set which elements in the form should be displayed (isDisplayed attribute = true/false)
                    this.setSectionFormDisplay();
                    // this.unsetOtherAnswerValue(question, null, null);
                    // if (question.uom_questions && question.uom_questions.length > 0) {
                    //   this.setUOMValuesInQAnswers(question);
                    // }
                    // // If this question is modifying another through an auto calculate formula, run the auto calculate function
                    // if (question.modifies_qas) {
                    //   this.runAutoCalculate(question, null, true);
                    // }
                    // this.unsetDependentQuestions(question);
                    this.askUserToConfirmExit = true;
                    // if (this.showSummaryForCurrentTab) {
                    //   this.runTabSummaryCalculate();
                    // }
                    this.checkAllQuestionAnswered();
                    this.checkMandatoryQuestions();
                    // Added by SBJY
                    if (fileObj.sak == "") {
                        record = this.qAnswers.find(function (item) { return item.key == question.id; });
                        if (typeof record !== "undefined" && typeof record.value !== "undefined" && record.value) {
                            this.confirmAnswer("", question, true);
                        }
                        else {
                            this.confirmAnswer("", question, false);
                        }
                    }
                }
                else if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) {
                    /* If question is mandatory then disable navigation buttons */
                    for (j = 0; j < question.survey_q_asset_validations.length; j++) {
                        if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
                            this.mandatoryQuestionAnswered = false;
                        }
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    /* file upload */
    DynamicQuestionnaireComponent.prototype.handleUploadFileInput = function (file, question, sak) {
        var _this = this;
        // // debugger;
        if (file && file.length > 0) {
            var fileItem_1 = file.item(0);
            var msg = this.commonService.ValidateFile(fileItem_1);
            if (msg && msg.length > 0) {
                this.toastr.warning(msg);
                return;
            }
            new Compressor(fileItem_1, {
                quality: environment.imageCompress.quality,
                maxWidth: environment.imageCompress.maxWidth,
                maxHeight: environment.imageCompress.maxHeight,
                mimeType: environment.imageCompress.mimeType,
                convertSize: environment.imageCompress.convertSize,
                success: function (result) {
                    setFarmerImageData_1(result);
                },
                error: function (err) {
                    console.log(err.message);
                },
            });
            var setFarmerImageData_1 = function (result) {
                _this.commonService.blobToBase64(result).then(function (imageData) {
                    var obj = {
                        "id": question.id,
                        "image_file": imageData,
                        "image_for": fileItem_1.name,
                        "sak": sak ? sak : ''
                    };
                    if (sak) {
                        var x = _this.uploadedFiles.find(function (item) { return item.id == question.id && item.sak == sak; });
                        if (x) {
                            var index = _this.uploadedFiles.indexOf(x);
                            _this.uploadedFiles[index]['image_file'] = imageData;
                            _this.uploadedFiles[index]['image_for'] = fileItem_1.name;
                        }
                        else {
                            _this.uploadedFiles.push(obj);
                        }
                    }
                    else {
                        // See if this object already exists in uploadedFiles
                        var x = _this.uploadedFiles.find(function (item) { return item.id == question.id; });
                        if (x) {
                            var index = _this.uploadedFiles.indexOf(x);
                            _this.uploadedFiles[index]['image_file'] = imageData;
                            _this.uploadedFiles[index]['image_for'] = fileItem_1.name;
                        }
                        else {
                            _this.uploadedFiles.push(obj);
                        }
                    }
                    _this.sectionFormChangeHandlerFile(obj, question);
                });
            };
        }
    };
    /**
     * Handle input change events
     * Section & subsection (NON TABLE) questions (type = multiselect)
     * @param args
     * @param option
     * @param question
     */
    DynamicQuestionnaireComponent.prototype.sectionFormChangeHandlerCheckbox = function (args, option, question) {
        if (this.questionHasNoErrors(question)) {
            this.updateCheckedObject(args, option.id, question.id, null);
            if (!this.qAnswers) {
                this.qAnswers = [];
            }
            // If the checkbox is checked
            if (args.target.checked) {
                var checkedItem = this.qAnswers.find(function (item) { return (item.key == question.id && item.value == option.id); });
                var checkEmptyItem = this.qAnswers.find(function (item) { return (item.key == question.id && item.value == ''); });
                if (checkedItem) {
                    var checkedItemIndex = this.qAnswers.indexOf(checkedItem);
                    if (checkedItemIndex != null && checkedItemIndex > -1) {
                        this.qAnswers[checkedItemIndex]['unchecked'] = false;
                        if (this.qAnswers[checkedItemIndex]['deleted']) {
                            this.qAnswers[checkedItemIndex]['deleted'] = false;
                        }
                    }
                }
                else if (checkEmptyItem) {
                    var checkedItemIndex = this.qAnswers.indexOf(checkEmptyItem);
                    if (checkedItemIndex != null && checkedItemIndex > -1) {
                        this.qAnswers[checkedItemIndex]['value'] = option.id.toString();
                        this.qAnswers[checkedItemIndex]['checked'] = true;
                        this.qAnswers[checkedItemIndex]['unchecked'] = false;
                        if (this.qAnswers[checkedItemIndex]['deleted']) {
                            this.qAnswers[checkedItemIndex]['deleted'] = false;
                        }
                    }
                }
                else {
                    // Add the answer to qAnswers array. Value should contain the option asset's id
                    if (question.answerConfirmed || question.answerConfirmed == false) {
                        if (question.answerConfirmed == true) {
                            this.qAnswers.push({ key: question.id.toString(), value: option.id.toString(), touched: true, checked: true, flex3: 'A' });
                        }
                        else {
                            this.qAnswers.push({ key: question.id.toString(), value: option.id.toString(), touched: true, checked: true, flex3: 'NA' });
                        }
                    }
                    else {
                        this.qAnswers.push({ key: question.id.toString(), value: option.id.toString(), touched: true, checked: true });
                    }
                }
                this.confirmAnswer("", question, true);
            }
            else {
                // If checkbox is unchecked, find the unchecked item in qAnswers
                var uncheckedItem = this.qAnswers.find(function (item) { return (item.key == question.id && item.value == option.id); });
                if (uncheckedItem) {
                    var uncheckedItemIndex = this.qAnswers.indexOf(uncheckedItem);
                    if (uncheckedItemIndex != null && uncheckedItemIndex > -1) {
                        // If the unchecked item has an id (meaning it was created & saved in DB previously), then set the unchecked attribute to true
                        // if (this.qAnswers[uncheckedItemIndex].id && this.qAnswers[uncheckedItemIndex].id > 0) {
                        //   this.qAnswers[uncheckedItemIndex]['touched'] = true;
                        //   this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
                        // } else {
                        //   // If there is no id, this checkbox has been checked & unchecked in the same session. The item can be remvoed from qAnswers
                        //   // this.qAnswers = this.qAnswers.filter(item => !(item.key == question.id && item.value == option.id));
                        //   // TODO: Remove this from IDB
                        //   this.qAnswers[uncheckedItemIndex]['touched'] = true;
                        //   this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
                        // }
                        this.qAnswers[uncheckedItemIndex]['touched'] = true;
                        this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
                        //   // TODO: Remove this from IDB
                    }
                }
                //check if not any option is selected
                var allQAnswers = this.qAnswers.filter(function (item) { return item.key == question.id; });
                var allEmpty = true;
                for (var _i = 0, allQAnswers_1 = allQAnswers; _i < allQAnswers_1.length; _i++) {
                    var i = allQAnswers_1[_i];
                    if (!i.unchecked) {
                        allEmpty = false;
                    }
                }
                if (allEmpty) {
                    //to show input required message check if question is mandaory;
                    if (question.survey_q_asset_validations) {
                        var checkMandatory = question.survey_q_asset_validations.find(function (item) { return item.validation_key == "mandatory"; });
                        if (checkMandatory) {
                            this.sectionForm.controls[question.id].setErrors({ 'required': true });
                        }
                        else {
                            this.confirmAnswer("", question, false);
                        }
                    }
                }
            }
            // Run display logic if required
            // if (question.table_to_modify != null && question.table_to_modify != '') {this.runDisplayLogicCheckBox(question, option.id, this.sectionForm.value[question.id]);}
            // Set which assets to display
            this.setSectionFormDisplay();
            this.unsetOtherAnswerValue(question, null, null);
            this.unsetDependentQuestions(question);
            this.askUserToConfirmExit = true;
            this.checkAllQuestionAnswered();
            this.checkMandatoryQuestions();
        }
        else if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) {
            /* If question is mandatory then disable navigation buttons */
            for (var j = 0; j < question.survey_q_asset_validations.length; j++) {
                if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
                    this.mandatoryQuestionAnswered = false;
                }
            }
        }
    };
    /**Confirmed that this question is answered */
    DynamicQuestionnaireComponent.prototype.confirmAnswer = function (e, question, na, isAuto, isClicked) {
        var flag = na ? 'A' : 'NA';
        var autoCalculateQnErrorFlag = false;
        if (this.questionHasNoErrors(question)) {
            var findQ = void 0;
            for (var _i = 0, _a = this.currentSection.questions; _i < _a.length; _i++) {
                var j = _a[_i];
                if (j.id == question.id) {
                    findQ = j;
                }
            }
            for (var _b = 0, _c = this.currentSection.subsections; _b < _c.length; _b++) {
                var i = _c[_b];
                for (var _d = 0, _e = i.questions; _d < _e.length; _d++) {
                    var j = _e[_d];
                    if (j.id == question.id) {
                        findQ = j;
                    }
                }
            }
            for (var _f = 0, _g = this.currentSection.tables; _f < _g.length; _f++) {
                var i = _g[_f];
                for (var _h = 0, _j = i.questions; _h < _j.length; _h++) {
                    var j = _j[_h];
                    if (j.id == question.id) {
                        findQ = j;
                    }
                }
            }
            if (!this.qAnswers) {
                this.qAnswers = [];
            }
            // See if this object already exists in qAnswers      
            // if(question.qa_subtype == 'multiselect'){
            //   const x = this.qAnswers.filter(item => item.key == question.id);
            // }
            var x = this.qAnswers.filter(function (item) { return item.key == question.id; });
            if (typeof question.survey_q_asset_autocalculate_formulas[0] !== "undefined") {
                var formula3 = question.survey_q_asset_autocalculate_formulas[0].formula;
                var formulaArray3 = formula3.split(",");
                var qArray2 = formulaArray3.filter(function (item) { return !isNaN(parseFloat(item)); });
                var questionCount2 = 0;
                var _loop_6 = function (qid) {
                    var findItem = this_5.qAnswers.find(function (item) { return item.key == qid; });
                    if (findItem && findItem.flex3) {
                        questionCount2++;
                    }
                    //to check if current question type is grand total
                    for (var _i = 0, _a = this_5.currentSection.tables; _i < _a.length; _i++) {
                        var t = _a[_i];
                        if (t.grand_total_questions && t.grand_total_questions.length > 0) {
                            var gt = t.grand_total_questions.find(function (item) { return item.id == qid; });
                            var findA = this_5.qAnswers.find(function (item) { return item.key == qid; });
                            if (gt && !findA) {
                                questionCount2++;
                            }
                        }
                    }
                };
                var this_5 = this;
                for (var _k = 0, qArray2_1 = qArray2; _k < qArray2_1.length; _k++) {
                    var qid = qArray2_1[_k];
                    _loop_6(qid);
                }
                if (questionCount2 !== qArray2.length) {
                    this.toastr.error(this.answerCanNotBeValidate);
                    autoCalculateQnErrorFlag = true;
                }
            }
            // If object exists, find the index
            if (!autoCalculateQnErrorFlag) {
                if (x && x.length > 0) {
                    var allEmpty = true;
                    var checkBoxFlag = false;
                    var noErrors = true;
                    for (var _l = 0, x_1 = x; _l < x_1.length; _l++) {
                        var i = x_1[_l];
                        if (!i.unchecked && i.value !== "") {
                            allEmpty = false;
                        }
                        if (typeof i.unchecked !== "undefined" || typeof i.checked !== "undefined") {
                            checkBoxFlag = true;
                        }
                    }
                    if (na && (isClicked || typeof isClicked === "undefined")) {
                        if (!checkBoxFlag && x[0].value === '') {
                            this.toastr.error(this.answerCanNotBeConfirmed);
                            noErrors = false;
                        }
                        if (checkBoxFlag && allEmpty) {
                            this.toastr.error(this.answerCanNotBeConfirmed);
                            noErrors = false;
                        }
                    }
                    else if (!na && (isClicked || typeof isClicked === "undefined")) {
                        if (!checkBoxFlag && x[0].value !== '') {
                            this.toastr.error(this.answerCanNotMarkNA);
                            noErrors = false;
                        }
                        if (checkBoxFlag && !allEmpty) {
                            this.toastr.error(this.answerCanNotMarkNA);
                            noErrors = false;
                        }
                    }
                    // if(!na && (x[0].value != '' || !allEmpty)){
                    // if(!na && question.survey_q_asset_autocalculate_formulas.length === 0){
                    //   if(!allEmpty){
                    //     console.log("Answer cannot marke NA-->1");
                    //     this.toastr.error(this.answerCanNotMarkNA);
                    //   } else if(x[0].value != ''){
                    //     console.log("Answer cannot marke NA-->2");
                    //     this.toastr.error(this.answerCanNotMarkNA);
                    //   }
                    // this.toastr.error(this.answerCanNotMarkNA);
                    // } else if (na && question.survey_q_asset_autocalculate_formulas.length === 0 && x[0].value == '' && allEmpty) {
                    // }else if(na && question.survey_q_asset_autocalculate_formulas.length === 0){
                    //   if(allEmpty){
                    //     console.log("Answer cannot mark confirmed-->1");
                    //     this.toastr.error(this.answerCanNotBeConfirmed);
                    //   } else if(x[0].value === ''){
                    //     console.log("Answer cannot mark confirmed-->2");
                    //     this.toastr.error(this.answerCanNotBeConfirmed);
                    //   }
                    // console.log("Answer cannot mark confirmed");
                    // this.toastr.error(this.answerCanNotBeConfirmed);
                    // } 
                    if (noErrors) {
                        for (var _m = 0, x_2 = x; _m < x_2.length; _m++) {
                            var y = x_2[_m];
                            var index = this.qAnswers.indexOf(y);
                            // Set this object's touched parameter to true so that it is picked up to be sent to server
                            this.qAnswers[index]['flex3'] = flag;
                            this.qAnswers[index]['touched'] = true;
                            if (findQ) {
                                findQ.answerConfirmed = na;
                            }
                            ;
                        }
                    }
                }
                else {
                    // If this object does not exist in qAnswers
                    // If the form control has a not-null not-blank value, add the object to qAnswers with touched = true
                    if (this.sectionForm.value[question.id] != null && this.sectionForm.value[question.id] !== '' && na) {
                        this.qAnswers.push({ key: question.id.toString(), value: this.sectionForm.value[question.id].toString(), touched: true, flex3: 'A' });
                        if (findQ) {
                            findQ.answerConfirmed = na;
                        }
                        ;
                    }
                    else if (!na) {
                        this.qAnswers.push({ key: question.id.toString(), value: '', touched: true, flex3: "NA" });
                        if (findQ) {
                            findQ.answerConfirmed = na;
                        }
                        ;
                    }
                    else if ((this.sectionForm.value[question.id] === '' || this.sectionForm.value[question.id] === null) && na) {
                        this.toastr.error(this.answerCanNotBeConfirmed);
                    }
                }
                this.checkAllQuestionAnswered();
                if (!isAuto) {
                    this.askUserToConfirmExit = true;
                    // Fix for confirmeed metrics not showing in view page, compare answers and reports
                    if (question.uom_questions && question.uom_questions.length > 0) {
                        this.setUOMValuesInQAnswers(question, null, null, na);
                    }
                }
                if (question.modifies_qas) {
                    this.runAutoCalculate(question, null, true);
                }
                this.checkMandatoryQuestions();
                // if(question.modifies_qas){
                //   this.runAutoCalculate(question);
                // }
            }
        }
    };
    /* confirm answer table */
    DynamicQuestionnaireComponent.prototype.confirmTableAnswer = function (e, tableId, secondaryAssetKey, na, clicked) {
        var flag = na ? 'A' : 'NA';
        var table = this.currentSection.tables.find(function (item) { return item.id == tableId; });
        if (table) {
            var count = 0;
            var TQcount = 0;
            var _loop_7 = function (question) {
                var keyIsDisplayed = secondaryAssetKey + "_isDisplayed";
                if (question[keyIsDisplayed]) {
                    TQcount++;
                }
                if (!this_6.qAnswers) {
                    this_6.qAnswers = [];
                }
                // See if this object already exists in qAnswers
                var x = this_6.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == secondaryAssetKey; });
                // If object exists, find the index
                var value = this_6.sectionForm.value[question.id + '_' + secondaryAssetKey];
                // if (this.notADuplicateValue(question, value)) { // Uncomment this function to apply constraint of unique answer value for given question
                if (x) {
                    var index = this_6.qAnswers.indexOf(x);
                    // Set this object's answered parameter to value
                    this_6.qAnswers[index]['flex3'] = flag;
                    this_6.qAnswers[index]['touched'] = true;
                    if (x.value != null && x.value !== '') {
                        count++;
                    }
                }
                else {
                    // Set the value of the formcontrol or set '' in case form control's value is null. This is required as each table question must have an entry in the answers table (even if '')
                    if (value != null && value !== '') {
                        this_6.qAnswers.push({ key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: this_6.sectionForm.value[question.id + '_' + secondaryAssetKey].toString(), touched: true, flex3: 'A' });
                        count++;
                    }
                    else if (!na) {
                        this_6.qAnswers.push({ key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: '', touched: true, flex3: 'NA' });
                        //this.tableAnswersSAKs[secondaryAssetKey] = na;
                    }
                }
            };
            var this_6 = this;
            for (var _i = 0, _a = table.questions; _i < _a.length; _i++) {
                var question = _a[_i];
                _loop_7(question);
            }
            var errCount = 0;
            // The err msgs controlled by the btn behaviour
            if (na && count < 1 && clicked) {
                this.toastr.error(this.answerCanNotBeConfirmed);
                errCount++;
            }
            if (count >= 1 && !na && clicked) {
                this.toastr.error(this.answerCanNotMarkNA);
                errCount++;
            }
            // If the any of the sub qn is answered in a row, we are marking the entire row is answered.
            if (count > 0 && !clicked) {
                this.tableAnswersSAKs[secondaryAssetKey] = true;
            }
            else if (count < 0 && !clicked) {
                this.tableAnswersSAKs[secondaryAssetKey] = false;
            }
            var findwithsaks = this.qAnswers.filter(function (item) { return item.secondary_asset_key == secondaryAssetKey; });
            this.checkAllQuestionAnswered(clicked, na, secondaryAssetKey, errCount);
            this.askUserToConfirmExit = true;
        }
    };
    DynamicQuestionnaireComponent.prototype.checkAllQuestionAnswered = function (clicked, na, secondaryAssetKey, errCount) {
        var qCount = 0, aCount = 0;
        var noQuestion = false, noSubsection = false, noTable = false;
        var allQnsMarkedNAInTableFlag = false;
        var flex3Changed = false;
        if (this.currentSection.questions.length == 0) {
            noQuestion = true;
        }
        var _loop_8 = function (j) {
            if (j.isDisplayed) {
                qCount++;
            }
            if (j.qa_subtype !== "multiselect") {
                var findFlex3 = this_7.qAnswers.find(function (item) { return item.key == j.id; });
                if (findFlex3 && findFlex3.flex3) {
                    if (findFlex3.flex3 == 'A') {
                        j.answerConfirmed = true;
                    }
                    else {
                        j.answerConfirmed = false;
                    }
                }
            }
            else {
                var findFlex3Arr = this_7.qAnswers.filter(function (item) { return item.key == j.id; });
                if (findFlex3Arr && findFlex3Arr.length > 0) {
                    var c_1 = 0, nc_1 = 0;
                    findFlex3Arr.forEach(function (item) {
                        if (item.flex3 === "A") {
                            c_1++;
                        }
                        if (item.flex3 === "NA") {
                            nc_1++;
                        }
                    });
                    if (c_1 > 0) {
                        j["answerConfirmed"] = true;
                    }
                    else if (nc_1 > 0) {
                        j["answerConfirmed"] = false;
                    }
                }
            }
            if (j.isDisplayed && (j.answerConfirmed || j.answerConfirmed == false)) {
                aCount++;
            }
        };
        var this_7 = this;
        for (var _i = 0, _a = this.currentSection.questions; _i < _a.length; _i++) {
            var j = _a[_i];
            _loop_8(j);
        }
        if (this.currentSection.subsections.length == 0) {
            noSubsection = true;
        }
        for (var _b = 0, _c = this.currentSection.subsections; _b < _c.length; _b++) {
            var j = _c[_b];
            if (j.questions && j.questions.length > 0 && j.isDisplayed) {
                var _loop_9 = function (q) {
                    if (q.isDisplayed) {
                        qCount++;
                    }
                    if (q.qa_subtype !== "multiselect") {
                        var findFlex3 = this_8.qAnswers.find(function (item) { return item.key == q.id; });
                        if (findFlex3 && findFlex3.flex3) {
                            if (findFlex3.flex3 == 'A') {
                                q.answerConfirmed = true;
                            }
                            else {
                                q.answerConfirmed = false;
                            }
                        }
                    }
                    else {
                        var findFlex3Arr = this_8.qAnswers.filter(function (item) { return item.key == q.id; });
                        if (findFlex3Arr && findFlex3Arr.length > 0) {
                            var c_2 = 0, nc_2 = 0;
                            findFlex3Arr.forEach(function (item) {
                                if (item.flex3 === "A") {
                                    c_2++;
                                }
                                if (item.flex3 === "NA") {
                                    nc_2++;
                                }
                            });
                            if (c_2 > 0) {
                                q["answerConfirmed"] = true;
                            }
                            else if (nc_2 > 0) {
                                q["answerConfirmed"] = false;
                            }
                        }
                    }
                    if (q.answerConfirmed || q.answerConfirmed == false) {
                        aCount++;
                    }
                };
                var this_8 = this;
                for (var _d = 0, _e = j.questions; _d < _e.length; _d++) {
                    var q = _e[_d];
                    _loop_9(q);
                }
            }
        }
        for (var _f = 0, _g = this.currentSection.tables; _f < _g.length; _f++) {
            var j = _g[_f];
            //if(j.isDisplayed){qCount++;}
            var findSAKs = this.secondary_asset_keys[j.id];
            if (findSAKs.length == 0) {
                noTable = true;
            }
            var totalNAQns = 0;
            if (findSAKs && findSAKs.length > 0) {
                var _loop_10 = function (sak) {
                    var TQcount = 0;
                    var TAcount = 0;
                    var flex3Flag = void 0;
                    // if(j.questions && j.questions.length > 0 && j.isDisplayed){
                    if (j.questions && j.questions.length > 0) {
                        var _loop_11 = function (q) {
                            var assetDisplayed = this_9.checkAssetDisplay(q);
                            if (assetDisplayed) {
                                var findFlex3 = this_9.qAnswers.find(function (item) { return item.key == q.id && item.secondary_asset_key == sak; });
                                if (findFlex3 && findFlex3.flex3 == 'A' && findFlex3.value != null && findFlex3.value != '') {
                                    TAcount++;
                                    flex3Flag = true;
                                }
                                var keyIsDisplayed = sak + "_isDisplayed";
                                if (q[keyIsDisplayed]) {
                                    TQcount++;
                                    if (findFlex3 && findFlex3.flex3 == 'NA' && findFlex3.value != null && findFlex3.value != '' && findFlex3.unchecked !== true) {
                                        TAcount++;
                                        flex3Flag = false;
                                    }
                                }
                                if (findFlex3 && (typeof findFlex3.flex3 === "undefined" || findFlex3.flex3 === null)) {
                                    if (findFlex3.value != null && findFlex3.value != '') {
                                        TAcount++;
                                    }
                                }
                            }
                        };
                        for (var _i = 0, _a = j.questions; _i < _a.length; _i++) {
                            var q = _a[_i];
                            _loop_11(q);
                        }
                    }
                    if (TQcount != 0 && TAcount != 0 && TAcount > 0) {
                        qCount++;
                        this_9.tableAnswersSAKs[sak] = flex3Flag;
                    }
                    else if (j.isDisplayed) {
                        qCount++;
                    }
                    aCount++;
                    if (TAcount > 0) {
                        this_9.tableAnswersSAKs[sak] = true;
                        j.answerConfirmed = true;
                        this_9.qAnswers.forEach(function (item) {
                            if (item.secondary_asset_key === sak && (item.flex3 === null || typeof item.flex3 === "undefined" || item.flex3 === "NA")) {
                                item.flex3 = "A";
                                item.touched = true;
                                flex3Changed = true;
                            }
                        });
                    }
                    else if (TAcount === 0 && !na) {
                        // this.tableAnswersSAKs[sak] = false;
                        if (!errCount) {
                            this_9.tableAnswersSAKs[(secondaryAssetKey ? secondaryAssetKey : sak)] = false;
                            this_9.qAnswers.forEach(function (item) {
                                if (item.secondary_asset_key === sak && (item.flex3 === null || typeof item.flex3 === "undefined" || item.flex3 === "A")) {
                                    item.flex3 = "NA";
                                    item.touched = true;
                                    flex3Changed = true;
                                }
                            });
                        }
                        j.answerConfirmed = false;
                        totalNAQns++;
                    }
                };
                var this_9 = this;
                for (var _h = 0, findSAKs_1 = findSAKs; _h < findSAKs_1.length; _h++) {
                    var sak = findSAKs_1[_h];
                    _loop_10(sak);
                }
                if (totalNAQns === findSAKs.length) {
                    allQnsMarkedNAInTableFlag = true;
                }
            }
        }
        if (qCount != 0 && aCount != 0 && qCount == aCount) {
            this.totalSectionAnswered = true;
        }
        else if (noQuestion && noSubsection && noTable) {
            this.totalSectionAnswered = true;
        }
        else if (allQnsMarkedNAInTableFlag === true) {
            this.totalSectionAnswered = true;
        }
        else {
            this.totalSectionAnswered = false;
        }
        if (flex3Changed) {
            this.askUserToConfirmExit = true;
        }
    };
    /**
     * Unset the value of the 'other' question
     * @param question
     * @param table
     * @param secondaryAssetKey
     */
    DynamicQuestionnaireComponent.prototype.unsetOtherAnswerValue = function (question, table, secondaryAssetKey) {
        if (question.other_questions && question.other_questions.length > 0) {
            if (secondaryAssetKey) {
                if (!this.tableOtherQuestionsIsDisplayed[question.other_questions[0].id + '_' + secondaryAssetKey]) {
                    var x = {};
                    x[question.other_questions[0].id + '_' + secondaryAssetKey] = '';
                    this.sectionForm.patchValue(x);
                    this.sectionFormChangeHandlerForTable(null, table, question.other_questions[0], secondaryAssetKey);
                }
            }
            else {
                if (!question.other_questions[0].isDisplayed) {
                    var x = {};
                    x[question.other_questions[0].id] = null;
                    this.sectionForm.patchValue(x);
                    this.sectionFormChangeHandler(null, question.other_questions[0]);
                }
            }
        }
    };
    /**
     * Save questionnaire responses in the DB
     * @param alsoExit
     */
    DynamicQuestionnaireComponent.prototype.saveProfilingData = function (alsoExit) {
        this.spinner.show();
        // Find the items from qAnswers that have been touched in the current session
        var qAnswersToSubmit = this.qAnswers.filter(function (item) { return item.touched; });
        qAnswersToSubmit = qAnswersToSubmit.filter(function (item) { return !(!item.id && (item.deleted || item.unchecked) && !(item.flex3 || (item.flex2 == 'A' && typeof item.flex3 === "undefined"))); });
        // If any items need to be sent to the DB
        if (qAnswersToSubmit && qAnswersToSubmit.length > 0) {
            // For each such item, set deleted attribute to true if any of the following conditions are met. Items with deleted = true will be removed from the table
            // 1. value is null
            // 2. value is '' & secondary_asset_key is not present (i.e. non-table question with empty value)
            // 3. unchecked attribute is true (i.e. checkbox answer, which has now been unchecked) - for checkbox questions within a table, unchecked will not be set to false for the last option so that '' value is updated in DB
            // 4. secondary_asset_key is present, and the item is marked for deletion. I.e. table question, where in the entire row / column has been deleted
            for (var i = 0; i < qAnswersToSubmit.length; i++) {
                if (qAnswersToSubmit[i].value === null || qAnswersToSubmit[i].value === undefined ||
                    (qAnswersToSubmit[i].value === '' && !qAnswersToSubmit[i].secondary_asset_key && qAnswersToSubmit[i].flex3 != 'NA') ||
                    qAnswersToSubmit[i].unchecked ||
                    (qAnswersToSubmit[i].secondary_asset_key && qAnswersToSubmit[i].deleted)) {
                    qAnswersToSubmit[i]['deleted'] = true;
                    if (qAnswersToSubmit[i].unchecked && qAnswersToSubmit[i].flex3 == 'NA') {
                        qAnswersToSubmit[i]['value'] = '';
                        qAnswersToSubmit[i]['deleted'] = false;
                    }
                }
            }
            // For each key from this.secondary_asset_key__options, set the sak_of_modified attribute to the corresponding item
            // E.g. option = 498 caused secondary_asset_key = dskljhsfd09839823 to get created. The item with value = 498 should have sak_of_modified = dskljhsfd09839823
            // sak_of_modified is required so that we can map an option to the row / column that was created because of it
            Object.keys(this.secondary_asset_key__options).forEach(function (option) {
                var qAnswerToSubmitExists = qAnswersToSubmit.find(function (item) { return item.secondary_asset_key === option; });
                if (qAnswerToSubmitExists) {
                    var index = qAnswersToSubmit.indexOf(qAnswerToSubmitExists);
                    if (index != null && index > -1) {
                        qAnswersToSubmit[index]['sak_of_modified'] = this.secondary_asset_key__options[option];
                    }
                }
            }.bind(this));
            // Make API call. Response is the updated values of qAnswers
            this.commonService.showToast('warning', 'saving_changes', {});
            var answerStats = this.getAnswerStatsLocal();
            for (var _i = 0, qAnswersToSubmit_1 = qAnswersToSubmit; _i < qAnswersToSubmit_1.length; _i++) {
                var ans = qAnswersToSubmit_1[_i];
                if (ans.deleted && ans.secondary_asset_key && !ans.id) {
                    ans["not_for_save"] = true;
                }
            }
            qAnswersToSubmit = qAnswersToSubmit.filter(function (item) { return !item.not_for_save; });
            if (navigator.onLine) {
                this.saveProfilingDataOnline(qAnswersToSubmit, alsoExit, answerStats);
                // this.updatePercentageCompletedValuesOffline();
            }
            else {
                this.saveProfilingDataOffline(qAnswersToSubmit, alsoExit, answerStats);
            }
        }
        else {
            this.commonService.showToast('success', 'changes_saved', {});
            this.askUserToConfirmExit = false;
            this.spinner.hide();
        }
    };
    /**
     * Update the qAnswers arary with answers saved in the DB
     * @param savedQAnswers
     */
    DynamicQuestionnaireComponent.prototype.updateQAnswers = function (savedQAnswers) {
        if (!this.qAnswers) {
            this.qAnswers = [];
        }
        this.qAnswers = this.qAnswers.filter(function (item) { return !(item.unchecked || item.deleted); });
        var _loop_12 = function (i) {
            var x = this_10.qAnswers.find(function (item) {
                return item.key == savedQAnswers[i].questionnaire_asset_id && item.value == savedQAnswers[i].answer && item.secondary_asset_key == savedQAnswers[i].secondary_asset_key;
            });
            if (x) {
                var index = this_10.qAnswers.indexOf(x);
                this_10.qAnswers[index] = {
                    id: savedQAnswers[i].id.toString(),
                    key: savedQAnswers[i].questionnaire_asset_id.toString(),
                    value: savedQAnswers[i].answer.toString(),
                    sak_of_modified: savedQAnswers[i].sak_of_modified ? savedQAnswers[i].sak_of_modified.toString() : null,
                    secondary_asset_key: savedQAnswers[i].secondary_asset_key ? savedQAnswers[i].secondary_asset_key.toString() : null,
                    flex3: savedQAnswers[i].flex3
                };
            }
            else {
                this_10.qAnswers.push({
                    id: savedQAnswers[i].id.toString(),
                    key: savedQAnswers[i].questionnaire_asset_id.toString(),
                    value: savedQAnswers[i].answer.toString(),
                    sak_of_modified: savedQAnswers[i].sak_of_modified ? savedQAnswers[i].sak_of_modified.toString() : null,
                    secondary_asset_key: savedQAnswers[i].secondary_asset_key ? savedQAnswers[i].secondary_asset_key.toString() : null,
                    flex3: savedQAnswers[i].flex3
                });
            }
        };
        var this_10 = this;
        for (var i = 0; i < savedQAnswers.length; i++) {
            _loop_12(i);
        }
        // for (let i = 0; i < savedQAnswers.length; i++) {
        //   this.qAnswers.push({id: savedQAnswers[i].id.toString(), key: savedQAnswers[i].questionnaire_asset_id.toString(), sak_of_modified: savedQAnswers[i].sak_of_modified, secondary_asset_key: savedQAnswers[i].secondary_asset_key, value: savedQAnswers[i].answer.toString()});
        // }
        this.updateAllQAnswersTouchedValue(false);
    };
    /**
     * Check if a given question has answer(s) in the qAnswers array. This should be used for non-table questions only
     * @param question
     */
    DynamicQuestionnaireComponent.prototype.existsInQAnswers = function (question) {
        if (!question.is_table_question) {
            var x = void 0;
            if (question.qa_subtype === 'multiselect') {
                x = this.qAnswers.filter(function (item) { return item.key == question.id; });
            }
            else {
                x = this.qAnswers.find(function (item) { return item.key == question.id; });
            }
            return x;
        }
    };
    /**
     * Check if a given table question has answer(s) in the qAnswers array. This should be used for table questions only
     * Filter is used for all types of questions as same asset id may have multiple answers (one in each row / col)
     * @param question
     */
    DynamicQuestionnaireComponent.prototype.existsInQAnswersForTableQuestion = function (question) {
        return this.qAnswers.filter(function (item) { return item.key == question.id && !item.deleted && !item.unchecked; });
    };
    /**
     * Set the checkedObject object attribute of question id + option id to true if it exists in qAnswers
     * This is used for checkbox questions that are NOT in a table
     * @param existsInQAnswers
     * @param question
     */
    DynamicQuestionnaireComponent.prototype.setCheckedObject = function (existsInQAnswers, question) {
        for (var i = 0; i < existsInQAnswers.length; i++) {
            if (!existsInQAnswers[i].unchecked) {
                this.checkedObject[question.id + '_' + existsInQAnswers[i]['value']] = true;
            }
        }
    };
    /**
     * Set the checkedObject object attribute of question id + secondary_asset_key + option id to true if it exists in qAnswers for a given secondary asset key
     * This is used for checkbox questions that are in a table
     * @param existsInQAnswersForTableQuestionItem
     * @param question
     */
    DynamicQuestionnaireComponent.prototype.setCheckedObjectSecondaryKeys = function (existsInQAnswersForTableQuestionItem, question) {
        if (!existsInQAnswersForTableQuestionItem.unchecked) {
            this.checkedObjectSecondaryKeys[question.id + '_' + existsInQAnswersForTableQuestionItem.secondary_asset_key + '_' + existsInQAnswersForTableQuestionItem['value']] = true;
        }
    };
    /**
     * Set isDisplayed = true / false for each child / grandchild asset in the section
     */
    DynamicQuestionnaireComponent.prototype.setSectionFormDisplay = function () {
        for (var i = 0; i < this.currentSection.questions.length; i++) {
            this.currentSection.questions[i].isDisplayed = this.checkAssetDisplay(this.currentSection.questions[i]);
            //if question answer is autocalucate
            if (this.currentSection.questions[i].isDisplayed &&
                (this.currentSection.questions[i].survey_q_asset_autocalculate_formulas && this.currentSection.questions[i].survey_q_asset_autocalculate_formulas.length > 0)) {
                this.runAutocalculateForNewlyDIsplayed(this.currentSection.questions[i]);
            }
            if (this.currentSection.questions[i].other_questions && this.currentSection.questions[i].other_questions.length > 0) {
                this.currentSection.questions[i].other_questions[0].isDisplayed = this.checkOtherAnswerQuestionDisplay(this.currentSection.questions[i]);
            }
        }
        for (var k = 0; k < this.currentSection.subsections.length; k++) {
            this.currentSection.subsections[k].isDisplayed = this.checkAssetDisplay(this.currentSection.subsections[k]);
            for (var i = 0; i < this.currentSection.subsections[k].questions.length; i++) {
                this.currentSection.subsections[k].questions[i].isDisplayed = this.checkAssetDisplay(this.currentSection.subsections[k].questions[i]);
                if (this.currentSection.subsections[k].questions[i].isDisplayed &&
                    (this.currentSection.subsections[k].questions[i].survey_q_asset_autocalculate_formulas &&
                        this.currentSection.subsections[k].questions[i].survey_q_asset_autocalculate_formulas.length > 0)) {
                    this.runAutocalculateForNewlyDIsplayed(this.currentSection.subsections[k].questions[i]);
                }
                if (this.currentSection.subsections[k].questions[i].other_questions && this.currentSection.subsections[k].questions[i].other_questions.length > 0) {
                    this.currentSection.subsections[k].questions[i].other_questions[0].isDisplayed = this.checkOtherAnswerQuestionDisplay(this.currentSection.subsections[k].questions[i]);
                }
            }
        }
        for (var k = 0; k < this.currentSection.tables.length; k++) {
            this.currentSection.tables[k].isDisplayed = this.checkAssetDisplay(this.currentSection.tables[k]);
            for (var i = 0; i < this.currentSection.tables[k].questions.length; i++) {
                if (this.currentSection.tables[k].questions[i].other_questions && this.currentSection.tables[k].questions[i].other_questions.length > 0) {
                    if (!this.tableOtherQuestionsIsDisplayed) {
                        this.tableOtherQuestionsIsDisplayed = {};
                    }
                    for (var p = 0; p < this.secondary_asset_keys[this.currentSection.tables[k].id].length; p++) {
                        this.tableOtherQuestionsIsDisplayed[this.currentSection.tables[k].questions[i].other_questions[0].id + '_' + this.secondary_asset_keys[this.currentSection.tables[k].id][p]]
                            = this.checkOtherAnswerQuestionDisplay(this.currentSection.tables[k].questions[i], this.secondary_asset_keys[this.currentSection.tables[k].id][p]);
                    }
                }
            }
            // TODO: Add logic for questions within the table
            for (var i = 0; i < this.currentSection.tables[k].questions.length; i++) {
                if (this.secondary_asset_keys[this.currentSection.tables[k].id] && this.secondary_asset_keys[this.currentSection.tables[k].id].length > 0) {
                    for (var j = 0; j < this.secondary_asset_keys[this.currentSection.tables[k].id].length; j++) {
                        this.currentSection.tables[k].questions[i][this.secondary_asset_keys[this.currentSection.tables[k].id][j] + '_isDisplayed'] =
                            this.checkAssetDisplay(this.currentSection.tables[k].questions[i], this.secondary_asset_keys[this.currentSection.tables[k].id][j]);
                    }
                }
            }
        }
    };
    DynamicQuestionnaireComponent.prototype.runAutocalculateForNewlyDIsplayed = function (question) {
        var sections = this.currentTab.sections;
        if (sections && sections.length > 0) {
            for (var k = 0; k < sections.length; k++) {
                if (sections[k].questions && sections[k].questions.length > 0) {
                    for (var _i = 0, _a = sections[k].questions; _i < _a.length; _i++) {
                        var value = _a[_i];
                        if (value.modifies_qas != null && value.modifies_qas.includes(question.id)) {
                            this.runAutoCalculate(value);
                        }
                    }
                }
                if (sections[k].subsections && sections[k].subsections.length > 0) {
                    for (var j = 0; j < sections[k].subsections.length; j++) {
                        if (sections[k].subsections[j].questions && sections[k].subsections[j].questions.length > 0) {
                            for (var _b = 0, _c = sections[k].subsections[j].questions; _b < _c.length; _b++) {
                                var value = _c[_b];
                                if (value.modifies_qas != null && value.modifies_qas.includes(question.id)) {
                                    this.runAutoCalculate(value);
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    /**
     * Check for simple toggle logic in deciding whether to display / hide an asset
     * By default, display the asset if there is no toggle logic defined for it
     * @param asset
     * @param secondary_asset_key
     */
    DynamicQuestionnaireComponent.prototype.checkAssetDisplay = function (asset, secondary_asset_key) {
        // console.log('checkAssetDisplay secondary_asset_key', secondary_asset_key);
        // If the asset has any defined toggle logic
        if (asset.survey_q_asset_display_logic && asset.survey_q_asset_display_logic.length > 0) {
            var _loop_13 = function (i) {
                // If the condition is of type "value_matches" - i.e. the asset on which this asset's display depends must have a value that exactly matches the condition
                if (asset.survey_q_asset_display_logic[i].logic_type === 'value_matches') {
                    if (this_11.qAnswers.find(function (item) { return !item.unchecked && item.key == asset.survey_q_asset_display_logic[i].display_if_key && item.value == asset.survey_q_asset_display_logic[i].display_if_value && item.secondary_asset_key == secondary_asset_key; })) {
                        return { value: true };
                    }
                }
                // If the condition is of type "value_matches" - i.e. the asset on which this asset's display depends must have any not-null, & not-blank, & non-zero value
                if (asset.survey_q_asset_display_logic[i].logic_type === 'value_exists') {
                    if (this_11.qAnswers.find(function (item) { return !item.unchecked && item.key == asset.survey_q_asset_display_logic[i].display_if_key && item.value && item.value != '' && item.value != 0 && item.secondary_asset_key == secondary_asset_key; })) {
                        return { value: true };
                    }
                }
            };
            var this_11 = this;
            // if (!secondary_asset_key) {
            // For any of the asset's display logic conditions
            for (var i = 0; i < asset.survey_q_asset_display_logic.length; i++) {
                var state_1 = _loop_13(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
            // If after looping through all display logic conditions the function has not returned true already, then return false
            return false;
            // } else {
            // }
        }
        else {
            // Return true in case no display logic is defined for this asset (visible by default)
            return true;
        }
    };
    /**
     * Disable the back / next buttons
     * @param newSectionIndex
     */
    DynamicQuestionnaireComponent.prototype.disableButton = function (newSectionIndex) {
        if (newSectionIndex <= 0 && this.currentTabIndex <= 0) {
            this.showHideBackButton = true;
        }
        else {
            this.showHideBackButton = false;
        }
        if (this.currentTab && this.currentTab.sections && this.questionnaire
            && newSectionIndex == this.currentTab.sections.length - 1
            && this.currentTabIndex == this.questionnaire.length - 1) {
            this.showHideNextButton = true;
        }
        else {
            this.showHideNextButton = false;
        }
    };
    /**
     * On click of Next / back button navigate to the section in a right (index+1) or left (index-1) direction
     */
    DynamicQuestionnaireComponent.prototype.navigateSection = function (direction) {
        // Find the section index to navigate to
        var newSectionIndex = this.currentSectionIndex + direction;
        var curActiveSection = document.querySelector('ul.sub-tab-fixed li a.active');
        this.disableButton(newSectionIndex);
        // If < 0, that indicates you need to move to the previous tab if the tab's index > 0
        if (newSectionIndex < 0) {
            var newTabIndex = this.currentTabIndex + direction;
            if (newTabIndex >= 0) {
                this.tabClickedSectionLast(newTabIndex);
            }
        }
        // If > 0 and less than the number of sections in the current tab, move to the clicked section's index
        else if (newSectionIndex >= 0 && newSectionIndex < this.currentTab.sections.length) {
            this.sectionClicked(newSectionIndex);
            var nextActiveSection = void 0;
            if (curActiveSection) {
                if (direction > 0) {
                    nextActiveSection = curActiveSection.parentElement.nextElementSibling;
                }
                else {
                    nextActiveSection = curActiveSection.parentElement.previousElementSibling;
                }
                var x = document.querySelector('ul.sub-tab-fixed');
                if (x) {
                    x.scrollLeft = nextActiveSection.offsetLeft;
                }
            }
        }
        // In other conditions, navigate to the next tab (e.g. you click next in the last section of any tab, except the last tab)
        else {
            var newTabIndex = this.currentTabIndex + direction;
            if (newTabIndex < this.questionnaire.length && newTabIndex > 0) {
                this.tabClicked(newTabIndex);
            }
        }
    };
    //TODO: What happens on click of exit
    // exitClicked(template): void {
    //   let qAnswersToSubmit = this.qAnswers.filter(item => item.touched);
    //   if (qAnswersToSubmit && qAnswersToSubmit.length > 0) {
    //     this.unsavedAnswerCount = qAnswersToSubmit.length;
    //     this.saveChangesConfirmModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false });
    //   } else {
    //     this.confirmExit();
    //   }
    // }
    /**
     * Handle click of exit button
     */
    DynamicQuestionnaireComponent.prototype.exitClicked = function () {
        if (!navigator.onLine) {
            this.commonService.showToast('info', 'offline_sync_later', {});
        }
        if (this.campaignId) {
            this.router.navigate(["/campaign-profiling-farmers/" + this.projectId + "/" + this.campaignId]);
        }
        else {
            this.router.navigate(["/profiling/" + this.projectId]);
        }
    };
    DynamicQuestionnaireComponent.prototype.closeModal = function (isOkay) {
        this.saveChangesConfirmModal.hide();
        this.subject.next(isOkay);
    };
    DynamicQuestionnaireComponent.prototype.confirmExit = function () {
        var _this = this;
        if (this.saveChangesConfirmModal) {
            this.saveChangesConfirmModal.hide();
            this.unsavedAnswerCount = 0;
        }
        setTimeout(function () { _this.router.navigate(["/profiling/" + _this.projectId]); }, 10);
    };
    DynamicQuestionnaireComponent.prototype.closeSurveyTemplateModal = function () {
        this.saveChangesConfirmModal.hide();
    };
    DynamicQuestionnaireComponent.prototype.backClicked = function () {
        if (this.campaignId) {
            this.router.navigate(["/campaign-profiling-farmers/" + this.projectId + "/" + this.campaignId]);
        }
        else {
            this.router.navigate(["/profiling/" + this.projectId]);
        }
    };
    // Run non-trivial toggle logic - around create / remove a row / column from a table
    // This function handles the scenarios where a change in input = number is supposed to modify a table
    // runDisplayLogic(question, answer) {
    //   // Check for question.toggleType value, e.g. 'generateMultipleChildren' - do not check for asset id
    //   // Get the table that this question is supposed to modify
    //   let tableToModify = this.currentSection.tables.find(item => item.id == question.table_to_modify);
    //   console.log('tableToModify', tableToModify, this.currentSection);
    //   // If it exists
    //   if (tableToModify) {
    //     // If current number of secondary asset keys is less than the newly answered value, new secondary asset key rows / cols must be added to the table
    //     // Initialize the this.secondary_asset_keys for this table
    //     if (!this.secondary_asset_keys[tableToModify.id]) {this.secondary_asset_keys[tableToModify.id] = []}
    //     let currentSecondarys = this.secondary_asset_keys[tableToModify.id].length;
    //     if (currentSecondarys < answer) {
    //       // Iterate as many times as the answer is GREATER than current number of secondary asset keys
    //       for (let k = 0; k < answer - currentSecondarys; k++) {
    //         // Generate a new secondary asset key
    //         let secondary_asset_key = uuidv4();
    //         // Add form controls (per question - key combination)
    //         for (let i = 0; i < tableToModify.questions.length; i++) {
    //           this.sectionForm.addControl(tableToModify.questions[i].id + '_' + secondary_asset_key, new FormControl(''));
    //           if (tableToModify.questions[i].disable_input) {this.sectionForm.controls[tableToModify.questions[i].id + '_' + secondary_asset_key].disable();}
    //           if (tableToModify.questions[i].include_uom_question) {this.setUOMQuestion(tableToModify.questions[i], tableToModify.questions[i].disable_input, {secondary_asset_key: secondary_asset_key, value: ''});}
    //         }
    //         // Add the newly generated key into the secondary_asset_keys object
    //         this.secondary_asset_keys[tableToModify.id].push(secondary_asset_key);
    //         // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
    //         this.addTableQuestionsIntoQAnswers(tableToModify, secondary_asset_key);
    //       }
    //     }
    //     // If current number of secondary asset keys is MORE than the newly answered value, old secondary asset key rows must be removed from the table
    //     else if (currentSecondarys > answer) {
    //       for (let k = 0; k < currentSecondarys - answer; k++) {
    //         // Find the secondary_asset_key to be removed (it is the last row / column in the table)
    //         let secondary_asset_key = this.secondary_asset_keys[tableToModify.id][(currentSecondarys - 1) - k];
    //         // Remove each question from the row / column from the forngroup
    //         for (let i = 0; i < tableToModify.questions.length; i++) {
    //           this.sectionForm.removeControl(tableToModify.questions[i].id + '_' + secondary_asset_key);
    //         }
    //         // Remove that row / col's secondary_asset_key from the this.secondary_asset_keys
    //         this.secondary_asset_keys[tableToModify.id] = this.secondary_asset_keys[tableToModify.id].filter(item => item != secondary_asset_key);
    //         // As the entire row / column is being removed, rark the entire table row / column questions for deletion
    //         for (let i = 0; i < this.qAnswers.length; i++) {
    //           if (this.qAnswers[i].secondary_asset_key == secondary_asset_key) {
    //             this.markDeleted(i);
    //             this.markTouched(i);
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // Run non-trivial toggle logic - around create / remove a row / column from a table
    // This function handles the scenarios where a change in input = checkbox is supposed to modify a table
    // runDisplayLogicCheckBox(question, answer, checked) {
    //   // Get the table that this question is supposed to modify
    //   let tableToModify = this.currentSection.tables.find(item => item.id == question.table_to_modify);
    //   // If it exists
    //   if (tableToModify) {
    //     // Initialize this.secondary_asset_keys[tableToModify.id] for this table
    //     if (!this.secondary_asset_keys[tableToModify.id]) {this.secondary_asset_keys[tableToModify.id] = []}
    //     // Initialize this.secondary_asset_key__options = which option checked/unchecked has triggered addition of the secondary_asset_key
    //     if (!this.secondary_asset_key__options[answer]) {this.secondary_asset_key__options[answer] = []}
    //     // If the checkbox was marked as checked
    //     if (checked) {
    //       // Generate a new secondary_asset_key
    //       let secondary_asset_key = uuidv4();
    //       // For each question in the table, add a form control, & disable user input if required
    //       for (let i = 0; i < tableToModify.questions.length; i++) {
    //         this.sectionForm.addControl(tableToModify.questions[i].id + '_' + secondary_asset_key, new FormControl(''));
    //         if (tableToModify.questions[i].disable_input) {this.sectionForm.controls[tableToModify.questions[i].id + '_' + secondary_asset_key].disable();}
    //         if (tableToModify.questions[i].include_uom_question) {this.setUOMQuestion(tableToModify.questions[i], tableToModify.questions[i].disable_input, {secondary_asset_key: secondary_asset_key, value: ''});}
    //       }
    //       // Add the newly generated secondary_asset_key to this.secondary_asset_keys
    //       this.secondary_asset_keys[tableToModify.id].push(secondary_asset_key);
    //       // Add the newly generated secondary_asset_key as value of this.secondary_asset_key__options[answer]
    //       this.secondary_asset_key__options[answer] = secondary_asset_key;
    //       // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
    //       this.addTableQuestionsIntoQAnswers(tableToModify, secondary_asset_key);
    //     }
    //     // if unchecked
    //     else {
    //       // Find the secondary_asset_key to be removed
    //       let secondaryKeyToRemove = this.secondary_asset_key__options[answer];
    //       // Remove the corresponding form controls
    //       for (let i = 0; i < tableToModify.questions.length; i++) {
    //         this.sectionForm.removeControl(tableToModify.questions[i].id + '_' + secondaryKeyToRemove);
    //       }
    //       // Remove this secondary_asset_key from this.secondary_asset_keys
    //       this.secondary_asset_keys[tableToModify.id] = this.secondary_asset_keys[tableToModify.id].filter(item => item != secondaryKeyToRemove);
    //       // Delete this this.secondary_asset_key__options[answer]
    //       if (this.secondary_asset_key__options[answer]) {delete this.secondary_asset_key__options[answer];}
    //       // As the entire row / column is being removed, rark the entire table row / column questions for deletion
    //       for (let i = 0; i < this.qAnswers.length; i++) {
    //         if (this.qAnswers[i].secondary_asset_key == secondaryKeyToRemove) {
    //           this.qAnswers[i].deleted = true;
    //           this.qAnswers[i].touched = true;
    //         }
    //       }
    //     }
    //   }
    // }
    //clear calendar date for table
    DynamicQuestionnaireComponent.prototype.clearTableCalendarDate = function (e, table, question, secondaryAssetKey) {
        this.sectionForm.controls[question.id + '_' + secondaryAssetKey].setValue('');
        this.sectionFormChangeHandlerForTable(e, table, question, secondaryAssetKey);
    };
    /**
     * Handle input change events
     * Table questions (type = text, number, select)
     * @param args
     * @param table
     * @param question
     * @param secondaryAssetKey
     */
    DynamicQuestionnaireComponent.prototype.sectionFormChangeHandlerForTable = function (args, table, question, secondaryAssetKey) {
        if (this.questionHasNoErrors(question, secondaryAssetKey)) {
            if (!this.qAnswers) {
                this.qAnswers = [];
            }
            // if question subtype is date convert it to readable format
            if (question.qa_subtype == 'date') {
                if (this.sectionForm.value[question.id + '_' + secondaryAssetKey] == undefined) {
                    return;
                }
                var dateSelected = void 0;
                if (this.sectionForm.value[question.id + '_' + secondaryAssetKey] == '') {
                    dateSelected = '';
                }
                else {
                    var dateValue = this.sectionForm.value[question.id + '_' + secondaryAssetKey];
                    var date = void 0;
                    if (typeof dateValue == "string") {
                        var dateParts = dateValue.split("-");
                        date = new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);
                    }
                    else {
                        date = new Date(dateValue);
                    }
                    // const dateSelected = date.getDate() + '-' + this.monthShortNames[date.getMonth()] + '-' + date.getFullYear();
                    dateSelected = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                    // console.log("dateSelected from sectionFormChangeHandlerForTable:",dateSelected);
                }
                this.sectionForm.value[question.id + '_' + secondaryAssetKey] = dateSelected;
            }
            // See if this object already exists in qAnswers
            var x = this.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == secondaryAssetKey; });
            // If object exists, find the index
            var value = this.sectionForm.value[question.id + '_' + secondaryAssetKey];
            // if (this.notADuplicateValue(question, value)) { // Uncomment this function to apply constraint of unique answer value for given question
            if (x) {
                var index = this.qAnswers.indexOf(x);
                // Set this object's touched parameter to true
                this.qAnswers[index]['touched'] = true;
                // Set the value of the formcontrol or set '' in case form control's value is null. This is required as each table question must have an entry in the answers table (even if '')
                this.qAnswers[index]['value'] = (value != null && value !== '') ? value : '';
                // if (this.qAnswers[index]['value'] === '') {this.unsetOtherAnswerValue(question, table, secondaryAssetKey);}
                if (this.qAnswers[index]['deleted']) {
                    this.qAnswers[index]['deleted'] = false;
                }
            }
            else {
                // Set the value of the formcontrol or set '' in case form control's value is null. This is required as each table question must have an entry in the answers table (even if '')
                if (value != null && value !== '') {
                    this.qAnswers.push({ key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: this.sectionForm.value[question.id + '_' + secondaryAssetKey].toString(), touched: true });
                }
                else {
                    this.qAnswers.push({ key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: '', touched: true });
                    // this.unsetOtherAnswerValue(question, table, secondaryAssetKey);
                }
            }
            // Set the display of assets in this section
            this.setSectionFormDisplay();
            this.unsetOtherAnswerValue(question, table, secondaryAssetKey);
            // Run auto calculate logic if applicable
            if (question.modifies_qas) {
                this.runAutoCalculate(question, secondaryAssetKey, true);
            }
            // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
            this.addTableQuestionsIntoQAnswers(table, secondaryAssetKey);
            this.askUserToConfirmExit = true;
            this.modifyConnectedTable(table, secondaryAssetKey, 'update');
            this.modifyConnectedQuestion(table, secondaryAssetKey, 'update');
            if (question.uom_questions && question.uom_questions.length > 0) {
                this.setUOMValuesInQAnswers(question, secondaryAssetKey, table);
            }
            if (table && table.grand_total_questions && table.grand_total_questions.length > 0) {
                this.runGrandTotalCalculate(table.grand_total_questions[0]);
            }
            else {
                if (this.showSummaryForCurrentTab) {
                    this.runTabSummaryCalculate();
                }
            }
            this.unsetDependentQuestions(question, secondaryAssetKey);
            // } 
            // else {
            //   this.toastr.error('This is a duplicate value & cannot be selected');
            //   let x = {};
            //   x[question.id + '_' + secondaryAssetKey] = '';
            //   this.sectionForm.patchValue(x);
            // }
            this.checkAutoValidation(table, secondaryAssetKey);
            if (value != null && value !== '') {
                this.searchKey(secondaryAssetKey);
                if (question.code == '8e4574af-dea9-4db9-a70e-8c2fec029f6f') {
                    this.cropNameQuestionAnswered = true;
                }
            }
            if (value == '' || value == null) {
                if (question.code == '8e4574af-dea9-4db9-a70e-8c2fec029f6f') {
                    this.cropNameQuestionAnswered = false;
                }
                // this.checkAllAnswersRow(secondaryAssetKey);
                // this.checkAllAnswersTable(table);
            }
            this.checkAllAnswersTable(table);
            this.checkMandatoryQuestions();
        }
        else {
            if (question.code == '8e4574af-dea9-4db9-a70e-8c2fec029f6f') {
                this.cropNameQuestionAnswered = false;
                // this.checkAllAnswersRow(secondaryAssetKey);
                this.checkAllAnswersTable(table);
            }
            if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) {
                /* If question is mandatory then disable navigation buttons */
                for (var j = 0; j < question.survey_q_asset_validations.length; j++) {
                    if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
                        this.mandatoryQuestionAnswered = false;
                    }
                }
            }
        }
    };
    DynamicQuestionnaireComponent.prototype.checkAllAnswersRow = function (sak) {
        var allTableSAKAnswers = this.qAnswers.filter(function (item) { return item.secondary_asset_key == sak; });
        var emptyValue = 0;
        var _loop_14 = function (i) {
            var question = void 0;
            for (var _i = 0, _a = this_12.currentSection.tables; _i < _a.length; _i++) {
                var t = _a[_i];
                question = t.questions.find(function (item) { return item.id == i.key; });
            }
            if (i.value != null && i.value !== '' && question) {
                emptyValue++;
            }
        };
        var this_12 = this;
        for (var _i = 0, allTableSAKAnswers_1 = allTableSAKAnswers; _i < allTableSAKAnswers_1.length; _i++) {
            var i = allTableSAKAnswers_1[_i];
            _loop_14(i);
        }
        if (emptyValue == 0) {
            this.tableQuestionAnswered = false;
        }
    };
    DynamicQuestionnaireComponent.prototype.checkAllAnswersTable = function (table) {
        this.tableQuestionAnswered = true;
        var emptyRowsPresentinTable = false;
        if (typeof this.secondary_asset_keys[table.id] !== "undefined") {
            var _loop_15 = function (p, emptyValue) {
                var sak = this_13.secondary_asset_keys[table.id][p];
                var allTableSAKAnswers = this_13.qAnswers.filter(function (item) { return item.secondary_asset_key == sak; });
                var _loop_16 = function (i) {
                    var question = void 0;
                    for (var _i = 0, _a = this_13.currentSection.tables; _i < _a.length; _i++) {
                        var t = _a[_i];
                        question = t.questions.find(function (item) { return item.id == i.key; });
                        if (question) {
                            break;
                        }
                    }
                    if (i.value != null && i.value !== '' && question) {
                        if (!(typeof i.unchecked !== "undefined" && i.unchecked == true)) {
                            emptyValue++;
                        }
                    }
                };
                for (var _i = 0, allTableSAKAnswers_2 = allTableSAKAnswers; _i < allTableSAKAnswers_2.length; _i++) {
                    var i = allTableSAKAnswers_2[_i];
                    _loop_16(i);
                }
                if (emptyValue == 0) {
                    emptyRowsPresentinTable = true;
                    return out_emptyValue_1 = emptyValue, "break";
                }
                out_emptyValue_1 = emptyValue;
            };
            var this_13 = this, out_emptyValue_1;
            for (var p = 0, emptyValue = 0; p < this.secondary_asset_keys[table.id].length; p++, emptyValue = 0) {
                var state_2 = _loop_15(p, emptyValue);
                emptyValue = out_emptyValue_1;
                if (state_2 === "break")
                    break;
            }
        }
        if (emptyRowsPresentinTable === true) {
            this.tableQuestionAnswered = false;
        }
    };
    DynamicQuestionnaireComponent.prototype.checkAutoValidation = function (table, secondaryAssetKey) {
        var allTableSAKAnswers = this.qAnswers.filter(function (item) { return item.secondary_asset_key == secondaryAssetKey; });
        var allTableAnswered = true;
        var totalVisibleQuestions = [];
        var TQcount = 0, count = 0;
        if (table.questions && table.questions.length > 0) {
            for (var _i = 0, _a = table.questions; _i < _a.length; _i++) {
                var q = _a[_i];
                var keyIsDisplayed = secondaryAssetKey + "_isDisplayed";
                if (q[keyIsDisplayed]) {
                    TQcount++;
                    totalVisibleQuestions.push(q.id);
                }
            }
        }
        var _loop_17 = function (i) {
            var findInVisibleQuestion = totalVisibleQuestions.find(function (item) { return item == i.key; });
            if (findInVisibleQuestion) {
                if (i.value == null || i.value == '' || i.unchecked === true) {
                    allTableAnswered = false;
                }
                else {
                    count++;
                }
            }
        };
        for (var _b = 0, allTableSAKAnswers_3 = allTableSAKAnswers; _b < allTableSAKAnswers_3.length; _b++) {
            var i = allTableSAKAnswers_3[_b];
            _loop_17(i);
        }
        if (count > 0) {
            allTableAnswered = true;
        }
        if (allTableAnswered) {
            this.confirmTableAnswer('', table.id, secondaryAssetKey, true);
        }
        else {
            this.confirmTableAnswer('', table.id, secondaryAssetKey, false);
        }
    };
    /**
     * Called for checkbox type question - which has dynamic options (which crop intercropping with surveyed crop?)
     * @param args
     * @param option
     * @param question
     */
    DynamicQuestionnaireComponent.prototype.sectionFormChangeHandlerCheckboxDynamicOptions = function (args, option, question) {
        if (this.questionHasNoErrors(question)) {
            this.updateCheckedObject(args, option.q.toString() + '__' + option.sak.toString(), question.id, null);
            if (!this.qAnswers) {
                this.qAnswers = [];
            }
            // If the checkbox is checked
            if (args.target.checked) {
                var checkedItem = this.qAnswers.find(function (item) { return (item.key == question.id && item.value == option.q + '__' + option.sak); });
                if (checkedItem) {
                    var checkedItemIndex = this.qAnswers.indexOf(checkedItem);
                    if (checkedItemIndex != null && checkedItemIndex > -1) {
                        this.qAnswers[checkedItemIndex]['unchecked'] = false;
                        if (this.qAnswers[checkedItemIndex]['deleted']) {
                            this.qAnswers[checkedItemIndex]['deleted'] = false;
                        }
                    }
                }
                else {
                    // Add the answer to qAnswers array. Value should contain the option asset's id
                    this.qAnswers.push({ key: question.id.toString(), value: option.q.toString() + '__' + option.sak.toString(), touched: true, checked: true });
                }
            }
            else {
                // If checkbox is unchecked, find the unchecked item in qAnswers
                var uncheckedItem = this.qAnswers.find(function (item) { return (item.key == question.id && item.value == option.q + '__' + option.sak); });
                if (uncheckedItem) {
                    var uncheckedItemIndex = this.qAnswers.indexOf(uncheckedItem);
                    if (uncheckedItemIndex != null && uncheckedItemIndex > -1) {
                        // If the unchecked item has an id (meaning it was created & saved in DB previously), then set the unchecked attribute to true
                        // if (this.qAnswers[uncheckedItemIndex].id && this.qAnswers[uncheckedItemIndex].id > 0) {
                        //   this.qAnswers[uncheckedItemIndex]['touched'] = true;
                        //   this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
                        // } else {
                        //   // If there is no id, this checkbox has been checked & unchecked in the same session. The item can be remvoed from qAnswers
                        //   // this.qAnswers = this.qAnswers.filter(item => !(item.key == question.id && item.value == option.id));
                        //   // TODO: Remove this from IDB
                        //   this.qAnswers[uncheckedItemIndex]['touched'] = true;
                        //   this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
                        // }
                        this.qAnswers[uncheckedItemIndex]['touched'] = true;
                        this.qAnswers[uncheckedItemIndex]['unchecked'] = true;
                        //   // TODO: Remove this from IDB
                        // this.unsetOtherAnswerValue(question, null, null);
                    }
                }
            }
            // Run display logic if required
            // if (question.table_to_modify != null && question.table_to_modify != '') {this.runDisplayLogicCheckBox(question, option.id, this.sectionForm.value[question.id]);}
            // Set which assets to display
            this.setSectionFormDisplay();
            this.unsetOtherAnswerValue(question, null, null);
            //check if not any option is selected
            var allQAnswers = this.qAnswers.filter(function (item) { return item.key == question.id; });
            var allEmpty = true;
            for (var _i = 0, allQAnswers_2 = allQAnswers; _i < allQAnswers_2.length; _i++) {
                var i = allQAnswers_2[_i];
                if (!i.unchecked && i.value !== "") {
                    allEmpty = false;
                }
            }
            if (allEmpty) {
                this.confirmAnswer("", question, false, "", false);
            }
            else {
                this.confirmAnswer("", question, true, "", false);
            }
            this.askUserToConfirmExit = true;
        }
    };
    /**
     * Called for checkbox type question in tables - which has dynamic options (which crop intercropping with surveyed crop?)
     * @param args
     * @param table
     * @param question
     * @param option
     * @param secondaryAssetKey
     * @param calledFromUnsetQuestionValue
     */
    DynamicQuestionnaireComponent.prototype.sectionFormChangeHandlerForTableCheckboxDynamicOptions = function (args, table, question, option, secondaryAssetKey, calledFromUnsetQuestionValue) {
        if (this.questionHasNoErrors(question, secondaryAssetKey)) {
            this.updateCheckedObject(args, option.q.toString() + '__' + option.sak.toString(), question.id, secondaryAssetKey);
            if (!this.qAnswers) {
                this.qAnswers = [];
            }
            // Find all the qAnswers with this asset id and secondary asset key and where unchecked != true
            var checkboxOptions = this.qAnswers.filter(function (item) { return item.key == question.id && item.secondary_asset_key == secondaryAssetKey && !item.unchecked; });
            // Find the qAnswer with the clicked option id or value = ''
            var changedItem = this.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == secondaryAssetKey && (item.value === '' || item.value == option.q + '__' + option.sak); });
            if (changedItem) {
                var index = this.qAnswers.indexOf(changedItem);
                if (index != null && index > -1) {
                    // if checkbox is being checked
                    if (args.target.checked) {
                        // Set the value of the option id, set unchecked to false and touched to true
                        this.qAnswers[index]['value'] = option.q.toString() + '__' + option.sak.toString();
                        this.qAnswers[index]['unchecked'] = false;
                        this.qAnswers[index]['touched'] = true;
                        if (this.qAnswers[index]['deleted']) {
                            this.qAnswers[index]['deleted'] = false;
                        }
                    }
                    // If the checkbox is being unchecked
                    else {
                        // Set touched to true
                        this.qAnswers[index]['touched'] = true;
                        // If there is at least one more item in qAnswers with key = question in this column (with this secondary asset key), then mark the unchecked item with unchecked = true
                        // This will delete it from the db
                        if (checkboxOptions.length > 1) {
                            this.qAnswers[index]['unchecked'] = true;
                        }
                        // If the last checkbox in this row / column for this question is being unchecked, set it's value to '' & set unchecked to false
                        // This will ensure it is NOT deleted in the db, just that the value is updated to ''
                        else if (checkboxOptions.length === 1) {
                            this.qAnswers[index]['value'] = '';
                            this.qAnswers[index]['unchecked'] = false;
                        }
                    }
                }
            }
            // If this item does not yet exist in qAnswers, add it
            else {
                this.qAnswers.push({ key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: option.q.toString() + '__' + option.sak.toString(), touched: true, checked: true });
            }
            // Set display of assets in this section
            this.setSectionFormDisplay();
            this.unsetOtherAnswerValue(question, table, secondaryAssetKey);
            // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
            this.addTableQuestionsIntoQAnswers(table, secondaryAssetKey);
            this.askUserToConfirmExit = true;
        }
    };
    /**
     * Handle input change events
     * Table questions (type = checkbox)
     * @param args
     * @param table
     * @param question
     * @param option
     * @param secondaryAssetKey
     */
    DynamicQuestionnaireComponent.prototype.sectionFormChangeHandlerForTableCheckbox = function (args, table, question, option, secondaryAssetKey) {
        if (this.questionHasNoErrors(question, secondaryAssetKey)) {
            this.updateCheckedObject(args, option.id, question.id, secondaryAssetKey);
            if (!this.qAnswers) {
                this.qAnswers = [];
            }
            // Find all the qAnswers with this asset id and secondary asset key and where unchecked != true
            var checkboxOptions = this.qAnswers.filter(function (item) { return item.key == question.id && item.secondary_asset_key == secondaryAssetKey && !item.unchecked; });
            // Find the qAnswer with the clicked option id or value = ''
            var changedItem = this.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == secondaryAssetKey && (item.value === '' || item.value == option.id); });
            if (changedItem) {
                var index = this.qAnswers.indexOf(changedItem);
                if (index != null && index > -1) {
                    // if checkbox is being checked
                    if (args.target.checked) {
                        // Set the value of the option id, set unchecked to false and touched to true
                        this.qAnswers[index]['value'] = option.id.toString();
                        this.qAnswers[index]['unchecked'] = false;
                        this.qAnswers[index]['touched'] = true;
                        if (this.qAnswers[index]['deleted']) {
                            this.qAnswers[index]['deleted'] = false;
                        }
                    }
                    // If the checkbox is being unchecked
                    else {
                        // Set touched to true
                        this.qAnswers[index]['touched'] = true;
                        console.log('checkboxOptions', checkboxOptions);
                        // If there is at least one more item in qAnswers with key = question in this column (with this secondary asset key), then mark the unchecked item with unchecked = true
                        // This will delete it from the db
                        if (checkboxOptions.length > 1) {
                            this.qAnswers[index]['unchecked'] = true;
                        }
                        // If the last checkbox in this row / column for this question is being unchecked, set it's value to '' & set unchecked to false
                        // This will ensure it is NOT deleted in the db, just that the value is updated to ''
                        else if (checkboxOptions.length === 1) {
                            this.qAnswers[index]['value'] = '';
                            this.qAnswers[index]['unchecked'] = false;
                        }
                    }
                }
            }
            // If this item does not yet exist in qAnswers, add it
            else {
                this.qAnswers.push({ key: question.id.toString(), secondary_asset_key: secondaryAssetKey, value: option.id.toString(), touched: true, checked: true });
            }
            //check if not any option is selected
            var allQAnswers = this.qAnswers.filter(function (item) { return item.key == question.id && item.secondary_asset_key == secondaryAssetKey; });
            var allEmpty = true;
            for (var _i = 0, allQAnswers_3 = allQAnswers; _i < allQAnswers_3.length; _i++) {
                var i = allQAnswers_3[_i];
                if (i.value != '' && !i.unchecked) {
                    allEmpty = false;
                }
            }
            if (allEmpty) {
                //to show input required message check if question is mandaory;
                if (question.survey_q_asset_validations) {
                    var checkMandatory = question.survey_q_asset_validations.find(function (item) { return item.validation_key == "mandatory"; });
                    if (checkMandatory) {
                        this.sectionForm.controls[question.id + '_' + secondaryAssetKey].setErrors({ 'required': true });
                    }
                }
            }
            // Set display of assets in this section
            this.setSectionFormDisplay();
            this.unsetOtherAnswerValue(question, table, secondaryAssetKey);
            // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
            this.addTableQuestionsIntoQAnswers(table, secondaryAssetKey);
            this.unsetDependentQuestions(question, secondaryAssetKey);
            this.askUserToConfirmExit = true;
            this.checkAutoValidation(table, secondaryAssetKey);
            this.checkAllAnswersTable(table);
            this.checkMandatoryQuestions();
        }
        else if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) {
            /* If question is mandatory then disable navigation buttons */
            for (var j = 0; j < question.survey_q_asset_validations.length; j++) {
                if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
                    this.mandatoryQuestionAnswered = false;
                }
            }
        }
    };
    /**
     * Run an auto calculate formula calculation when question's answer is modified
     * @param question
     * @param secondary_asset_key
     */
    DynamicQuestionnaireComponent.prototype.runAutoCalculate = function (question, secondary_asset_key, fromUI) {
        var _this = this;
        // Find which questionnaire assets are modified by this asset (who's change event has triggered this function call)
        var qasToModify = question.modifies_qas.split(",");
        var _loop_18 = function (i) {
            // Try to find the question in the section's child questions
            var qaToModify = this_14.getAssetNew(qasToModify[i]);
            // If any such question is found
            if (qaToModify) {
                if (this_14.checkAssetDisplay(qaToModify)) {
                    // Define and set value of the target questions form control, depending on whether secondary_asset_key exists or not
                    var fcName = void 0;
                    var uomName_1;
                    fcName = secondary_asset_key ? qaToModify.id + '_' + secondary_asset_key : qaToModify.id;
                    fcName = fcName.toString();
                    if (qaToModify.uom_questions && qaToModify.uom_questions.length > 0) {
                        uomName_1 = secondary_asset_key ? qaToModify.uom_questions[0].id + '_' + secondary_asset_key : qaToModify.uom_questions[0].id;
                        uomName_1 = uomName_1.toString();
                    }
                    // Get the formula to be evaluated (e.g. "135,*,161")
                    if (qaToModify.survey_q_asset_autocalculate_formulas && qaToModify.survey_q_asset_autocalculate_formulas.length > 0) {
                        var formula = qaToModify.survey_q_asset_autocalculate_formulas[0].formula;
                        // Find the elements of the formula [135, *, 161]
                        var formulaArray_1 = formula.split(",");
                        var formulaObjArray = [];
                        var _loop_19 = function (p_1) {
                            // Ignore non-number elements of the formula
                            if (isNaN(parseInt(formulaArray_1[p_1]))) { }
                            else {
                                // Find the value of the questionnaire asset id in the formula from the qAnswers array
                                var answer = void 0;
                                var x_3 = void 0;
                                if (!secondary_asset_key) {
                                    // answer = this.qAnswers.find(item => item.key == formulaArray[p]) ? this.qAnswers.find(item => item.key == formulaArray[p]).value : null;
                                    x_3 = this_14.qAnswers.find(function (item) { return item.key == formulaArray_1[p_1]; }) ? this_14.qAnswers.find(function (item) { return item.key == formulaArray_1[p_1]; }) : null;
                                }
                                else {
                                    // answer = this.qAnswers.find(item => item.key == formulaArray[p] && item.secondary_asset_key == secondary_asset_key) ? this.qAnswers.find(item => item.key == formulaArray[p] && item.secondary_asset_key == secondary_asset_key).value : null;
                                    x_3 = this_14.qAnswers.find(function (item) { return item.key == formulaArray_1[p_1] && item.secondary_asset_key == secondary_asset_key; }) ? this_14.qAnswers.find(function (item) { return item.key == formulaArray_1[p_1] && item.secondary_asset_key == secondary_asset_key; }) : null;
                                }
                                // If any of the answers is not a valid value, set the answer as 0
                                if (x_3 == null || (x_3.value && x_3.value == "")) {
                                    x_3 = { key: formulaArray_1[p_1], value: 0, secondary_asset_key: secondary_asset_key ? secondary_asset_key : null };
                                }
                                // formulaArray[p] = parseFloat(answer);
                                formulaObjArray.push(x_3);
                            }
                        };
                        for (var p_1 = 0; p_1 < formulaArray_1.length; p_1++) {
                            _loop_19(p_1);
                        }
                        // Evaluate the formula string with values
                        var y = JSON.parse(JSON.stringify(formulaObjArray));
                        var p = this_14.newAdjustFormulaArray(qaToModify, y, formulaArray_1, secondary_asset_key);
                        if (!p) {
                            return { value: void 0 };
                        }
                        for (var k = 0; k < p['formula'].length; k++) {
                            if (p['formula'][k] === '') {
                                p['formula'][k] = 0;
                            }
                        }
                        var answerToUpdate = this_14.calculateFormula(p['formula']);
                        answerToUpdate = answerToUpdate ? answerToUpdate.toString() : '';
                        // Create the patchValue object
                        var x = {};
                        x[fcName] = answerToUpdate;
                        if (uomName_1) {
                            x[uomName_1] = p['unit'];
                        }
                        // Patch the auto calculated value in the formgroup
                        this_14.sectionForm.patchValue(x);
                        // Add / Update the value in qAnswers
                        var answerOfQaToModify = void 0;
                        var answerOfQaToModifyUOM = void 0;
                        if (secondary_asset_key) {
                            answerOfQaToModify = this_14.qAnswers.find(function (item) { return item.key == qaToModify.id && item.secondary_asset_key == secondary_asset_key; });
                            if (uomName_1) {
                                answerOfQaToModifyUOM = this_14.qAnswers.find(function (item) { return item.key == uomName_1 && item.secondary_asset_key == secondary_asset_key; });
                            }
                        }
                        else {
                            answerOfQaToModify = this_14.qAnswers.find(function (item) { return item.key == qaToModify.id; });
                            if (uomName_1) {
                                answerOfQaToModifyUOM = this_14.qAnswers.find(function (item) { return item.key == uomName_1; });
                            }
                        }
                        if (answerOfQaToModify) {
                            var index_1 = this_14.qAnswers.indexOf(answerOfQaToModify);
                            if (index_1 != null && index_1 > -1) {
                                this_14.qAnswers[index_1]['value'] = answerToUpdate.toString();
                                this_14.qAnswers[index_1]['touched'] = true;
                                //auto validation added for auto calculated answers
                                var findQn = this_14.currentSection.questions.find(function (item) { return item.id == _this.qAnswers[index_1].key; });
                                if (findQn) {
                                    //checking if all questions are validated or not
                                    var formula2 = findQn.survey_q_asset_autocalculate_formulas[0].formula;
                                    var formulaArray2 = formula2.split(",");
                                    var qArray = formulaArray2.filter(function (item) { return !isNaN(parseFloat(item)); });
                                    var questionCount = 0;
                                    var _loop_20 = function (qid) {
                                        var x_4 = void 0;
                                        if (!secondary_asset_key) {
                                            x_4 = this_14.qAnswers.find(function (item) { return item.key == qid; });
                                        }
                                        else {
                                            x_4 = this_14.qAnswers.find(function (item) { return item.key == qid && item.secondary_asset_key == secondary_asset_key; });
                                        }
                                        if (x_4 && x_4.flex3) {
                                            questionCount++;
                                        }
                                        //to check if current question type is grand total
                                        for (var _i = 0, _a = this_14.currentSection.tables; _i < _a.length; _i++) {
                                            var t = _a[_i];
                                            if (t.grand_total_questions && t.grand_total_questions.length > 0) {
                                                var gt = t.grand_total_questions.find(function (item) { return item.id == qid; });
                                                var findA = this_14.qAnswers.find(function (item) { return item.key == qid; });
                                                if (gt && fromUI && !findA) {
                                                    questionCount++;
                                                }
                                            }
                                        }
                                    };
                                    for (var _i = 0, qArray_1 = qArray; _i < qArray_1.length; _i++) {
                                        var qid = qArray_1[_i];
                                        _loop_20(qid);
                                    }
                                    if (qArray.length == questionCount) {
                                        if (answerToUpdate !== "") {
                                            this_14.confirmAnswer("", findQn, true, true);
                                        }
                                        else {
                                            this_14.confirmAnswer("", findQn, false, true);
                                        }
                                    }
                                }
                                var findSubSecQn = null;
                                for (var _a = 0, _b = this_14.currentSection.subsections; _a < _b.length; _a++) {
                                    var j = _b[_a];
                                    findSubSecQn = j.questions.find(function (item) { return item.id == _this.qAnswers[index_1].key; });
                                }
                                if (findSubSecQn) {
                                    //checking if all questions are validated or not
                                    var formula3 = findSubSecQn.survey_q_asset_autocalculate_formulas[0].formula;
                                    var formulaArray3 = formula3.split(",");
                                    var qArray2 = formulaArray3.filter(function (item) { return !isNaN(parseFloat(item)); });
                                    var questionCount2 = 0;
                                    var _loop_21 = function (qid) {
                                        var x_5 = void 0;
                                        if (!secondary_asset_key) {
                                            x_5 = this_14.qAnswers.find(function (item) { return item.key == qid; });
                                        }
                                        else {
                                            x_5 = this_14.qAnswers.find(function (item) { return item.key == qid && item.secondary_asset_key == secondary_asset_key; });
                                        }
                                        if (x_5 && x_5.flex3) {
                                            questionCount2++;
                                        }
                                        //to check if current question type is grand total
                                        for (var _i = 0, _a = this_14.currentSection.tables; _i < _a.length; _i++) {
                                            var t = _a[_i];
                                            if (t.grand_total_questions && t.grand_total_questions.length > 0) {
                                                var gt = t.grand_total_questions.find(function (item) { return item.id == qid; });
                                                var findA = this_14.qAnswers.find(function (item) { return item.key == qid; });
                                                if (gt && fromUI && !findA) {
                                                    questionCount2++;
                                                }
                                            }
                                        }
                                    };
                                    for (var _c = 0, qArray2_2 = qArray2; _c < qArray2_2.length; _c++) {
                                        var qid = qArray2_2[_c];
                                        _loop_21(qid);
                                    }
                                    if (qArray2.length == questionCount2) {
                                        if (answerToUpdate !== "") {
                                            this_14.confirmAnswer("", findSubSecQn, true, true);
                                        }
                                        else {
                                            this_14.confirmAnswer("", findSubSecQn, false, true);
                                        }
                                    }
                                }
                                var findQnTable = void 0;
                                var tableid = void 0;
                                for (var _d = 0, _e = this_14.currentSection.tables; _d < _e.length; _d++) {
                                    var t = _e[_d];
                                    findQnTable = t.questions.find(function (item) { return item.id == _this.qAnswers[index_1].key; });
                                    tableid = t.id;
                                }
                                if (findQnTable) {
                                    if (answerToUpdate !== "") {
                                        this_14.confirmTableAnswer("", tableid, secondary_asset_key, true);
                                    }
                                    else {
                                        this_14.confirmTableAnswer("", tableid, secondary_asset_key, false);
                                    }
                                }
                            }
                        }
                        else {
                            this_14.qAnswers.push({ key: qaToModify.id.toString(), value: answerToUpdate.toString(), secondary_asset_key: secondary_asset_key, touched: true });
                        }
                        if (answerOfQaToModifyUOM) {
                            var index = this_14.qAnswers.indexOf(answerOfQaToModifyUOM);
                            if (index != null && index > -1) {
                                this_14.qAnswers[index]['value'] = p['unit'].toString();
                                this_14.qAnswers[index]['touched'] = true;
                                this_14.qAnswers[index]['flex3'] = answerToUpdate ? "A" : "NA";
                            }
                        }
                        else {
                            if (p['unit']) {
                                this_14.qAnswers.push({ key: uomName_1.toString(), value: p['unit'].toString(), secondary_asset_key: secondary_asset_key, touched: true });
                            }
                        }
                        // In case the auto calcualted field is part of another auto calculation (as an input), then run the next auto calculation as well
                        if (qaToModify.modifies_qas) {
                            this_14.runAutoCalculate(qaToModify, secondary_asset_key);
                        }
                        if (secondary_asset_key) {
                            var parentTable = this_14.getParentAsset(question);
                            // console.log('parentTable', parentTable);
                            if (parentTable && parentTable.grand_total_questions && parentTable.grand_total_questions.length > 0) {
                                this_14.runGrandTotalCalculate(parentTable.grand_total_questions[0]);
                            }
                            else if (parentTable && (!parentTable.grand_total_questions || parentTable.grand_total_questions.length == 0)) {
                                if (this_14.showSummaryForCurrentTab) {
                                    this_14.runTabSummaryCalculate();
                                }
                            }
                        }
                    }
                }
            }
        };
        var this_14 = this;
        // For each such questionnaire asset to be modified
        for (var i = 0; i < qasToModify.length; i++) {
            var state_3 = _loop_18(i);
            if (typeof state_3 === "object")
                return state_3.value;
        }
    };
    /**
     * Check if the uom_question has a value, & create the corresponding sectionForm control. Also add the options to be displayed in the dropdown
     * @param question
     * @param disable
     * @param existsInQAnswersForTableQuestionItem
     */
    DynamicQuestionnaireComponent.prototype.setUOMQuestion = function (question, disable, existsInQAnswersForTableQuestionItem) {
        if (!existsInQAnswersForTableQuestionItem) {
            // If the question has uom_questions children elements
            if (question.uom_questions && question.uom_questions.length > 0) {
                var _loop_22 = function (i) {
                    // For each uom_question child element, check if it has a value in qAnswers, & create the form control accordingly
                    var existsInQAnswers = this_15.existsInQAnswers(question.uom_questions[i]);
                    this_15.sectionForm.addControl(question.uom_questions[i].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
                    if (disable) {
                        this_15.sectionForm.controls[question.uom_questions[i].id].disable();
                    }
                    // Set the options dropdown for this uom_question
                    question.uom_questions[i].options = this_15.project.ProjectUOM.filter(function (item) { return item.unit_type_id == question.uom_questions[i].qa_subtype; });
                    this_15.setUOMValuesInForm(question.uom_questions[i].id, question.uom_questions[i].options, null);
                };
                var this_15 = this;
                for (var i = 0; i < question.uom_questions.length; i++) {
                    _loop_22(i);
                }
            }
        }
        else {
            if (question.uom_questions && question.uom_questions.length > 0) {
                var _loop_23 = function (i) {
                    var valueObject = this_16.qAnswers.find(function (item) {
                        return item.key == question.uom_questions[i].id && item.secondary_asset_key === existsInQAnswersForTableQuestionItem['secondary_asset_key'];
                    });
                    this_16.sectionForm.addControl(question.uom_questions[i].id + '_' + existsInQAnswersForTableQuestionItem['secondary_asset_key'], valueObject != null ? new FormControl(valueObject['value']) : new FormControl(''));
                    if (disable) {
                        this_16.sectionForm.controls[question.uom_questions[i].id + '_' + existsInQAnswersForTableQuestionItem['secondary_asset_key']].disable();
                    }
                    question.uom_questions[i].options = this_16.project.ProjectUOM.filter(function (item) { return item.unit_type_id == question.uom_questions[i].qa_subtype; });
                    this_16.setUOMValuesInForm(question.uom_questions[i].id, question.uom_questions[i].options, existsInQAnswersForTableQuestionItem['secondary_asset_key']);
                };
                var this_16 = this;
                for (var i = 0; i < question.uom_questions.length; i++) {
                    _loop_23(i);
                }
            }
        }
    };
    /**
     * Set the angular reactive form validators for the given question
     * @param question
     * @param secondaryAssetKey
     */
    DynamicQuestionnaireComponent.prototype.setQuestionValidations = function (question, secondaryAssetKey) {
        if (question.survey_q_asset_validations && question.survey_q_asset_validations.length > 0) {
            var formControlName = question.id;
            if (secondaryAssetKey) {
                formControlName = formControlName + '_' + secondaryAssetKey;
            }
            var arrValidators = [];
            for (var j = 0; j < question.survey_q_asset_validations.length; j++) {
                if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
                    arrValidators.push(Validators.required);
                }
                if (question.survey_q_asset_validations[j].validation_key == 'regex_pattern') {
                    arrValidators.push(Validators.pattern(question.survey_q_asset_validations[j].validation_value));
                }
                if (question.survey_q_asset_validations[j].validation_key == 'min_value') {
                    arrValidators.push(Validators.min(question.survey_q_asset_validations[j].validation_value));
                }
                if (question.survey_q_asset_validations[j].validation_key == 'max_value') {
                    arrValidators.push(Validators.max(question.survey_q_asset_validations[j].validation_value));
                }
            }
            this.sectionForm.controls[formControlName].setValidators(arrValidators);
            this.sectionForm.controls[formControlName].updateValueAndValidity();
        }
    };
    /**
     * For table questions, ALL questions' answers must be entered in the answers table, even if the answer is ''
     * This is because the question asset id and secondary asset id must be present for every question in each unique column / row of the table
     * @param table
     * @param sak
     * @param setValueForKey
     * @param valueToSet
     */
    DynamicQuestionnaireComponent.prototype.addTableQuestionsIntoQAnswers = function (table, sak, setValueForKey, valueToSet) {
        if (table && table.questions) {
            var _loop_24 = function (i) {
                var x = this_17.qAnswers.find(function (item) { return item.key == table.questions[i].id && item.secondary_asset_key === sak; });
                if (x) {
                    // Do nothing, as the value for this question id and secondary asset key is already in qAnswers
                }
                else {
                    // Add this question id and secondary asset key to qAnswers
                    this_17.qAnswers.push({ key: table.questions[i].id.toString(), secondary_asset_key: sak, value: setValueForKey == table.questions[i].id.toString() ? valueToSet : '', touched: true });
                    // if (table.questions[i].uom_questions && table.questions[i].uom_questions.length > 0) {
                    //   for (let j = 0; j < table.questions[i].uom_questions.length; j++) {
                    //     this.qAnswers.push({key: table.questions[i].uom_questions[j].id.toString(), secondary_asset_key: sak, value: '', touched: true});
                    //   }
                    // }
                }
                if (table.questions[i].other_questions && table.questions[i].other_questions.length > 0) {
                    var y = this_17.qAnswers.find(function (item) { return item.key == table.questions[i].other_questions[0].id && item.secondary_asset_key === sak; });
                    if (y) {
                        // Do nothing, as the value for this question id and secondary asset key is already in qAnswers
                    }
                    else {
                        // Add this question id and secondary asset key to qAnswers
                        this_17.qAnswers.push({ key: table.questions[i].other_questions[0].id.toString(), secondary_asset_key: sak, value: setValueForKey == table.questions[i].other_questions[0].id.toString() ? valueToSet : '', touched: true });
                        if (table.questions[i].uom_questions && table.questions[i].uom_questions.length > 0) {
                            for (var j = 0; j < table.questions[i].uom_questions.length; j++) {
                                this_17.qAnswers.push({ key: table.questions[i].uom_questions[j].id.toString(), secondary_asset_key: sak, value: '', touched: true });
                            }
                        }
                    }
                }
                if (table.questions[i].uom_questions && table.questions[i].uom_questions.length > 0) {
                    var _loop_25 = function (j) {
                        var z = this_17.qAnswers.find(function (item) { return item.key == table.questions[i].uom_questions[j].id && item.secondary_asset_key === sak; });
                        if (z) {
                            // Do nothing, as the value for this question id and secondary asset key is already in qAnswers
                        }
                        else {
                            // Add this question id and secondary asset key to qAnswers
                            this_17.qAnswers.push({ key: table.questions[i].uom_questions[j].id.toString(), secondary_asset_key: sak, value: setValueForKey === table.questions[i].uom_questions[j].id.toString() ? valueToSet : '', touched: true });
                        }
                    };
                    for (var j = 0; j < table.questions[i].uom_questions.length; j++) {
                        _loop_25(j);
                    }
                }
            };
            var this_17 = this;
            for (var i = 0; i < table.questions.length; i++) {
                _loop_24(i);
            }
        }
    };
    /**
     * Set the values in this.secondary_asset_key__options given a qAnswers array
     */
    DynamicQuestionnaireComponent.prototype.setSecondaryAssetKeyOptionValues = function () {
        for (var i = 0; i < this.qAnswers.length; i++) {
            if (this.qAnswers[i].sak_of_modified != null) {
                this.secondary_asset_key__options[this.qAnswers[i].secondary_asset_key] = this.qAnswers[i].sak_of_modified;
            }
        }
    };
    /**
     * Add a row / column to the table (click on Add button)
     * @param table
     */
    DynamicQuestionnaireComponent.prototype.addToTable = function (table) {
        var tableToModify = this.currentSection.tables.find(function (item) { return item.id == table.id; });
        if (tableToModify) {
            // Initialize the this.secondary_asset_keys for this table
            if (!this.secondary_asset_keys[tableToModify.id]) {
                this.secondary_asset_keys[tableToModify.id] = [];
            }
            var secondary_asset_key = uuidv4();
            // Add form controls (per question - key combination)
            for (var i = 0; i < tableToModify.questions.length; i++) {
                this.sectionForm.addControl(tableToModify.questions[i].id + '_' + secondary_asset_key, new FormControl(''));
                if (tableToModify.questions[i].disable_input) {
                    this.sectionForm.controls[tableToModify.questions[i].id + '_' + secondary_asset_key].disable();
                }
                if (tableToModify.questions[i].include_uom_question) {
                    this.setUOMQuestion(tableToModify.questions[i], tableToModify.questions[i].disable_input, { secondary_asset_key: secondary_asset_key, value: '' });
                }
                this.setQuestionValidations(tableToModify.questions[i], secondary_asset_key);
            }
            // Add the newly generated key into the secondary_asset_keys object
            this.secondary_asset_keys[tableToModify.id].push(secondary_asset_key);
            // Add ALL questions in each row / column to qAnswers with value = '' if none exists. This has to be done so that the table can be rendered properly
            this.addTableQuestionsIntoQAnswers(tableToModify, secondary_asset_key);
            // Add the "other" questions if any to the table
            for (var i = 0; i < tableToModify.questions.length; i++) {
                this.setOtherQuestion(tableToModify.questions[i], secondary_asset_key);
            }
            this.modifyConnectedQuestion(tableToModify, secondary_asset_key, 'add');
            this.modifyConnectedTable(tableToModify, secondary_asset_key, 'add');
            this.setSectionFormDisplay();
            this.askUserToConfirmExit = true;
            this.totalSectionAnswered = false;
            //to disable add button
            this.tableQuestionAnswered = false;
            var obj = {
                sak: secondary_asset_key
            };
            this.newlyAddedKeys.push(obj);
            //disable navigations for crop name question
            if (table.code == '04c85617-7d54-4efd-b3b9-17db042ddb5d') {
                this.cropNameQuestionAnswered = false;
            }
            this.checkMandatoryQuestions();
        }
    };
    /**
     * When a table is modified, automatically modify its connected table (e.g. addotopma; crop details modifies the agri income table])
     * @param table
     * @param secondary_asset_key
     * @param action
     */
    DynamicQuestionnaireComponent.prototype.modifyConnectedQuestion = function (table, secondary_asset_key, action) {
        if (table && table.displayFunction && table.displayFunction.indexOf("addOptionToQ") > -1) {
            var tableModifyDetails = JSON.parse(table.displayFunction);
            // Find the questions that need an option addition
            if (tableModifyDetails.addOptionToQ) {
                var addOptionToQ = tableModifyDetails.addOptionToQ.split(",");
                var qToAddAsOption_1 = tableModifyDetails.qToAddAsOption;
                if (action == 'add') {
                    if (!this.dynamicOptionsObject) {
                        this.dynamicOptionsObject = {};
                    }
                    for (var i = 0; i < addOptionToQ.length; i++) {
                        if (!this.dynamicOptionsObject[addOptionToQ[i]]) {
                            this.dynamicOptionsObject[addOptionToQ[i]] = [];
                        }
                        if (!this.dynamicOptionsObject[addOptionToQ[i]].find(function (item) { return item.sak == secondary_asset_key; })) {
                            this.dynamicOptionsObject[addOptionToQ[i]].push({ q: qToAddAsOption_1, sak: secondary_asset_key, label: '' });
                        }
                    }
                }
                else if (action == 'update') {
                    var qLabelToUpdate = this.qAnswers.find(function (item) { return item.key == qToAddAsOption_1 && item.secondary_asset_key == secondary_asset_key; });
                    for (var i = 0; i < addOptionToQ.length; i++) {
                        var p = this.dynamicOptionsObject[addOptionToQ[i]].find(function (item) { return item.sak == secondary_asset_key; });
                        var index = this.dynamicOptionsObject[addOptionToQ[i]].indexOf(p);
                        if (this.validIndex) {
                            this.dynamicOptionsObject[addOptionToQ[i]][index]['label'] = qLabelToUpdate.value;
                        }
                    }
                }
            }
            // console.log('this.dynamicOptionsObject', this.dynamicOptionsObject);
        }
    };
    /**
     * Remove a given row/column from a table
     * @param table
     * @param secondary_asset_key
     */
    DynamicQuestionnaireComponent.prototype.removeFromTable = function (table, secondary_asset_key) {
        // this is older version of tableToModify let tableToModify = this.currentSection.tables.find(item => item.id == table.id);
        var tableToModify = table;
        var dynamicOptionForSAKIsUsed = this.checkIfDynamicOptionIsUsed(table, secondary_asset_key);
        if (dynamicOptionForSAKIsUsed) {
            this.commonService.showToast('error', 'input_used_in_another_question', {});
            return;
        }
        if (tableToModify) {
            for (var i = 0; i < tableToModify.questions.length; i++) {
                // Remove the control from the sectionForm
                this.sectionForm.removeControl(tableToModify.questions[i].id + '_' + secondary_asset_key);
                // Remove the other question control from the sectionForm
                if (tableToModify.questions[i].other_questions && tableToModify.questions[i].other_questions.length > 0) {
                    this.sectionForm.removeControl(tableToModify.questions[i].other_questions[0].id + '_' + secondary_asset_key);
                }
                // Remove the uom question controls from the sectionForm
                if (tableToModify.questions[i].uom_questions && tableToModify.questions[i].uom_questions.length > 0) {
                    for (var j = 0; j < tableToModify.questions[i].uom_questions.length; j++) {
                        this.sectionForm.removeControl(tableToModify.questions[i].uom_questions[j].id + '_' + secondary_asset_key);
                    }
                }
            }
            // Remove that row / col's secondary_asset_key from the this.secondary_asset_keys
            if (this.secondary_asset_keys[tableToModify.id]) {
                this.secondary_asset_keys[tableToModify.id] = this.secondary_asset_keys[tableToModify.id].filter(function (item) { return item != secondary_asset_key; });
            }
            // As the entire row / column is being removed, rark the entire table row / column questions for deletion
            for (var i = 0; i < this.qAnswers.length; i++) {
                if (this.qAnswers[i].secondary_asset_key == secondary_asset_key) {
                    this.markDeleted(i);
                    this.markTouched(i);
                }
            }
            this.modifyConnectedTable(tableToModify, secondary_asset_key, 'remove');
            if (tableToModify != null && tableToModify.grand_total_questions != null && tableToModify.grand_total_questions.length > 0) {
                this.runGrandTotalCalculate(tableToModify.grand_total_questions[0]);
            }
            else if (tableToModify != null && (!tableToModify.grand_total_questions || tableToModify.grand_total_questions.length == 0)) {
                if (this.showSummaryForCurrentTab) {
                    this.runTabSummaryCalculate();
                }
            }
            this.setDynamicOptionsObject();
            this.setSectionFormDisplay();
            this.checkAllQuestionAnswered();
            this.askUserToConfirmExit = true;
            //added for empty row issue
            this.searchKey(secondary_asset_key);
            this.checkAllAnswersTable(table);
            this.checkMandatoryQuestions();
            //enable navigations for crop name question
            if (table.code == '04c85617-7d54-4efd-b3b9-17db042ddb5d') {
                var emptyCrop = 0;
                var findSAKs = this.secondary_asset_keys[table.id];
                if (findSAKs.length == 0) {
                    this.cropNameQuestionAnswered = true;
                }
                else {
                    var _loop_26 = function (sak) {
                        var _loop_27 = function (q) {
                            if (q.code == '8e4574af-dea9-4db9-a70e-8c2fec029f6f') {
                                var crop = this_18.qAnswers.find(function (item) { return item.key == q.id && item.secondary_asset_key == sak; });
                                if (crop.value == "" || crop.value == null) {
                                    emptyCrop++;
                                }
                            }
                        };
                        for (var _i = 0, _a = table.questions; _i < _a.length; _i++) {
                            var q = _a[_i];
                            _loop_27(q);
                        }
                    };
                    var this_18 = this;
                    for (var _i = 0, findSAKs_2 = findSAKs; _i < findSAKs_2.length; _i++) {
                        var sak = findSAKs_2[_i];
                        _loop_26(sak);
                    }
                }
                if (emptyCrop == 0) {
                    this.cropNameQuestionAnswered = true;
                }
                else {
                    this.cropNameQuestionAnswered = false;
                }
            }
        }
    };
    //search key in newly added array and if found delete that object
    DynamicQuestionnaireComponent.prototype.searchKey = function (key) {
        var findKey = this.newlyAddedKeys.find(function (item) { return item.sak == key; });
        if (findKey) {
            this.tableQuestionAnswered = true;
        }
    };
    /**
     * If the table's cell value is used as a dynamic option in another question, check if an dynamic option answer with this table cell (q & secondary asset key) already exists
     * @param table
     * @param secondary_asset_key
     */
    DynamicQuestionnaireComponent.prototype.checkIfDynamicOptionIsUsed = function (table, secondary_asset_key) {
        if (table.displayFunction && table.displayFunction.indexOf("addOptionToQ") > -1) {
            var tableModifyDetails = JSON.parse(table.displayFunction);
            // Find the questions that need an option addition
            var addOptionToQ_1 = tableModifyDetails.addOptionToQ.split(",");
            var _loop_28 = function (i) {
                var answerExists = this_19.qAnswers.find(function (item) { return item.key == addOptionToQ_1[i] && item.value.indexOf(secondary_asset_key) > -1 && !item.unchecked; });
                if (answerExists) {
                    return { value: true };
                }
            };
            var this_19 = this;
            for (var i = 0; i < addOptionToQ_1.length; i++) {
                var state_4 = _loop_28(i);
                if (typeof state_4 === "object")
                    return state_4.value;
            }
        }
        return false;
    };
    /**
     * Add 'other' question as a child asset of the given question
     * @param question
     * @param secondaryAssetKey
     */
    DynamicQuestionnaireComponent.prototype.setOtherQuestion = function (question, secondaryAssetKey) {
        if (question.options && question.options.length > 0) {
            if (question.options.find(function (item) { return item.qa_subtype == 'other'; })) {
                if (secondaryAssetKey) {
                    var existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(question.other_questions[0]);
                    // console.log('existsInQAnswersForTableQuestion', existsInQAnswersForTableQuestion, this.sectionForm);
                    if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
                        for (var i = 0; i < existsInQAnswersForTableQuestion.length; i++) {
                            // console.log('existsInQAnswersForTableQuestion', existsInQAnswersForTableQuestion[i].value, existsInQAnswersForTableQuestion[i].secondary_asset_key, secondaryAssetKey);
                            if (existsInQAnswersForTableQuestion[i].secondary_asset_key == secondaryAssetKey) {
                                // Create a form control for each question
                                this.sectionForm.addControl(question.other_questions[0].id + '_' + secondaryAssetKey, existsInQAnswersForTableQuestion[i].value ? new FormControl(existsInQAnswersForTableQuestion[i].value) : new FormControl(''));
                                this.setQuestionValidations(question.other_questions[0], secondaryAssetKey);
                            }
                        }
                    }
                }
                else {
                    var existsInQAnswers = this.existsInQAnswers(question.other_questions[0]);
                    // Create a form control for each question
                    this.sectionForm.addControl(question.other_questions[0].id, existsInQAnswers ? new FormControl(existsInQAnswers['value']) : new FormControl(''));
                    this.setQuestionValidations(question.other_questions[0]);
                }
            }
        }
    };
    /**
     * Check whether the "other" question should be disabled
     * @param question
     * @param secondary_asset_key
     */
    DynamicQuestionnaireComponent.prototype.checkOtherAnswerQuestionDisplay = function (question, secondary_asset_key) {
        if (question.other_questions && question.other_questions.length > 0 && question.options && question.options.length > 0) {
            var otherOption_1 = question.options.find(function (item) { return item.qa_subtype == 'other'; });
            if (otherOption_1) {
                if (this.qAnswers.find(function (item) { return !item.unchecked && item.key == question.id && item.value == otherOption_1.id && item.secondary_asset_key == secondary_asset_key; })) {
                    return true;
                }
                return false;
            }
        }
    };
    /**
     * Modify the connected table's entry
     * @param sourceTable
     * @param sak_of_source_table_element
     * @param action
     * @param sak_of_modified
     */
    DynamicQuestionnaireComponent.prototype.modifyConnectedTable = function (sourceTable, sak_of_source_table_element, action, sak_of_modified) {
        if (sourceTable && sourceTable.displayFunction && sourceTable.displayFunction.indexOf('modifyTable') > -1) {
            var tableModifyDetails_1 = JSON.parse(sourceTable.displayFunction);
            if (action == 'add') {
                var assetToModify = this.getAsset(tableModifyDetails_1.modifyTable);
                if (assetToModify) {
                    var secondary_asset_key = uuidv4();
                    this.addTableQuestionsIntoQAnswers(assetToModify, secondary_asset_key);
                    this.secondary_asset_key__options[sak_of_source_table_element] = secondary_asset_key;
                    for (var i = 0; i < this.qAnswers.length; i++) {
                        if (this.qAnswers[i].secondary_asset_key == sak_of_source_table_element) {
                            this.qAnswers[i].sak_of_modified = secondary_asset_key;
                        }
                    }
                }
            }
            else if (action == 'update' && tableModifyDetails_1.sourceQId && tableModifyDetails_1.targetQId) {
                var assetToModify = this.getAsset(tableModifyDetails_1.modifyTable);
                if (assetToModify) {
                    // Update the qAnswers
                    var answerValueToSet_1 = this.qAnswers.find(function (item) { return item.secondary_asset_key === sak_of_source_table_element && item.key == tableModifyDetails_1.sourceQId; });
                    // console.log('answerValueToSet', answerValueToSet);
                    if (answerValueToSet_1) {
                        var qAnswerToUpdate = this.qAnswers.find(function (item) { return item.secondary_asset_key === answerValueToSet_1.sak_of_modified; });
                        // console.log('qAnswerToUpdate', qAnswerToUpdate);
                        if (qAnswerToUpdate) {
                            var index = this.qAnswers.indexOf(qAnswerToUpdate);
                            // console.log('index', index);
                            if (this.validIndex(index)) {
                                this.qAnswers[index].value = answerValueToSet_1 != null ? answerValueToSet_1.value : '';
                                this.markTouched(index);
                            }
                        }
                        // Update the formcontrol's value if the target control is present in the sectionForm
                        if (this.sectionForm.controls[tableModifyDetails_1.targetQId + '_' + answerValueToSet_1.sak_of_modified]) {
                            var x = {};
                            x[tableModifyDetails_1.targetQId + "_" + answerValueToSet_1.sak_of_modified] = answerValueToSet_1 != null ? answerValueToSet_1.value : '';
                            // Patch the auto calculated value in the formgroup
                            this.sectionForm.patchValue(x);
                        }
                    }
                }
            }
            else if (action === 'remove' && tableModifyDetails_1.sourceQId && tableModifyDetails_1.targetQId) {
                var assetToModify = this.getAsset(tableModifyDetails_1.modifyTable);
                if (assetToModify) {
                    var answerValueToSet = this.qAnswers.find(function (item) { return item.secondary_asset_key === sak_of_source_table_element && item.key === tableModifyDetails_1.sourceQId; });
                    this.removeFromTable(assetToModify, answerValueToSet.sak_of_modified);
                }
            }
            // console.log('this.qAnswers', this.qAnswers);
            // console.log('this.secondary_asset_key__options', this.secondary_asset_key__options);
        }
    };
    /**
     * Gets the table asset corresponding to assetId
     * @param assetId
     */
    DynamicQuestionnaireComponent.prototype.getAsset = function (assetId) {
        var asset;
        loop1: for (var i = 0; i < this.questionnaire.length; i++) {
            if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                        for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                            if (assetId == this.questionnaire[i].sections[j].tables[k].id) {
                                asset = this.questionnaire[i].sections[j].tables[k];
                                break loop1;
                            }
                        }
                    }
                }
            }
        }
        return asset;
    };
    /**
     * Given a child asset, get its parent asset
     * @param childAsset
     */
    DynamicQuestionnaireComponent.prototype.getParentAsset = function (childAsset) {
        var parentAsset;
        if (childAsset.qa_type == 'question' || childAsset.qa_type == 'grand_total_question') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
                        parentAsset = this.questionnaire[i].sections[j];
                        break loop1;
                    }
                    else {
                        var parentSubsection = this.questionnaire[i].sections[j].subsections.find(function (item) { return item.code == childAsset.parent_code; });
                        if (parentSubsection) {
                            parentAsset = parentSubsection;
                            break loop1;
                        }
                        var parentTable = this.questionnaire[i].sections[j].tables.find(function (item) { return item.code == childAsset.parent_code; });
                        if (parentTable) {
                            parentAsset = parentTable;
                            break loop1;
                        }
                    }
                }
            }
        }
        if (childAsset.qa_type == 'subsection') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
                        parentAsset = this.questionnaire[i].sections[j];
                        break loop1;
                    }
                }
            }
        }
        if (childAsset.qa_type == 'section') {
            for (var i = 0; i < this.questionnaire.length; i++) {
                parentAsset = this.questionnaire.find(function (item) { return item.code == childAsset.parent_code; });
            }
        }
        if (childAsset.qa_type == 'option' || childAsset.qa_type == 'other_question' || childAsset.qa_type == 'uom_question') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    var parentQuestion = this.questionnaire[i].sections[j].questions.find(function (item) { return item.code == childAsset.parent_code; });
                    if (parentQuestion) {
                        parentAsset = parentQuestion;
                        break loop1;
                    }
                    else {
                        if (this.questionnaire[i].sections[j].subsections && this.questionnaire[i].sections[j].subsections.length > 0) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].subsections.length; k++) {
                                var parentQuestion_1 = this.questionnaire[i].sections[j].subsections[k].questions.find(function (item) { return item.code == childAsset.parent_code; });
                                if (parentQuestion_1) {
                                    parentAsset = parentQuestion_1;
                                    break loop1;
                                }
                            }
                        }
                        if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                            for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                                var parentQuestion_2 = this.questionnaire[i].sections[j].tables[k].questions.find(function (item) { return item.code == childAsset.parent_code; });
                                if (parentQuestion_2) {
                                    parentAsset = parentQuestion_2;
                                    break loop1;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (childAsset.qa_type == 'table') {
            loop1: for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (childAsset.parent_code == this.questionnaire[i].sections[j].code) {
                        parentAsset = this.questionnaire[i].sections[j];
                        break loop1;
                    }
                }
            }
        }
        return parentAsset;
    };
    /**
     * Add the default crop entry to the table
     * @param table
     */
    DynamicQuestionnaireComponent.prototype.addDefaultCropEntry = function (table) {
        var cropName = this.project.Product.name;
        var displayFunction = JSON.parse(table.displayFunction);
        var x = this.qAnswers.find(function (item) { return item.key == displayFunction.setQId && item.value == cropName; });
        if (x) {
            // Do nothing
            this.defaultCropSak = x.secondary_asset_key;
        }
        else {
            this.defaultCropSak = uuidv4();
            this.addTableQuestionsIntoQAnswers(table, this.defaultCropSak, displayFunction.setQId, cropName);
        }
    };
    /**
     * Checks whether the given index is valid (not null, > -1)
     * @param index
     */
    DynamicQuestionnaireComponent.prototype.validIndex = function (index) {
        if (index != null && index > -1) {
            return true;
        }
        return false;
    };
    /**
     * Mark the given array entry as 'touched', i.e. to be sent to the API
     * @param index
     */
    DynamicQuestionnaireComponent.prototype.markTouched = function (index) {
        this.qAnswers[index].touched = true;
    };
    /**
     * Mark the given array entry as deleted
     * @param index
     */
    DynamicQuestionnaireComponent.prototype.markDeleted = function (index) {
        this.qAnswers[index].deleted = true;
    };
    /**
     * Save the profiling answers data (ONLINE mode)
     * @param qAnswersToSubmit
     * @param alsoExit
     * @param stats
     */
    DynamicQuestionnaireComponent.prototype.saveProfilingDataOnline = function (qAnswersToSubmit, alsoExit, stats) {
        var _this = this;
        this.askUserToConfirmExit = false;
        if (this.campaignId) {
            this.dynamicQuestionnaireService.saveProfilingDataForCampaign(qAnswersToSubmit, this.rawFarmerId, this.campaignId, new Date().getTime(), stats)
                .subscribe(function (result) {
                _this.spinner.hide();
                _this.toastr.clear();
                _this.commonService.showToast('success', 'changes_saved', {});
                _this.updateQAnswers(result.message);
                if (alsoExit) {
                    _this.confirmExit();
                }
            });
        }
        else {
            this.dynamicQuestionnaireService.saveProfilingData(qAnswersToSubmit, this.rawFarmerId, new Date().getTime(), stats)
                .subscribe(function (result) {
                _this.toastr.clear();
                _this.spinner.hide();
                _this.commonService.showToast('success', 'changes_saved', {});
                _this.updateQAnswers(result.message);
                if (alsoExit) {
                    _this.confirmExit();
                }
            });
        }
    };
    /**
     * Save the profiling answers data to indexed DB (OFFLINE mode)
     *
     * @param qAnswersToSubmit
     * @param alsoExit
     * @param answerStats
     */
    DynamicQuestionnaireComponent.prototype.saveProfilingDataOffline = function (qAnswersToSubmit, alsoExit, answerStats) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmertmpobj, qAnswersForThisFarmerId, i, values, newAnswersArray, _loop_29, j, uniqueId, x;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(qAnswersToSubmit && qAnswersToSubmit.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        farmertmpobj = _a.sent();
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            qAnswersForThisFarmerId = farmertmpobj.filter(function (item) {
                                return (String(item.tmp_id) === String(_this.rawFarmerId) && String(item.campaignId) === String(_this.campaignId));
                            });
                            if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
                                for (i = 0; i < qAnswersForThisFarmerId.length; i++) {
                                    values = JSON.parse(qAnswersForThisFarmerId[i].values);
                                    newAnswersArray = [];
                                    _loop_29 = function (j) {
                                        var a = qAnswersToSubmit[j];
                                        if (values.length == 1 && values[0].value == '' && values[0].flex2 == 'A') {
                                            a['flex2'] = 'A';
                                            newAnswersArray.push(a);
                                        }
                                        else if (a.secondary_asset_key) {
                                            if (a.value != null && !a.deleted && !a.unchecked) {
                                                if (a.checked) {
                                                    newAnswersArray.push(a);
                                                }
                                                else {
                                                    var x = values.find(function (item) { return item.key == a.key && item.secondary_asset_key === a.secondary_asset_key; });
                                                    if (x) {
                                                        var xIndex = values.indexOf(x);
                                                        var y = values[xIndex];
                                                        y['value'] = a.value;
                                                        y['flex3'] = a.flex3;
                                                        if (a.imgBase64) {
                                                            y['imgBase64'] = a.imgBase64;
                                                        }
                                                        newAnswersArray.push(y);
                                                    }
                                                    else {
                                                        newAnswersArray.push(a);
                                                    }
                                                }
                                            }
                                            else if (a.value != null && a.id && a.unchecked) {
                                                newAnswersArray.push(a);
                                            }
                                            else if (a.value === "" && a.touched && a.flex2 === "A" && !a.flex3) {
                                                newAnswersArray.push(a);
                                            }
                                            else if (a.value !== null && a.id && a.deleted && a.flex3) {
                                                newAnswersArray.push(a);
                                            }
                                        }
                                        else {
                                            // Non-checkbox
                                            if (a.value != null && !a.deleted && !a.unchecked) {
                                                if (a.checked) {
                                                    newAnswersArray.push(a);
                                                }
                                                else {
                                                    var x = values.find(function (item) { return item.key == a.key; });
                                                    if (x) {
                                                        var xIndex = values.indexOf(x);
                                                        var y = values[xIndex];
                                                        y['value'] = a.value;
                                                        y['flex3'] = a.flex3;
                                                        y['deleted'] = a.deleted;
                                                        newAnswersArray.push(y);
                                                    }
                                                    else {
                                                        a["flex2"] = "A";
                                                        newAnswersArray.push(a);
                                                    }
                                                }
                                            }
                                            else if (a.value != null && a.id && a.unchecked) {
                                                newAnswersArray.push(a);
                                            }
                                            else if (a.value === "" && a.flex3) {
                                                newAnswersArray.push(a);
                                            }
                                            else if (a.value === "" && a.touched && typeof a.flex3 === "undefined") {
                                                a["flex2"] = "A";
                                                newAnswersArray.push(a);
                                            }
                                        }
                                    };
                                    for (j = 0; j < qAnswersToSubmit.length; j++) {
                                        _loop_29(j);
                                    }
                                    // Remove the old entry for this unique_id and add a new entry in indexedDB with a new unique_id
                                    idbApp.removeDataFromStore('dq_profiling', qAnswersForThisFarmerId[i].unique_id);
                                    if (newAnswersArray && newAnswersArray.length > 0) {
                                        uniqueId = uuidv4();
                                        x = { unique_id: uniqueId, tmp_id: this.rawFarmerId,
                                            updatedAt: new Date().getTime(), values: JSON.stringify(newAnswersArray),
                                            stats: JSON.stringify(answerStats), campaignId: this.campaignId };
                                        idbApp.addStoreInfo([x], 'dq_profiling');
                                        this.askUserToConfirmExit = false;
                                    }
                                }
                            }
                            else {
                                this.addNewEntrytoNQIDB(qAnswersToSubmit, answerStats);
                            }
                        }
                        else {
                            this.addNewEntrytoNQIDB(qAnswersToSubmit, answerStats);
                        }
                        _a.label = 2;
                    case 2:
                        if (alsoExit) {
                            this.confirmExit();
                        }
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Add offline profiling answers to indexedDB (when answers do not already exist in indexedDB for this tmp_id)
     * @param qAnswersToSubmit
     * @param stats
     */
    DynamicQuestionnaireComponent.prototype.addNewEntrytoNQIDB = function (qAnswersToSubmit, stats) {
        var y1 = qAnswersToSubmit.filter(function (a) { return a.checked || a.unchecked; });
        var y2 = qAnswersToSubmit.filter(function (a) { return (!a.secondary_asset_key && !a.checked && !a.unchecked && a.value != null && a.value != '' && !a.deleted)
            || (a.secondary_asset_key && !a.checked && !a.unchecked && a.value != null && !a.deleted); });
        var na = qAnswersToSubmit.filter(function (a) { return a.value == '' && a.flex3 == 'NA' && !a.deleted; });
        //added by KRPT for fixing offline delete answer issue
        //when user was removing numerical question answer or dropdown reset to select in offline mode, 
        // it was not getting saved and hence after syncing answer remained as it is.
        var deleted = qAnswersToSubmit.filter(function (a) { return (a.value == '' || a.value == null || a.secondary_asset_key) && a.deleted == true; });
        var y11 = [];
        var y21 = [];
        if (y1 && y1.length > 0)
            y11 = y1;
        if (y2 && y2.length > 0)
            y21 = y2;
        var y = y11.concat(y21);
        y = y.concat(na);
        y = y.concat(deleted);
        if (y && y.length > 0) {
            var uniqueId = uuidv4();
            var x = { unique_id: uniqueId, tmp_id: this.rawFarmerId,
                updatedAt: new Date().getTime(), values: JSON.stringify(y),
                stats: JSON.stringify(stats), campaignId: this.campaignId };
            idbApp.addStoreInfo([x], 'dq_profiling');
            this.askUserToConfirmExit = false;
        }
    };
    /**
     * Not used. This can be deleted
     * @param uniqueId
     */
    DynamicQuestionnaireComponent.prototype.postOfflineIDBDataToAPI = function (uniqueId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var farmertmpobj, uniqueIdData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        farmertmpobj = _a.sent();
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            uniqueIdData = farmertmpobj.filter(function (item) { return item.unique_id == uniqueId; });
                            // console.log('uniqueIdData', uniqueIdData);
                            this.dynamicQuestionnaireService.saveProfilingDataOffline(uniqueIdData[0]).subscribe(function (result) { });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Mark all entries of qAnswers as touched so they are considered to be synced with DB
     * @param valueToSet
     */
    DynamicQuestionnaireComponent.prototype.updateAllQAnswersTouchedValue = function (valueToSet) {
        if (this.qAnswers && this.qAnswers.length > 0) {
            for (var i = 0; i < this.qAnswers.length; i++) {
                this.qAnswers[i].touched = valueToSet;
            }
        }
    };
    DynamicQuestionnaireComponent.prototype.registerSyncEvent = function () {
        serviceWorkerVar.sync.register('nqDataSync');
    };
    /**
     * Get all displayed assets (considering applied display logic and other answers)
     */
    DynamicQuestionnaireComponent.prototype.getAllDisplayedQuestions = function () {
        var displayedQuestions = [];
        var otherquestions = [];
        var tableQns = [];
        for (var i = 0; i < this.questionnaire.length; i++) {
            for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                var section = this.questionnaire[i].sections[j];
                for (var k = 0; k < section.questions.length; k++) {
                    if (this.checkAssetDisplay(section.questions[k])) {
                        displayedQuestions.push(section.questions[k]);
                        if (section.questions[k].uom_questions && section.questions[k].uom_questions.length > 0) {
                            for (var p = 0; p < section.questions[k].uom_questions.length; p++) {
                                displayedQuestions.push(section.questions[k].uom_questions[p]);
                            }
                        }
                    }
                }
                for (var k = 0; k < section.subsections.length; k++) {
                    if (this.checkAssetDisplay(section.subsections[k])) {
                        for (var m = 0; m < section.subsections[k].questions.length; m++) {
                            if (this.checkAssetDisplay(section.subsections[k].questions[m])) {
                                displayedQuestions.push(section.subsections[k].questions[m]);
                                if (section.subsections[k].questions[m].uom_questions && section.subsections[k].questions[m].uom_questions.length > 0) {
                                    for (var p = 0; p < section.subsections[k].questions[m].uom_questions.length; p++) {
                                        displayedQuestions.push(section.subsections[k].questions[m].uom_questions[p]);
                                    }
                                }
                                if (section.subsections[k].questions[m].other_questions && section.subsections[k].questions[m].other_questions.length > 0) {
                                    if (this.checkOtherAnswerQuestionDisplay(section.subsections[k].questions[m])) {
                                        displayedQuestions.push(section.subsections[k].questions[m].other_questions[0]);
                                    }
                                }
                            }
                        }
                    }
                }
                for (var k = 0; k < section.tables.length; k++) {
                    // Added by SBJY
                    var isDisplayed = this.checkAssetDisplay(section.tables[k]);
                    if (isDisplayed) {
                        for (var i_1 = 0; i_1 < section.tables[k].questions.length; i_1++) {
                            var sskeys = this.existsInQAnswersForTableQuestion(section.tables[k].questions[i_1]);
                            sskeys = sskeys.reduce(function (accumulator, current) {
                                if (!accumulator.some(function (item) { return item.key === current.key && item.secondary_asset_key === current.secondary_asset_key; })) {
                                    accumulator.push(current);
                                }
                                return accumulator;
                            }, []);
                            for (var j_1 = 0; j_1 < sskeys.length; j_1++) {
                                if (sskeys[j_1].flex3) {
                                    var displayed = this.checkAssetDisplay(section.tables[k].questions[i_1], sskeys[j_1].secondary_asset_key);
                                    if (displayed) {
                                        displayedQuestions.push(section.tables[k].questions[i_1]);
                                        tableQns.push(section.tables[k].questions[i_1]);
                                    }
                                }
                            }
                            // Push 'other' questions in the multiselect option into different array. This is used later to remove other answers to count no. answers
                            if (section.tables[k].questions[i_1].other_questions.length > 0) {
                                for (var m = 0; m < section.tables[k].questions[i_1].other_questions.length; m++) {
                                    otherquestions.push(section.tables[k].questions[i_1].other_questions[m]);
                                }
                            }
                        }
                    }
                    // Commented by SBJY
                    // To remove grand total question from total questions 
                    // if (section.tables[k].grand_total_questions && section.tables[k].grand_total_questions.length > 0) {
                    //   displayedQuestions.push(section.tables[k].grand_total_questions[0]);
                    // }
                    // }
                    // TODO: Add logic for questions within the table
                    // for (let i = 0; i < section.tables[k].questions.length; i++) {
                    //   if (this.secondary_asset_keys[section.tables[k].id] && this.secondary_asset_keys[section.tables[k].id].length > 0) {
                    //     for (let j = 0; j < this.secondary_asset_keys[section.tables[k].id].length; j++) {
                    //       console.log('this.secondary_asset_keys[section.tables[k].id][j]', this.secondary_asset_keys[section.tables[k].id][j]);
                    //       section.tables[k].questions[i][this.secondary_asset_keys[section.tables[k].id][j] + '_isDisplayed'] = this.checkAssetDisplay(section.tables[k].questions[i], this.secondary_asset_keys[section.tables[k].id][j]);
                    //     }
                    //   }
                    // }
                }
            }
        }
        return { displayedQuestions: displayedQuestions, otherquestions: otherquestions, tableQns: tableQns };
    };
    ;
    /**
     * Calculate the answer stats for this farmer
     */
    DynamicQuestionnaireComponent.prototype.getAnswerStatsLocal = function () {
        var _this = this;
        var displayedQuestionsObj = this.getAllDisplayedQuestions();
        var answerExistsForTableNonBlankNew = [];
        var answerExistsForTableNonBlankNewV2 = [];
        var uomQnIds = [];
        var answerExistsForQuestionsNonTable = displayedQuestionsObj.displayedQuestions.filter(function (item) { return _this.qAnswers.find(function (each) { return each.key == item.id && !each.deleted && !each.secondary_asset_key && each.flex3; }); });
        var answerExistsForTable = this.qAnswers.filter(function (item) { return (item.secondary_asset_key && item.flex3 && !item.deleted); });
        var answerExistsForTableNonBlank = answerExistsForTable.filter(function (item) { return item.value != ''; });
        // Get unique objects based on key and seconday asset key
        answerExistsForTableNonBlank = answerExistsForTableNonBlank.reduce(function (accumulator, current) {
            if (!accumulator.some(function (item) { return item.key === current.key && item.secondary_asset_key === current.secondary_asset_key; })) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);
        var _loop_30 = function (i) {
            var findIndex = displayedQuestionsObj.otherquestions.findIndex(function (item) { return parseInt(item.id) === parseInt(answerExistsForTableNonBlank[i].key); });
            if (findIndex === -1) {
                answerExistsForTableNonBlankNew.push(answerExistsForTableNonBlank[i]);
            }
        };
        // Remove other answers from answers array with the help of displayedQuestionsObj.otherquestions array
        for (var i = 0; i < answerExistsForTableNonBlank.length; i++) {
            _loop_30(i);
        }
        //---- Remove metric answers from table answers array
        for (var i = 0; i < displayedQuestionsObj.tableQns.length; i++) {
            for (var j = 0; j < displayedQuestionsObj.tableQns[i].uom_questions.length; j++) {
                uomQnIds.push(displayedQuestionsObj.tableQns[i].uom_questions[j].id);
            }
        }
        uomQnIds = Array.from(new Set(uomQnIds));
        var _loop_31 = function (i) {
            var findIndex = uomQnIds.findIndex(function (item) { return parseInt(item) === parseInt(answerExistsForTableNonBlankNew[i].key); });
            if (findIndex === -1) {
                answerExistsForTableNonBlankNewV2.push(answerExistsForTableNonBlankNew[i]);
            }
        };
        for (var i = 0; i < answerExistsForTableNonBlankNew.length; i++) {
            _loop_31(i);
        }
        //-------
        // console.log("uomQnIds:", uomQnIds);
        // console.log("displayedQuestions:", displayedQuestionsObj);
        // console.log("answerExistsForQuestionsNonTable:", answerExistsForQuestionsNonTable);
        // console.log("displayedQuestionsObj.otherquestions:", displayedQuestionsObj.otherquestions);
        // console.log("total qns: ", displayedQuestionsObj.displayedQuestions.length);
        // console.log("total ans: ", answerExistsForQuestionsNonTable.length + answerExistsForTableNonBlankNewV2.length);
        // console.log("answerExistsForQuestionsNonTableV2:", answerExistsForQuestionsNonTableV2);
        // console.log("answerExistsForTableNonBlankNewV2:", answerExistsForTableNonBlankNewV2);
        // ----------
        // The below code is only for debugging purpose.
        // let arr = [];
        //  displayedQuestionsObj.displayedQuestions.forEach((item1) => {
        //    let findIndex = answerExistsForQuestionsNonTable.findIndex((item) => item.id == item1.id);
        //    if (findIndex == -1) {
        //      let findIndex2 = answerExistsForTableNonBlankNewV2.findIndex((item) => item.key == item1.id);
        //      if (findIndex2 === -1) {
        //        console.log("Missed id: ", item1);
        //        arr.push(item1.id);
        //      }
        //    }
        // }) 
        // console.log("arr:", arr.join(","));
        // ---------- 
        return [displayedQuestionsObj.displayedQuestions[0].survey_id, displayedQuestionsObj.displayedQuestions.length, answerExistsForQuestionsNonTable.length + answerExistsForTableNonBlankNewV2.length];
    };
    /** Not used. This can be deleted */
    DynamicQuestionnaireComponent.prototype.removeKeyFromIDBSavedNQData = function (key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var indexedDBEntries, farmertmpobj, qAnswersForThisFarmerId, i, values;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        indexedDBEntries = [];
                        return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        farmertmpobj = _a.sent();
                        // console.log('farmertmpobj', farmertmpobj);
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            qAnswersForThisFarmerId = farmertmpobj.filter(function (item) { return item.tmp_id === _this.rawFarmerId; });
                            // console.log('qAnswersForThisFarmerId', qAnswersForThisFarmerId);
                            if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
                                for (i = 0; i < qAnswersForThisFarmerId.length; i++) {
                                    values = JSON.parse(qAnswersForThisFarmerId[i].values);
                                    values = values.filter(function (item) { return item.key != key; });
                                    if (values.length > 0) {
                                        // Update the values string in the idb object
                                    }
                                    else {
                                        // Delete the idb object
                                        idbApp.removeDataFromStore('dq_profiling', qAnswersForThisFarmerId[i].unique_id);
                                    }
                                }
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Toggle display of the wizard - quick notes
     */
    DynamicQuestionnaireComponent.prototype.wizardToggle = function () {
        this.wizardShow = !this.wizardShow;
    };
    /**
     * Save quick notes (ONLINE / OFFLINE mode)
     */
    DynamicQuestionnaireComponent.prototype.saveNotes = function () {
        var _this = this;
        this.wizardToggle();
        if (this.notes.id > 0) {
            this.notes.upd_user_id = this.currentUser.id;
            this.notes.upd_date = new Date();
        }
        else {
            this.notes.cr_user_id = this.currentUser.id;
        }
        this.notes.farmer_id = this.farmerId;
        if (navigator.onLine) {
            this.farmerService.SaveNotes(this.notes).subscribe(function (res) {
                if (res.msg === 'success') {
                    if (!_this.notes.id) {
                        res.data.cr_date = new Date();
                    }
                    _this.notes = res.data;
                    // this.toastrService.success('');
                }
            });
        }
        else {
            // console.log('this.notes', this.notes);
            var finalObj_1 = {};
            var farmerObj_1 = this.notes;
            Object.keys(farmerObj_1).forEach(function (key) {
                finalObj_1[key] = farmerObj_1[key];
            });
            var farmObj = new Array();
            var index = this.rawFarmerId.indexOf('_');
            var rawFarmerIdEdited = this.rawFarmerId.substr(0, index);
            finalObj_1['tmp_id'] = this.farmerId > 0 ? this.farmerId + "_QN" : rawFarmerIdEdited + "_QN";
            finalObj_1['savetab'] = 'QN';
            finalObj_1['randnum'] = Math.random().toString().slice(2, 12);
            finalObj_1['project_id'] = this.projectId;
            finalObj_1['upd_user_id'] = this.currentUser.id;
            farmObj[farmObj.length] = finalObj_1;
            idbApp.addStoreInfo(farmObj, 'farmerstmp');
        }
    };
    /** Not used. this can be deleted */
    DynamicQuestionnaireComponent.prototype.selectRevision = function () {
        if (this.selectedRevision != '') {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { revisionId: this.selectedRevision },
                queryParamsHandling: 'merge'
            });
        }
        else {
            // console.log('1562');
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { revisionId: '' },
                queryParamsHandling: 'merge'
            });
        }
    };
    /**
     * Given a qAnswer, if it has a uom question, convert the value into
     * project default unit and return (for grand total calculation)
     * @param qAnswer
     */
    DynamicQuestionnaireComponent.prototype.getUOMConvertedValue = function (qAnswer) {
        var asset = this.getAssetNew(qAnswer.key);
        var valueToReturn;
        if (asset && asset.uom_questions && asset.uom_questions.length > 0) {
            var uomQuestion_1 = asset.uom_questions[0];
            var uomAnswer_1 = this.qAnswers.find(function (item) { return item.key == uomQuestion_1.id && item.secondary_asset_key === qAnswer.secondary_asset_key; });
            if (!uomAnswer_1.value || uomAnswer_1.value == '') {
                return 0;
            }
            else {
                var conversionFactor = this.processedUOMs[uomQuestion_1['qa_subtype']]['units'].find(function (item) { return item.id == uomAnswer_1.value; })['conversionFactorToProjectDefault'];
                valueToReturn = qAnswer.value * conversionFactor;
            }
        }
        else {
            return qAnswer.value;
        }
        return valueToReturn;
    };
    /**
     * Run the grand total calculation for the given grand total question
     * @param grandTotalQuestion
     */
    DynamicQuestionnaireComponent.prototype.runGrandTotalCalculate = function (grandTotalQuestion) {
        var displayFunction = grandTotalQuestion.displayFunction;
        if (displayFunction && this.qAnswers && this.qAnswers.length > 0) {
            var displayFnJSON = JSON.parse(displayFunction);
            var grandTotal = 0;
            var numberOfDecimals = 0;
            for (var i = 0; i < this.qAnswers.length; i++) {
                if (this.qAnswers[i].key == displayFnJSON['grandTotalOf'] && !this.qAnswers[i].deleted && this.qAnswers[i].value && this.qAnswers[i].value != '') {
                    // console.log('answer object', this.qAnswers[i]);
                    // grandTotal = grandTotal + parseInt(this.qAnswers[i].value);
                    var convertedValue = this.getUOMConvertedValue(this.qAnswers[i]);
                    var p = this.getOutputDecimals([this.qAnswers[i]['value']]);
                    if (p > numberOfDecimals) {
                        numberOfDecimals = p;
                    }
                    if (convertedValue) {
                        grandTotal = grandTotal + parseFloat(convertedValue);
                    }
                }
            }
            var grandTotalValueToSet = grandTotal.toFixed(2);
            var x = this.qAnswers.find(function (item) { return item.key == grandTotalQuestion.id; });
            if (x) {
                var xIndex = this.qAnswers.indexOf(x);
                if (this.validIndex(xIndex)) {
                    this.qAnswers[xIndex].value = grandTotalValueToSet;
                    this.qAnswers[xIndex].touched = true;
                    this.qAnswers[xIndex].flex3 = 'A';
                }
            }
            else {
                this.qAnswers.push({
                    key: grandTotalQuestion.id,
                    value: grandTotalValueToSet,
                    touched: true,
                    flex3: 'A'
                });
            }
            var y = {};
            y[grandTotalQuestion.id] = grandTotalValueToSet;
            // Patch the auto calculated value in the formgroup
            this.sectionForm.patchValue(y);
            if (grandTotalQuestion.modifies_qas) {
                this.runAutoCalculate(grandTotalQuestion, null, true);
            }
            if (this.showSummaryForCurrentTab) {
                this.runTabSummaryCalculate();
            }
        }
    };
    /**
     * Check that the given formcontrol has any errors or not
     * @param question
     * @param secondaryAssetKey
     */
    DynamicQuestionnaireComponent.prototype.questionHasNoErrors = function (question, secondaryAssetKey) {
        if (secondaryAssetKey) {
            if (this.sectionForm.controls[question.id + '_' + secondaryAssetKey] && this.sectionForm.controls[question.id + '_' + secondaryAssetKey].errors) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            if (this.sectionForm.controls[question.id] && this.sectionForm.controls[question.id].errors) {
                return false;
            }
            else {
                return true;
            }
        }
    };
    /**
     * Create the set of dynamic options for the two questions
     * crop intercropping with surveyed crop
     * crop intercropping with surveyed crop (field level)
     */
    DynamicQuestionnaireComponent.prototype.setDynamicOptionsObject = function () {
        if (this.questionnaire && this.questionnaire !== 'noSurveys' && this.questionnaire.length > 0) {
            this.dynamicOptionsObject = {};
            for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                        var _loop_32 = function (k) {
                            if (this_20.questionnaire[i].sections[j].tables[k].displayFunction && this_20.questionnaire[i].sections[j].tables[k].displayFunction.indexOf("addOptionToQ") > -1) {
                                var tableModifyDetails = JSON.parse(this_20.questionnaire[i].sections[j].tables[k].displayFunction);
                                if (tableModifyDetails && tableModifyDetails.addOptionToQ) {
                                    var addOptionToQ = tableModifyDetails.addOptionToQ.split(",");
                                    var qToAddAsOption_2 = tableModifyDetails.qToAddAsOption;
                                    var existingAnswers_1 = this_20.qAnswers.filter(function (item) { return item.key == qToAddAsOption_2; });
                                    if (existingAnswers_1 && existingAnswers_1.length > 0) {
                                        for (var p = 0; p < addOptionToQ.length; p++) {
                                            var _loop_33 = function (q) {
                                                if (!existingAnswers_1[q].deleted) {
                                                    if (!this_20.dynamicOptionsObject[addOptionToQ[p]]) {
                                                        this_20.dynamicOptionsObject[addOptionToQ[p]] = [];
                                                    }
                                                    if (!this_20.dynamicOptionsObject[addOptionToQ[p]].find(function (item) { return item.sak == existingAnswers_1[q].secondary_asset_key; })) {
                                                        this_20.dynamicOptionsObject[addOptionToQ[p]].push({ q: qToAddAsOption_2, sak: existingAnswers_1[q].secondary_asset_key, label: existingAnswers_1[q].value });
                                                    }
                                                }
                                            };
                                            for (var q = 0; q < existingAnswers_1.length; q++) {
                                                _loop_33(q);
                                            }
                                        }
                                    }
                                }
                            }
                        };
                        var this_20 = this;
                        for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                            _loop_32(k);
                        }
                    }
                }
            }
        }
    };
    /**
     * Given array of formula elements, calculate the formula and return value
     * e.g. ["40","*","3"] would return 120
     * @param fnArray
     */
    DynamicQuestionnaireComponent.prototype.calculateFormula = function (fnArray) {
        if (fnArray && fnArray.length > 0) {
            var numberOfDecimalsOfOutput = this.getOutputDecimals(fnArray);
            var answer = void 0;
            for (var i = 0; i < fnArray.length; i++) {
                if (!fnArray[i]) {
                    fnArray[i] = 0;
                }
            }
            var fn = fnArray.join("");
            answer = new Function("return " + fn)();
            if (answer) {
                answer = answer.toFixed(numberOfDecimalsOfOutput);
            }
            return answer;
        }
    };
    /**
     * Navigate to the project dashboard page for a given project
     * @param projectNameVal
     * @param projectId
     */
    DynamicQuestionnaireComponent.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    /**
     * Set the farmer's quick notes attribute (from API / indexed DB)
     */
    DynamicQuestionnaireComponent.prototype.setFarmerQuickNotes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rawFarmerIdQuickNotes, x, whichTab, quickNotesFromDB, quickNotesFromIndexedDB, farmertmpobj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        x = this.rawFarmerId.substring(0, 2);
                        if (x == 'FA') {
                            whichTab = this.rawFarmerId.substring(this.rawFarmerId.length - 2);
                            if (whichTab == '00') {
                                rawFarmerIdQuickNotes = this.rawFarmerId.slice(0, -5) + "QN";
                            }
                            else {
                                rawFarmerIdQuickNotes = this.rawFarmerId.slice(0, -4) + "QN";
                            }
                        }
                        else {
                            // // farmer was registered online
                            // const p = 10 - this.rawFarmerId.length;
                            // let m = '';
                            // for (let i = 0; i < p; i++) {
                            //   m = `${m}0`;
                            // }
                            rawFarmerIdQuickNotes = this.rawFarmerId + "_QN";
                            // console.log('rawFarmerIdQuickNotes', rawFarmerIdQuickNotes);
                        }
                        quickNotesFromDB = this.farmer.FarmerQuickNotes;
                        if (!!navigator.onLine) return [3 /*break*/, 2];
                        return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                    case 1:
                        farmertmpobj = _a.sent();
                        quickNotesFromIndexedDB = farmertmpobj.filter(function (x) { return x.tmp_id === rawFarmerIdQuickNotes; });
                        _a.label = 2;
                    case 2:
                        if (!this.farmer.FarmerQuickNotes) {
                            this.farmer.FarmerQuickNotes = [];
                        }
                        if (quickNotesFromDB) {
                            this.farmer.FarmerQuickNotes = quickNotesFromDB;
                        }
                        if (quickNotesFromIndexedDB && quickNotesFromIndexedDB.length > 0) {
                            this.farmer.FarmerQuickNotes = quickNotesFromIndexedDB;
                        }
                        if (this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
                            this.notes = this.farmer.FarmerQuickNotes[0];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * partial-sync event triggers this function call. It indicates that the offline-entered data has been synced with the DB
     * and that the local state should be refreshed by fetching the latest DB data and caching it
     * @param args
     */
    DynamicQuestionnaireComponent.prototype.syncDone = function (args) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 6];
                        window.dispatchEvent(new Event('caching-now'));
                        if (!(this.farmerId != 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise()])];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 2: return [4 /*yield*/, Promise.all([
                            // this.dynamicQuestionnaireService.getQAnswersForProject(this.projectId, null).toPromise(),
                            this.dynamicQuestionnaireService.getAnswerStatsForProject(this.projectId).toPromise()
                        ])];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise()
                            ])
                                .then(function (result) {
                                if (result[0]['code'] === "success") {
                                    for (var i = 0; i < result[0]['message'].length; i++) {
                                        _this.loadFarmerDataIntoCache(_this.projectId, result[0]['message'][i], result[0]['message'][i + 1]);
                                    }
                                }
                            }).catch(function (e) { })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        window.dispatchEvent(new Event('data-synced'));
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    //Missing functions added by Kiran
    DynamicQuestionnaireComponent.prototype.leftScroll = function () {
        document.querySelector('ul.sub-tab-fixed').scrollLeft -= 500;
    };
    DynamicQuestionnaireComponent.prototype.rightScroll = function () {
        document.querySelector('ul.sub-tab-fixed').scrollLeft += 500;
    };
    /**
     * Collect all the display_if_key values. This is used to unset dependent questions if the parent question value is removed
     */
    DynamicQuestionnaireComponent.prototype.collectDisplayLogicKeyValues = function () {
        this.displayLogicKeyValues = [];
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var i = 0; i < this.questionnaire.length; i++) {
                if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
                    for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                        for (var k = 0; k < this.questionnaire[i].sections[j].questions.length; k++) {
                            if (this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic.length > 0) {
                                for (var m = 0; m < this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic.length; m++) {
                                    var x = {
                                        asset: this.questionnaire[i].sections[j].questions[k], key: this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_key.toString()
                                    };
                                    if (this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_value != null) {
                                        x['value'] = this.questionnaire[i].sections[j].questions[k].survey_q_asset_display_logic[m].display_if_value.toString();
                                    }
                                    this.displayLogicKeyValues.push(x);
                                }
                            }
                        }
                        for (var p = 0; p < this.questionnaire[i].sections[j].subsections.length; p++) {
                            if (this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic && this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic.length > 0) {
                                for (var m = 0; m < this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic.length; m++) {
                                    var x = {
                                        asset: this.questionnaire[i].sections[j].subsections[p], key: this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic[m].display_if_key.toString()
                                    };
                                    if (this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic[m].display_if_value != null) {
                                        x['value'] = this.questionnaire[i].sections[j].subsections[p].survey_q_asset_display_logic[m].display_if_value.toString();
                                    }
                                    this.displayLogicKeyValues.push(x);
                                }
                            }
                            for (var k = 0; k < this.questionnaire[i].sections[j].subsections[p].questions.length; k++) {
                                if (this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic.length > 0) {
                                    for (var m = 0; m < this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic.length; m++) {
                                        var x = {
                                            asset: this.questionnaire[i].sections[j].subsections[p].questions[k], key: this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_key.toString()
                                        };
                                        if (this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_value != null) {
                                            x['value'] = this.questionnaire[i].sections[j].subsections[p].questions[k].survey_q_asset_display_logic[m].display_if_value.toString();
                                        }
                                        this.displayLogicKeyValues.push(x);
                                    }
                                }
                            }
                        }
                        for (var p = 0; p < this.questionnaire[i].sections[j].tables.length; p++) {
                            if (this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic && this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic.length > 0) {
                                for (var m = 0; m < this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic.length; m++) {
                                    var x = {
                                        asset: this.questionnaire[i].sections[j].tables[p], key: this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic[m].display_if_key.toString()
                                    };
                                    if (this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic[m].display_if_value != null) {
                                        x['value'] = this.questionnaire[i].sections[j].tables[p].survey_q_asset_display_logic[m].display_if_value.toString();
                                    }
                                    this.displayLogicKeyValues.push(x);
                                }
                            }
                            for (var k = 0; k < this.questionnaire[i].sections[j].tables[p].questions.length; k++) {
                                if (this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic && this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic.length > 0) {
                                    for (var m = 0; m < this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic.length; m++) {
                                        var x = {
                                            asset: this.questionnaire[i].sections[j].tables[p].questions[k], key: this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_key.toString()
                                        };
                                        if (this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_value != null) {
                                            x['value'] = this.questionnaire[i].sections[j].tables[p].questions[k].survey_q_asset_display_logic[m].display_if_value.toString();
                                        }
                                        this.displayLogicKeyValues.push(x);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    /**
     * If the parent question's value is unset / modified, then unset the dependent child question's value automatically
     * @param question - parent question whose value has been modified
     * @param secondaryAssetKey - optional
     */
    DynamicQuestionnaireComponent.prototype.unsetDependentQuestions = function (question, secondaryAssetKey) {
        // Find if this question is a display logic key for another asset
        var assetsWithThisDisplayIfKey = this.displayLogicKeyValues.filter(function (item) { return item.key == question.id; });
        if (assetsWithThisDisplayIfKey && assetsWithThisDisplayIfKey.length > 0) {
            for (var i = 0; i < assetsWithThisDisplayIfKey.length; i++) {
                if (!this.checkAssetDisplay(assetsWithThisDisplayIfKey[i].asset, secondaryAssetKey)) {
                    if (assetsWithThisDisplayIfKey[i].asset.qa_type == 'question') {
                        this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset, secondaryAssetKey);
                        if (assetsWithThisDisplayIfKey[i].asset.uom_questions && assetsWithThisDisplayIfKey[i].asset.uom_questions.length > 0) {
                            for (var k = 0; k < assetsWithThisDisplayIfKey[i].asset.uom_questions.length; k++) {
                                this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.uom_questions[k], secondaryAssetKey);
                            }
                        }
                        if (assetsWithThisDisplayIfKey[i].asset.other_questions && assetsWithThisDisplayIfKey[i].asset.other_questions.length > 0) {
                            for (var k = 0; k < assetsWithThisDisplayIfKey[i].asset.other_questions.length; k++) {
                                this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.other_questions[k], secondaryAssetKey);
                            }
                        }
                    }
                    if (assetsWithThisDisplayIfKey[i].asset.qa_type == 'subsection' || assetsWithThisDisplayIfKey[i].asset.qa_type == 'table') {
                        if (assetsWithThisDisplayIfKey[i].asset.questions && assetsWithThisDisplayIfKey[i].asset.questions.length > 0) {
                            for (var j = 0; j < assetsWithThisDisplayIfKey[i].asset.questions.length; j++) {
                                this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.questions[j], secondaryAssetKey, secondaryAssetKey ? false : true);
                                if (assetsWithThisDisplayIfKey[i].asset.questions[j].uom_questions && assetsWithThisDisplayIfKey[i].asset.questions[j].uom_questions.length > 0) {
                                    for (var k = 0; k < assetsWithThisDisplayIfKey[i].asset.questions[j].uom_questions.length; k++) {
                                        this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.questions[j].uom_questions[k], secondaryAssetKey, secondaryAssetKey ? false : true);
                                    }
                                }
                                if (assetsWithThisDisplayIfKey[i].asset.questions[j].other_questions && assetsWithThisDisplayIfKey[i].asset.questions[j].other_questions.length > 0) {
                                    for (var k = 0; k < assetsWithThisDisplayIfKey[i].asset.questions[j].other_questions.length; k++) {
                                        this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.questions[j].other_questions[k], secondaryAssetKey, secondaryAssetKey ? false : true);
                                    }
                                }
                            }
                        }
                        if (assetsWithThisDisplayIfKey[i].asset.grand_total_questions && assetsWithThisDisplayIfKey[i].asset.grand_total_questions.length > 0) {
                            for (var j = 0; j < assetsWithThisDisplayIfKey[i].asset.grand_total_questions.length; j++) {
                                this.unsetQuestionValue(assetsWithThisDisplayIfKey[i].asset.grand_total_questions[j], secondaryAssetKey, secondaryAssetKey ? false : true);
                            }
                        }
                    }
                }
                else {
                    // console.log('2350');
                }
            }
        }
    };
    /**
     *
     * @param question - question whose value to be unset
     * @param secondaryAssetKey
     * @param unsetAllInTable
     */
    DynamicQuestionnaireComponent.prototype.unsetQuestionValue = function (question, secondaryAssetKey, unsetAllInTable) {
        var answersExistsInQAnswers;
        if (!unsetAllInTable) {
            answersExistsInQAnswers = this.qAnswers.filter(function (item) { return item.key == question.id && item.secondary_asset_key == secondaryAssetKey; });
        }
        else {
            answersExistsInQAnswers = this.qAnswers.filter(function (item) { return item.key == question.id; });
        }
        if (answersExistsInQAnswers && answersExistsInQAnswers.length > 0) {
            if (question.qa_subtype === 'multiselect') {
                var _loop_34 = function (j) {
                    var args = { target: { checked: false } };
                    if (!answersExistsInQAnswers[j].secondary_asset_key) {
                        this_21.sectionFormChangeHandlerCheckbox(args, { id: answersExistsInQAnswers[j].value }, question);
                        if (this_21.checkedObject[question.id + '_' + answersExistsInQAnswers[j].value]) {
                            delete this_21.checkedObject[question.id + '_' + answersExistsInQAnswers[j].value];
                        }
                    }
                    else {
                        if (question.has_dynamic_options) {
                            var o1 = this_21.dynamicOptionsObject[question.id];
                            if (o1) {
                                var o2_1 = answersExistsInQAnswers[j].value.split('__');
                                var o3 = o1.find(function (item) { return item.q == o2_1[0] && item.sak == o2_1[1]; });
                                if (o3) {
                                    this_21.sectionFormChangeHandlerForTableCheckboxDynamicOptions(args, this_21.getParentAsset(question), question, o3, answersExistsInQAnswers[j].secondary_asset_key, true);
                                }
                            }
                        }
                        else {
                            this_21.sectionFormChangeHandlerForTableCheckbox(args, this_21.getParentAsset(question), question, { id: answersExistsInQAnswers[j].value }, answersExistsInQAnswers[j].secondary_asset_key);
                        }
                        if (this_21.checkedObjectSecondaryKeys[question.id + '_' + answersExistsInQAnswers[j].secondary_asset_key + '_' + answersExistsInQAnswers[j]['value']]) {
                            delete this_21.checkedObjectSecondaryKeys[question.id + '_' + answersExistsInQAnswers[j].secondary_asset_key + '_' + answersExistsInQAnswers[j]['value']];
                        }
                    }
                };
                var this_21 = this;
                for (var j = 0; j < answersExistsInQAnswers.length; j++) {
                    _loop_34(j);
                }
            }
            else {
                for (var j = 0; j < answersExistsInQAnswers.length; j++) {
                    if (question.qa_type === 'uom_question') {
                        // this.setUOMValuesInForm(question.id, question.options, secondaryAssetKey);
                        this.patchSectionForm(question.id, answersExistsInQAnswers[j].secondary_asset_key, null);
                    }
                    else if (question.qa_subtype === 'select') {
                        this.patchSectionForm(question.id, answersExistsInQAnswers[j].secondary_asset_key, "");
                    }
                    else {
                        this.patchSectionForm(question.id, answersExistsInQAnswers[j].secondary_asset_key, null);
                    }
                    if (!answersExistsInQAnswers[j].secondary_asset_key) {
                        this.sectionFormChangeHandler(null, question);
                    }
                    else {
                        this.sectionFormChangeHandlerForTable(null, this.getParentAsset(question), question, answersExistsInQAnswers[j].secondary_asset_key);
                    }
                }
            }
        }
    };
    /**
     * Patch the value in the specified formcontrol of the sectionform
     * @param key
     * @param secondaryAssetKey
     * @param value
     */
    DynamicQuestionnaireComponent.prototype.patchSectionForm = function (key, secondaryAssetKey, value) {
        if (secondaryAssetKey) {
            var x = {};
            x[key + '_' + secondaryAssetKey] = value == null ? '' : value;
            this.sectionForm.patchValue(x);
        }
        else {
            var x = {};
            x[key] = value;
            this.sectionForm.patchValue(x);
        }
    };
    /**
     * Given an assetId, find the asset in the questionnaire nested JSON and return it
     * @param assetId
     */
    DynamicQuestionnaireComponent.prototype.getAssetNew = function (assetId) {
        var atp = this.assetTypes.map(function (item) { return item.type + 's'; }); // asset types plural
        // console.log('atp', atp);
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var o = 0; o < this.questionnaire.length; o++) {
                for (var i = 0; i < atp.length; i++) {
                    // console.log('i', atp[i]);
                    if (this.questionnaire[o][atp[i]] && this.questionnaire[o][atp[i]].length > 0) {
                        for (var j = 0; j < this.questionnaire[o][atp[i]].length; j++) {
                            if (assetId == this.questionnaire[o][atp[i]][j].id) {
                                return this.questionnaire[o][atp[i]][j];
                            }
                            for (var k = 0; k < atp.length; k++) {
                                // console.log('k', atp[k]);
                                if (this.questionnaire[o][atp[i]][j][atp[k]] && this.questionnaire[o][atp[i]][j][atp[k]].length > 0) {
                                    for (var l = 0; l < this.questionnaire[o][atp[i]][j][atp[k]].length; l++) {
                                        // console.log('this.questionnaire[o][atp[i]][j][atp[k]][l].id', this.questionnaire[o][atp[i]][j][atp[k]][l].id);
                                        if (assetId == this.questionnaire[o][atp[i]][j][atp[k]][l].id) {
                                            return this.questionnaire[o][atp[i]][j][atp[k]][l];
                                        }
                                        for (var m = 0; m < atp.length; m++) {
                                            // console.log('m', atp[m]);
                                            if (this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]] && this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]].length > 0) {
                                                for (var n = 0; n < this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]].length; n++) {
                                                    if (assetId == this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]][n].id) {
                                                        return this.questionnaire[o][atp[i]][j][atp[k]][l][atp[m]][n];
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    /**
     * If an asset in the formula array has a child uom_question, convert the asset's answer value to the project default unit
     * and return the converted value
     * the conversionFactorToProjectDefault value is used here
     * If an asset has TWO child uom questions, the 1st UOM conversion factor is multiplied, the 2nd one is used to divide.
     * An example of the above is 10 Acre * 20 KG / Acre
     * @param qaToModify
     * @param formulaObjArray
     * @param formulaArray
     * @param secondary_asset_key
     */
    DynamicQuestionnaireComponent.prototype.newAdjustFormulaArray = function (qaToModify, formulaObjArray, formulaArray, secondary_asset_key) {
        for (var i = 0; i < formulaObjArray.length; i++) {
            if (!formulaObjArray[i]) {
                return;
            }
            formulaObjArray[i]['asset'] = this.getAssetNew(formulaObjArray[i].key);
        }
        var _loop_35 = function (i) {
            if (formulaObjArray[i].asset.uom_questions && formulaObjArray[i].asset.uom_questions.length > 0) {
                formulaObjArray[i]['uomValues'] = [];
                var _loop_39 = function (j) {
                    var x = void 0;
                    var value = void 0;
                    if (secondary_asset_key) {
                        x = this_22.qAnswers.find(function (item) { return item.key == formulaObjArray[i].asset.uom_questions[j].id && item.secondary_asset_key == secondary_asset_key; });
                    }
                    else {
                        x = this_22.qAnswers.find(function (item) { return item.key == formulaObjArray[i].asset.uom_questions[j].id; });
                    }
                    if (x) {
                        value = x['value'];
                    }
                    formulaObjArray[i]['uomValues'].push({
                        qa_subtype: formulaObjArray[i].asset.uom_questions[j].qa_subtype,
                        value: value
                    });
                };
                for (var j = 0; j < formulaObjArray[i].asset.uom_questions.length; j++) {
                    _loop_39(j);
                }
            }
        };
        var this_22 = this;
        // Get the unit-adjusted values of formula components
        for (var i = 0; i < formulaObjArray.length; i++) {
            _loop_35(i);
        }
        if (qaToModify.uom_questions && qaToModify.uom_questions.length > 0) {
            qaToModify['uomValues'] = [];
            var _loop_36 = function (j) {
                var x = void 0;
                var value = void 0;
                if (secondary_asset_key) {
                    x = this_23.qAnswers.find(function (item) { return item.key == qaToModify.uom_questions[j].id && item.secondary_asset_key == secondary_asset_key; });
                }
                else {
                    x = this_23.qAnswers.find(function (item) { return item.key == qaToModify.uom_questions[j].id; });
                }
                if (x) {
                    value = x['value'];
                }
                qaToModify['uomValues'].push({
                    qa_subtype: qaToModify.uom_questions[j].qa_subtype,
                    value: value
                });
            };
            var this_23 = this;
            for (var j = 0; j < qaToModify.uom_questions.length; j++) {
                _loop_36(j);
            }
        }
        var newFormulaArray = [];
        var _loop_37 = function (i) {
            if (!formulaObjArray[i]) {
                return { value: void 0 };
            }
            if (formulaObjArray[i] && formulaObjArray[i].uomValues) {
                var modifiedValue = void 0;
                var _loop_40 = function (j) {
                    if (!formulaObjArray[i].uomValues[j]['value']) {
                        modifiedValue = 0;
                    }
                    var x = this_24.processedUOMs[formulaObjArray[i].uomValues[j]['qa_subtype']]['units'].find(function (item) { return item.id == formulaObjArray[i].uomValues[j].value; });
                    if (x) {
                        // The first UOM is multiplicative. e.g. "Kg" in Kg / Hecture
                        if (j === 0) {
                            modifiedValue = formulaObjArray[i].value * x['conversionFactorToProjectDefault'];
                        }
                        // The second UOM is used in the denominator, e.g. "Hectare" in Kg / Hecture
                        else if (j === 1) {
                            modifiedValue = modifiedValue / x['conversionFactorToProjectDefault'];
                        }
                    }
                    if (modifiedValue != null && modifiedValue != undefined) {
                        var decimals = this_24.getOutputDecimals([formulaObjArray[i].value]);
                        if (decimals != null && decimals != undefined) {
                            modifiedValue = modifiedValue.toFixed(2);
                        }
                    }
                };
                for (var j = 0; j < formulaObjArray[i].uomValues.length; j++) {
                    _loop_40(j);
                }
                newFormulaArray.push({ key: formulaObjArray[i].key, value: modifiedValue });
            }
            else {
                newFormulaArray.push({ key: formulaObjArray[i].key, value: formulaObjArray[i].value });
            }
        };
        var this_24 = this;
        for (var i = 0; i < formulaObjArray.length; i++) {
            var state_5 = _loop_37(i);
            if (typeof state_5 === "object")
                return state_5.value;
        }
        // As the unit of qaToModify, set the project's default value
        var qaToModifyUOM;
        if (qaToModify['uomValues'] && qaToModify['uomValues'].length > 0) {
            qaToModifyUOM = this.processedUOMs[qaToModify['uomValues'][0].qa_subtype].projectDefaultUnit.id;
        }
        var formulaArrayCopy = JSON.parse(JSON.stringify(formulaArray));
        var _loop_38 = function (i) {
            if (!isNaN(parseInt(formulaArrayCopy[i]))) {
                formulaArray[i] = newFormulaArray.find(function (item) { return item.key == formulaArrayCopy[i]; }).value;
            }
        };
        for (var i = 0; i < formulaArray.length; i++) {
            _loop_38(i);
        }
        return { formula: formulaArray, unit: qaToModifyUOM };
    };
    /**
     * Convert the project's UOM array into a usable map, where the key is unit_type.id and value is an object which has
     * ... the units corresponding to that unit_type, along wiht the conversionFactorToProjectDefault
     * conversionFactorToProjectDefault converts each unit's international_value conversion factor (which is relative to international_unit such as hectare)...
     * ... into a conversion factor which is relative to the project's default unit (which may be different than the international unit)
     * This is done because autocalculate formulas' answer value is calculated in the project's default unit, if a unit for the calculated answer is not defined
     */
    DynamicQuestionnaireComponent.prototype.preProcessProjectUOMs = function () {
        var _this = this;
        this.processedUOMs = {};
        if (this.project && this.project.ProjectUOM && this.project.ProjectUOM.length > 0) {
            var _loop_41 = function (i) {
                if (!this_25.processedUOMs[this_25.project.ProjectUOM[i].unit_type_id]) {
                    this_25.processedUOMs[this_25.project.ProjectUOM[i].unit_type_id] = {};
                    this_25.processedUOMs[this_25.project.ProjectUOM[i].unit_type_id]['units'] = [];
                }
                var projectDefaultUnit = this_25.project.ProjectUOM.find(function (item) { return item.is_default && item.unit_type_id === _this.project.ProjectUOM[i].unit_type_id; });
                this_25.processedUOMs[this_25.project.ProjectUOM[i].unit_type_id]['projectDefaultUnit'] = projectDefaultUnit;
                this_25.processedUOMs[this_25.project.ProjectUOM[i].unit_type_id]['units'].push({
                    id: this_25.project.ProjectUOM[i].id,
                    international_unit: this_25.project.ProjectUOM[i].international_unit,
                    international_value: this_25.project.ProjectUOM[i].international_value,
                    uom: this_25.project.ProjectUOM[i].uom,
                    conversionFactorToProjectDefault: this_25.project.ProjectUOM[i].international_value / projectDefaultUnit.international_value
                });
            };
            var this_25 = this;
            for (var i = 0; i < this.project.ProjectUOM.length; i++) {
                _loop_41(i);
            }
        }
    };
    /**
     * Sort the default crop (the ecosystem crop) to the top of the agri income table
     * @param tableId - Number
     */
    DynamicQuestionnaireComponent.prototype.sortDefaultCropToTop = function (tableId) {
        if (this.secondary_asset_keys && this.secondary_asset_keys[tableId] && this.secondary_asset_keys[tableId].length > 0) {
            var x = this.secondary_asset_keys[tableId].indexOf(this.defaultCropSak);
            if (x) {
                if (x === 0) {
                    // do nothing
                    return;
                }
                else {
                    var temp = JSON.parse(JSON.stringify(this.secondary_asset_keys[tableId]));
                    var p = temp[0];
                    temp[0] = this.defaultCropSak;
                    temp[x] = p;
                    this.secondary_asset_keys[tableId] = JSON.parse(JSON.stringify(temp));
                }
            }
        }
    };
    /**
     * Given the array of factors to be inserted into the formula, find the number of decimal places of the output
     * It is the max number of decimals of any of the input values
     * @param {*} fnArray
     */
    DynamicQuestionnaireComponent.prototype.getOutputDecimals = function (fnArray) {
        var _this = this;
        var decimals = 0;
        var decimalPositionArray = [];
        fnArray.forEach(function (item) {
            if (item) {
                var indexOfDecimal = item.toString().indexOf('.');
                if (_this.validIndex(indexOfDecimal)) {
                    decimalPositionArray.push(item.toString().length - indexOfDecimal);
                }
            }
        });
        if (decimalPositionArray.length > 0) {
            decimals = Math.max.apply(Math, decimalPositionArray) - 1;
        }
        return decimals > 0 ? decimals : 0;
    };
    /**
     * Navigate to the farmer management component
     */
    DynamicQuestionnaireComponent.prototype.goToFarmerManagementEdit = function () {
        if (!this.campaignId) {
            this.router.navigate(['farmerdetails/' + this.projectId + '/' + this.rawFarmerId]);
        }
        else {
            this.router.navigate(['farmerdetails/' + this.projectId + '/' + this.rawFarmerId], { queryParams: { campaignId: this.campaignId } });
        }
    };
    /**
     * Check whether the current user has access to the farmer management service or not. The button to navigate to farmer management
     * component is displayed only if the user has access
     */
    DynamicQuestionnaireComponent.prototype.canDoFarmerMgmtFn = function () {
        var _this = this;
        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
        }
        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
        }
        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
            var canDoFarmerMgmt = this.usersServiceComponentMappings.filter(function (item) { return (item.services_id == 2 && item.user_type_id == _this.currentUser.role.id); });
            if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
                this.canDoFarmerMgmt = true;
            }
        }
        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
            var canDoFarmerMgmt = this.ecosystemServicesComponentsMapping.filter(function (item) { return item.services_id == 2; });
            if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
                this.canDoFarmerMgmt = true;
            }
        }
    };
    /**
     * Display a message in place of the questionnaire saying that there are no active snapshots in the project
     */
    DynamicQuestionnaireComponent.prototype.showNoActiveSnapshotMessage = function () {
        var _this = this;
        this.canDoFarmerMgmtFn();
        setTimeout(function () { return _this.toastr.info(_this.thereNoActiveSnapshotForThisProject); }, 0);
    };
    /**
     * Check whether the profitability summary needs to be displayed in the given tab
     */
    DynamicQuestionnaireComponent.prototype.showSummaryForCurrentTabFn = function () {
        if (this.currentTab && this.currentTab.displayFunction && this.currentTab.displayFunction.indexOf('showSummary') > -1) {
            this.showSummaryForCurrentTab = true;
        }
        else {
            this.showSummaryForCurrentTab = false;
        }
    };
    /**
     * Run the calculations used to display the profitability summary figures
     */
    DynamicQuestionnaireComponent.prototype.runTabSummaryCalculate = function () {
        var _this = this;
        if (this.showSummaryForCurrentTab) {
            this.createProfitabilitySummaryObject();
            if (this.currentSection && this.farmProfitabilitySummaryArray && this.farmProfitabilitySummaryArray.length > 0) {
                var x = this.farmProfitabilitySummaryArray.find(function (item) { return item.sectionId == _this.currentSection.id; });
                var indexOfCurrentSection = this.farmProfitabilitySummaryArray.indexOf(x);
                var sections = JSON.parse(JSON.stringify(this.farmProfitabilitySummaryArray));
                if (indexOfCurrentSection > 0) {
                    sections.splice(indexOfCurrentSection);
                    sections.reverse();
                }
                // Logic used to display the left and right side of the profitability summary tab
                for (var i = 0; i < this.farmProfitabilitySummaryArray.length; i++) {
                    if (this.currentSection.id == this.farmProfitabilitySummaryArray[i].sectionId) {
                        if (this.currentSection.isIncome) {
                            this.farmProfitabilitySummary.income = {
                                source: this.farmProfitabilitySummaryArray[i].label,
                                amount: this.farmProfitabilitySummaryArray[i].amount
                            };
                            var expenseSection = sections.find(function (item) { return item.isExpense; });
                            if (expenseSection) {
                                this.farmProfitabilitySummary.expense = {
                                    source: expenseSection.label,
                                    amount: expenseSection.amount
                                };
                            }
                        }
                        else if (this.currentSection.isExpense) {
                            this.farmProfitabilitySummary.expense = {
                                source: this.farmProfitabilitySummaryArray[i].label,
                                amount: this.farmProfitabilitySummaryArray[i].amount
                            };
                            var incomeSection = sections.find(function (item) { return item.isIncome; });
                            if (incomeSection) {
                                this.farmProfitabilitySummary.income = {
                                    source: incomeSection.label,
                                    amount: incomeSection.amount
                                };
                            }
                        }
                    }
                }
            }
            // console.log('this.farmProfitabilitySummary', this.farmProfitabilitySummary);
        }
    };
    /**
     * Create the profitability summary array that will be used to display the summary on top
     */
    DynamicQuestionnaireComponent.prototype.createProfitabilitySummaryObject = function () {
        this.farmProfitabilitySummaryArray = [];
        var sections = this.currentTab.sections;
        var totalIncome = 0;
        var totalExpense = 0;
        if (sections && sections.length > 0) {
            for (var i = 0; i < sections.length; i++) {
                if (sections[i].isIncome || sections[i].isExpense) {
                    var amount = this.getSummaryAmount(sections[i]);
                    var amountToSet = amount ? amount : 0;
                    // console.log(sections[i]['survey_q_asset_labels'][0].label, amount, amountToSet);
                    var obj = {
                        index: i,
                        sectionId: sections[i].id,
                        label: sections[i]['survey_q_asset_labels'][0].label,
                        amount: amountToSet,
                        isIncome: sections[i]['isIncome'],
                        isExpense: sections[i]['isExpense']
                    };
                    this.farmProfitabilitySummaryArray.push(obj);
                    if (obj.isIncome) {
                        totalIncome = totalIncome + parseFloat(amountToSet);
                    }
                    if (obj.isExpense) {
                        totalExpense = totalExpense + parseFloat(amountToSet);
                    }
                }
                this.farmProfitabilitySummary.totalIncome = totalIncome.toString();
                this.farmProfitabilitySummary.totalExpense = totalExpense.toString();
                this.farmProfitabilitySummary.totalProfit = (totalIncome - totalExpense).toString();
            }
        }
    };
    /**
     * Reset the profitability summary array
     */
    DynamicQuestionnaireComponent.prototype.resetFarmProfitabilitySummary = function () {
        this.showSummaryForCurrentTab = false;
        this.farmProfitabilitySummary = { income: { source: 'NA', amount: 'NA' }, expense: { source: 'NA', amount: 'NA' }, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA' };
        this.farmProfitabilitySummaryArray = [];
    };
    /**
     * Given a section, get the summary amount - this is generally the grand total value of the section
     * @param section
     */
    DynamicQuestionnaireComponent.prototype.getSummaryAmount = function (section) {
        var answerToReturn;
        if (section.displayFunction) {
            var x_6 = JSON.parse(section.displayFunction);
            if (x_6) {
                if (x_6['answerQAsset']) {
                    var y = this.qAnswers.find(function (item) { return item.key == x_6['answerQAsset'] && !item.deleted; });
                    if (y) {
                        answerToReturn = y.value;
                    }
                }
                // This is required for the workers expenditure section
                // Grand total of the daily workers expenses must be added to 12 * number of workers * avg monthly wate
                if (x_6['multiplyFollowing']) {
                    if (x_6['key1'] && x_6['key2']) {
                        var y1 = this.qAnswers.find(function (item) { return item.key == x_6['key1'] && !item.deleted; });
                        var y2 = this.qAnswers.find(function (item) { return item.key == x_6['key2'] && !item.deleted; });
                        if (y1 && y2) {
                            var t = x_6['multiplyFollowing'] * y1.value * y2.value;
                            answerToReturn = answerToReturn ? parseInt(answerToReturn) + t : t;
                        }
                    }
                }
            }
        }
        return answerToReturn;
    };
    /**
     * Set special attributes to the questionnaire sections
     */
    DynamicQuestionnaireComponent.prototype.setQuestionnaireAttributes = function () {
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var i = 0; i < this.questionnaire.length; i++) {
                if (this.questionnaire[i].sections && this.questionnaire[i].sections.length > 0) {
                    for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                        if (this.questionnaire[i].sections[j].displayFunction) {
                            var x = JSON.parse(this.questionnaire[i].sections[j].displayFunction);
                            if (x.type && x.type === 'income') {
                                this.questionnaire[i].sections[j]['isIncome'] = true;
                            }
                            else if (x.type && x.type === 'expense') {
                                this.questionnaire[i].sections[j]['isExpense'] = true;
                            }
                        }
                    }
                }
            }
        }
    };
    /**
     * Set the currencycode value
     */
    DynamicQuestionnaireComponent.prototype.setCurrencyCode = function () {
        if (this.project.Currency && this.project.Currency.currency_code) {
            this.currencyCode = this.project.Currency.currency_code;
        }
        else {
            this.currencyCode = "";
        }
    };
    // notADuplicateValue(question, value) {
    // if (question.displayFunction) {
    //   const displayFunctionJson = JSON.parse(question.displayFunction);
    //   if (displayFunctionJson && displayFunctionJson['type'] && displayFunctionJson['type'] === 'uniqueAnswers') {
    //     let answerValues = this.qAnswers.filter(item => item.key == question.id && !item.deleted);
    //     answerValues.push({key: question.id, value: value});
    //     if (answerValues && answerValues.length > 0) {
    //       if (this.hasObjectWithDuplicates(answerValues, 'value')) {
    //         return false;
    //       } else {
    //         return true;
    //       }
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     return true;
    //   }
    // } else {
    //   return true;
    // }
    // }
    // Checks if array has an object with duplicate values of attributeToCheck
    // hasObjectWithDuplicates(array, attributeToCheck) {
    //   let valueArr = [];
    //   array.forEach(element => {
    //     valueArr.push(element[attributeToCheck]);
    //   });
    //   let isDuplicate = valueArr.some(function(item, idx){ 
    //     return valueArr.indexOf(item) != idx 
    //   });
    //   return isDuplicate;
    // }
    /**
     * This function sets the uom values in the sectionForm so that they appear in the UOM dropdown by default
    */
    DynamicQuestionnaireComponent.prototype.setUOMValuesInForm = function (uomQId, options, secondary_asset_key) {
        var defaultOption = options.find(function (item) { return item.is_default; });
        if (defaultOption) {
            if (this.sectionForm) {
                var x = {};
                if (secondary_asset_key) {
                    if (this.sectionForm['value'][uomQId + '_' + secondary_asset_key] === '') {
                        x[uomQId + '_' + secondary_asset_key] = defaultOption.id;
                    }
                }
                else {
                    if (this.sectionForm['value'][uomQId] === '') {
                        x[uomQId] = defaultOption.id;
                    }
                }
                this.sectionForm.patchValue(x);
            }
        }
    };
    /**
     * This function adds the valeus of the child UOM question to the qAnswers Array, it is called when the parent question is modified
     * @param question
     * @param secondary_asset_key
     * @param table
     */
    DynamicQuestionnaireComponent.prototype.setUOMValuesInQAnswers = function (question, secondary_asset_key, table, na) {
        if (secondary_asset_key) {
            var x = this.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key === secondary_asset_key; });
            if (x.value && x.touched) {
                for (var _i = 0, _a = question.uom_questions; _i < _a.length; _i++) {
                    var uomQ = _a[_i];
                    this.sectionFormChangeHandlerForTable(null, table, uomQ, secondary_asset_key);
                }
            }
        }
        else {
            var x = this.qAnswers.find(function (item) { return item.key == question.id; });
            if ((x.value || (typeof na !== "undefined" && na == false)) && x.touched) {
                for (var _b = 0, _c = question.uom_questions; _b < _c.length; _b++) {
                    var uomQ = _c[_b];
                    this.sectionFormChangeHandler(null, uomQ);
                }
            }
        }
    };
    /**
     * Update the state of the checkedObject, which records which multiselect (checkbox) assets have been checked
     * @param args
     * @param optionId
     * @param questionId
     * @param secondaryAssetKey
     */
    DynamicQuestionnaireComponent.prototype.updateCheckedObject = function (args, optionId, questionId, secondaryAssetKey) {
        if (secondaryAssetKey) {
            if (this.checkedObjectSecondaryKeys) {
                this.checkedObjectSecondaryKeys[questionId + '_' + secondaryAssetKey + '_' + optionId] = args.target.checked;
            }
        }
        else {
            if (this.checkedObject) {
                this.checkedObject[questionId + '_' + optionId] = args.target.checked;
            }
        }
    };
    /**
     * Find which limits range the given farmerId falls in
     * E.g. if limits = [130, 150, 170] and farmerId = 165
     * limitsToReturn = [150, 170] would be returned
     * @param limits
     * @param farmerId
     */
    DynamicQuestionnaireComponent.prototype.getPaginationLimits = function (limits, farmerId) {
        // console.log('limits', limits);
        // console.log('farmerId', farmerId);
        var limitsToReturn = [];
        for (var i = 0; i < limits.length; i++) {
            if (limits[i] <= farmerId && limits[i + 1] && limits[i + 1] > farmerId) {
                limitsToReturn[0] = limits[i];
                limitsToReturn[1] = limits[i + 1];
                return limitsToReturn;
            }
            else if (!limits[i + 1]) {
                limitsToReturn[0] = limits[i];
                limitsToReturn[1] = undefined;
                return limitsToReturn;
            }
        }
    };
    /**
     * Load the profiling data into cache (after message received from API that the data has been synced)
     * @param projectId
     * @param gte
     * @param lt
     */
    DynamicQuestionnaireComponent.prototype.loadFarmerDataIntoCache = function (projectId, gte, lt) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(projectId && projectId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForProjectPaginated(projectId, gte, lt).toPromise()
                            ])
                                .then(function (result) {
                                console.log('result', result);
                            }).catch(function (e) { })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    DynamicQuestionnaireComponent.prototype.campaignQuestionnaireFlow = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
                                this.dynamicQuestionnaireService.getQAnswersForFarmerForCampaign(this.campaignId, this.farmerId).toPromise(),
                                this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise(),
                                this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                            ])];
                    case 1:
                        r = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, Promise.all([
                            this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                            this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                            this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                            this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
                            this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise(),
                            this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                            this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                        ])];
                    case 3:
                        r = _a.sent();
                        _a.label = 4;
                    case 4:
                        console.log('r', r);
                        return [2 /*return*/, r];
                }
            });
        });
    };
    DynamicQuestionnaireComponent.prototype.setBreadcrumbText = function () {
        var _this = this;
        this.translate.get('breadcrumbTitle').subscribe(function (result) {
            if (_this.campaignId) {
                _this.breadcrumbTitle = result['campaign'];
            }
            _this.breadcrumbTitle = result['project'];
        });
    };
    DynamicQuestionnaireComponent.prototype.goToProfilingTable = function () {
        if (this.campaignId) {
            this.router.navigate(["/campaign-profiling-farmers/" + this.projectId + "/" + this.campaignId]);
        }
        else {
            this.router.navigate(["/profiling/" + this.projectId]);
        }
    };
    DynamicQuestionnaireComponent.prototype.checkMandatoryQuestions = function () {
        var qCount = 0;
        for (var _i = 0, _a = this.currentSection.questions; _i < _a.length; _i++) {
            var question = _a[_i];
            if (question.isDisplayed) {
                for (var j = 0; j < question.survey_q_asset_validations.length; j++) {
                    if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
                        var answered = this.checkIfAnswered(question);
                        if (!answered) {
                            qCount++;
                        }
                    }
                }
            }
        }
        for (var _b = 0, _c = this.currentSection.subsections; _b < _c.length; _b++) {
            var subsection = _c[_b];
            if (subsection.questions && subsection.questions.length > 0 && subsection.isDisplayed) {
                for (var _d = 0, _e = subsection.questions; _d < _e.length; _d++) {
                    var question = _e[_d];
                    if (question.isDisplayed) {
                        for (var j = 0; j < question.survey_q_asset_validations.length; j++) {
                            if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
                                var answered = this.checkIfAnswered(question);
                                if (!answered) {
                                    qCount++;
                                }
                            }
                        }
                    }
                }
            }
        }
        for (var _f = 0, _g = this.currentSection.tables; _f < _g.length; _f++) {
            var table = _g[_f];
            var findSAKs = this.secondary_asset_keys[table.id];
            if (findSAKs && findSAKs.length > 0) {
                for (var _h = 0, findSAKs_3 = findSAKs; _h < findSAKs_3.length; _h++) {
                    var sak = findSAKs_3[_h];
                    // if(j.questions && j.questions.length > 0 && j.isDisplayed){
                    if (table.questions && table.questions.length > 0 && table.isDisplayed) {
                        for (var _j = 0, _k = table.questions; _j < _k.length; _j++) {
                            var question = _k[_j];
                            var keyIsDisplayed = sak + "_isDisplayed";
                            if (question[keyIsDisplayed]) {
                                for (var j = 0; j < question.survey_q_asset_validations.length; j++) {
                                    if (question.survey_q_asset_validations[j].validation_key == 'mandatory') {
                                        var answered = this.checkIfAnswered(question, sak);
                                        if (!answered) {
                                            qCount++;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (qCount > 0) {
            this.mandatoryQuestionAnswered = false;
        }
        else {
            this.mandatoryQuestionAnswered = true;
        }
    };
    DynamicQuestionnaireComponent.prototype.checkIfAnswered = function (question, sak) {
        var qid = question.id;
        var ans;
        var checkBoxQnFlag = false;
        if (sak) {
            ans = this.qAnswers.find(function (item) { return item.key == qid && item.secondary_asset_key == sak; });
        }
        else if (question.qa_subtype === "multiselect") {
            ans = this.qAnswers.filter(function (item) { return item.key == qid; });
            checkBoxQnFlag = true;
        }
        else {
            ans = this.qAnswers.find(function (item) { return item.key == qid; });
        }
        if (checkBoxQnFlag === true) {
            var qnAnswered_1 = false;
            ans.map(function (item) {
                if (!item.unchecked && item.flex3 === "A") {
                    qnAnswered_1 = true;
                }
            });
            if (qnAnswered_1) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (ans && ans.value != '' && ans.value != null && ans.flex3 === "A") {
            return true;
        }
        else {
            return false;
        }
    };
    // BACKUP METHODS - MAY BE REQUIRED
    // 16/7
    // sectionFormChangeHandler(args): void {
    //   if (!this.qAnswers) {this.qAnswers = [];}
    //   Object.keys(this.sectionForm.value).forEach(function (k) {
    //     if (this.sectionForm.value[k] && this.sectionForm.value[k] != '') {
    //       let x = this.qAnswers.find(item => item.key == k);
    //       if (x) {
    //         let index = this.qAnswers.indexOf(x);
    //         this.qAnswers[index]['value'] = this.sectionForm.value[k];
    //       } else {
    //         this.qAnswers.push({key: k, value: this.sectionForm.value[k]});
    //       }
    //     } else if (this.sectionForm.value[k] === '') {
    //       let x = this.qAnswers.find(item => item.key == k);
    //       if (x) {
    //         let index = this.qAnswers.indexOf(x);
    //         this.qAnswers[index]['value'] = this.sectionForm.value[k];
    //       } else {
    //         this.qAnswers.push({key: k, value: this.sectionForm.value[k]});
    //       }
    //     }
    //   }.bind(this));
    //   console.log('this.qAnswers', this.qAnswers);
    // }
    // for (let k = 0; k < this.currentSection.tables.length; k++) {
    //   for (let i = 0; i < this.currentSection.tables[k].questions.length; i++) {
    //     let existsInQAnswersForTableQuestion = this.existsInQAnswersForTableQuestion(this.currentSection.tables[k].questions[i]);
    //     console.log('existsInQAnswersForTableQuestion', this.currentSection.tables[k].questions[i].id, existsInQAnswersForTableQuestion);
    //     if (existsInQAnswersForTableQuestion && existsInQAnswersForTableQuestion.length > 0) {
    //       for (let p = 0; p < existsInQAnswersForTableQuestion.length; p++) {
    //         this.sectionForm.addControl(this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key'], new FormControl(existsInQAnswersForTableQuestion[p]['value']));
    //         if (this.currentSection.tables[k].questions[i].disable_input) {this.sectionForm.controls[this.currentSection.tables[k].questions[i].id + '_' + existsInQAnswersForTableQuestion[p]['secondary_asset_key']].disable();}
    //         if (this.currentSection.tables[k].questions[i].include_uom_question) {this.setUOMQuestion(this.currentSection.tables[k].questions[i], existsInQAnswersForTableQuestion[p]['secondary_asset_key']);}
    //       }
    //       this.secondary_asset_keys[this.currentSection.tables[k].id] = existsInQAnswersForTableQuestion.map(item => item.secondary_asset_key);
    //       let sak_of_modified_option = this.qAnswers.find(item => item.sak_of_modified == this.secondary_asset_keys[this.currentSection.tables[k].id]);
    //       if (sak_of_modified_option) {
    //         this.secondary_asset_key__options[sak_of_modified_option['value']] = this.secondary_asset_keys[this.currentSection.tables[k].id];
    //       }
    //     } else {
    //       console.log('this.secondary_asset_keys[this.currentSection.tables[k].id]', this.secondary_asset_keys[this.currentSection.tables[k].id]);
    //       let sak_of_modified_option = this.qAnswers.find(item => item.sak_of_modified != null && item.sak_of_modified == this.secondary_asset_keys[this.currentSection.tables[k].id]);
    //       console.log('185', sak_of_modified_option, this.secondary_asset_keys[this.currentSection.tables[k].id]);
    //       if (sak_of_modified_option) {
    //         this.secondary_asset_key__options[sak_of_modified_option['value']] = this.secondary_asset_keys[this.currentSection.tables[k].id];
    //       } else {
    //       }
    //       this.sectionForm.addControl(this.currentSection.tables[k].questions[i].id + '_' + this.secondary_asset_keys[this.currentSection.tables[k].id], new FormControl(''));
    //       if (this.currentSection.tables[k].questions[i].disable_input) {this.sectionForm.controls[this.currentSection.tables[k].questions[i].id + '_' + this.secondary_asset_keys[this.currentSection.tables[k].id]].disable();}
    //       if (this.currentSection.tables[k].questions[i].include_uom_question) {this.setUOMQuestion(this.currentSection.tables[k].questions[i], this.secondary_asset_keys[this.currentSection.tables[k].id]);}
    //     }
    //   }
    // }
    DynamicQuestionnaireComponent.prototype.openSurveyManual = function () {
        this.showReferenceTab = true;
        this.docUrl = environment.apiBaseUrl + "/" + this.locale.substr(0, 2) + "/assets/uploads/surveyManual/" + this.activeSnapshot.flex4;
        console.log('docUrl', this.docUrl);
        // const docExtension = docUrl.split(/[#?]/)[0].split('.').pop().trim();
        // this.http
        //   .get(docUrl, {
        //     responseType: 'blob',
        //   })
        //   .subscribe(response => {
        //     const blob = new Blob([response], { type: 'application/octet-stream' });
        //     const downloadUrl = window.URL.createObjectURL(blob);
        //     const link = document.createElement('a');
        //     link.href = downloadUrl;
        //     console.log('downloadUrl', downloadUrl);
        //     link.style.display = 'none';
        //     link.download = `Survey_manual.${docExtension}`;
        //     link.click();
        //     window.URL.revokeObjectURL(downloadUrl);
        //     link.remove();
        //   });
    };
    DynamicQuestionnaireComponent.prototype.openTextarea = function (e) {
        e.target.nextElementSibling.style.display = "block";
        e.target.nextElementSibling.focus();
    };
    DynamicQuestionnaireComponent.prototype.closeTextArea = function (e) {
        e.target.style.display = "none";
        e.target.previousElementSibling.value = e.target.value;
    };
    return DynamicQuestionnaireComponent;
}());
export { DynamicQuestionnaireComponent };
