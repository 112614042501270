import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// Services
import { DynamicQuestionnaireService } from '../../services/dynamic-questionnaire.service';
import { SurveyService } from '../../services/survey.service';
import { ProjectService } from '../../services/project.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { FarmerService } from '../../services/farmer.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { merge, of, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { DataConsentService } from '../../services/data-consent.service';
import { DomSanitizer } from '@angular/platform-browser';
var Vpd2Component = /** @class */ (function () {
    function Vpd2Component(dynamicQuestionnaireService, projectService, commonService, route, toastr, surveyService, userService, farmerService, translate, router, modalService, spinner, dataConsentService, sanitizer) {
        this.dynamicQuestionnaireService = dynamicQuestionnaireService;
        this.projectService = projectService;
        this.commonService = commonService;
        this.route = route;
        this.toastr = toastr;
        this.surveyService = surveyService;
        this.userService = userService;
        this.farmerService = farmerService;
        this.translate = translate;
        this.router = router;
        this.modalService = modalService;
        this.spinner = spinner;
        this.dataConsentService = dataConsentService;
        this.sanitizer = sanitizer;
        this.showSectionForm = false; // Boolean to show / hide the section form
        this.checkedObject = {}; // Object that stores checked = true / false for checkbox questions (non-table)
        this.checkedObjectSecondaryKeys = {}; // Object that stores checked = true / false for checkbox questions (table)
        this.secondary_asset_keys = {}; // Stores the secondary_asset_keys for each table. Format {tableId: [sak1, sak2], table2: [sak3, sak4]}
        this.secondary_asset_key__options = {}; // Stores the secondary_asset_key against the checkbox option that triggered creation of that sak. Format {optionId1: sak1, optionId2: sak2}
        this.notActiveSurvey = 2;
        this.currentUser = this.userService.getCurrentUser();
        this.revisionsInSurvey = [];
        this.selectedRevision = 0;
        this.tableOtherQuestionsIsDisplayed = {};
        this.unsavedAnswerCount = 0;
        this.dynamicOptionsObject = {};
        this.subject = new Subject();
        this.displayLogicKeyValues = [];
        this.assetTypes = [
            { type: 'tab', subTypes: [] },
            { type: 'section', subTypes: [] },
            { type: 'subsection', subTypes: [] },
            { type: 'table', subTypes: [{ key: 'question_rows', label: 'Questions in rows' }, { key: 'question_cols', label: 'Questions in columns' }] },
            { type: 'question', subTypes: [{ key: 'text', label: 'Text' }, { key: 'number', label: 'Number' }, { key: 'select', label: 'Select' }, { key: 'multiselect', label: 'Multiselect' }] },
            { type: 'option', subTypes: [] },
            { type: 'uom_question', subTypes: [{ key: '1', label: 'Length' }, { key: '2', label: 'Volume' }, { key: '3', label: 'Weight' }, { key: '4', label: 'Area' }, { key: '5', label: 'Quantity' }] },
            { type: 'other_question', subTypes: [] },
            { type: 'grand_total_question', subTypes: [] },
        ];
        this.processedUOMs = {};
        this.askUserToConfirmExit = false;
        this.farmProfitabilitySummary = { income: { source: 'NA', amount: 'NA' }, expense: { source: 'NA', amount: 'NA' }, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA', agriIncome: 'NA', otherIncome: 'NA' };
        this.farmProfitabilitySummaryArray = [];
        this.canDoFarmerMgmt = false;
        this.showHideBackButton = true;
        this.showHideNextButton = false;
        this.farmerDetailsTabIndex = 0;
        this.farmerRegQuestions = {};
        this.villageName = '';
        this.isCompare = false;
        this.revisionType = '';
        this.discontinuedFarmer = false;
        this.farmerDiscontinueStatus = false;
    }
    /**
     * Init functions, API calls and many functions to process the API and IndexedDB data (in online & offline mode)
     * is the same as dynamic-questionnaire.component.ts - refer to that component for details
     */
    Vpd2Component.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.route.paramMap.subscribe(function (params) {
                    _this.projectId = params.get('projectId');
                    _this.route.queryParamMap.subscribe(function (queryParams) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var request, request2, farmer_requests, masterData, result, request3, qAnswersForFarmer, paginationLimitsArray, paginationLimits, surveyReq, activeSurveyId, activeSnapshot, snapshotSurveys, getQIds, farmertmpobj, tempFarmer, farmertmpobj, rawFarmerKey_1, tempFarmer, requestVal, isDisabled;
                        var _this = this;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    // Get the 'raw' farmerid - this can be the farmer id (online registration) or a temporary offline id
                                    this.rawFarmerId = params.get('farmerId');
                                    // Get the farmer id (online registration), if it exists, or set this.farmerId to 0
                                    this.farmerId = +params.get('farmerId') || 0;
                                    if (queryParams.get('fromCompare') && queryParams.get('fromCompare') === '1') {
                                        this.isCompare = true;
                                    }
                                    if (queryParams.get('type')) {
                                        this.revisionType = queryParams.get('type');
                                    }
                                    this.isOnline = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
                                    this.spinner.show();
                                    if (!(queryParams.get('campaignId') && +queryParams.get('campaignId') !== 0 && !isNaN(+queryParams.get('campaignId')))) return [3 /*break*/, 2];
                                    this.campaignId = queryParams.get('campaignId');
                                    return [4 /*yield*/, this.campaignQuestionnaireFlow()];
                                case 1:
                                    request = _a.sent();
                                    return [3 /*break*/, 10];
                                case 2:
                                    if (!(this.farmerId != 0)) return [3 /*break*/, 8];
                                    if (!navigator.onLine) return [3 /*break*/, 4];
                                    return [4 /*yield*/, Promise.all([
                                            this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                            this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                            this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                            this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                                            this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
                                            this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise(),
                                            this.surveyService.getAllSurveysForProject(this.projectId).toPromise(),
                                            this.dataConsentService.getFarmerConsentHistory(this.projectId, this.farmerId).toPromise(),
                                            this.farmerService.getDiscontinuedFarmerSurveyMapping(this.projectId).toPromise(),
                                        ])];
                                case 3:
                                    request = _a.sent();
                                    return [3 /*break*/, 7];
                                case 4: return [4 /*yield*/, Promise.all([
                                        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                        this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                        this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                                        this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise(),
                                        this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                                    ])];
                                case 5:
                                    request = _a.sent();
                                    return [4 /*yield*/, Promise.all([
                                            this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
                                        ])];
                                case 6:
                                    request2 = _a.sent();
                                    _a.label = 7;
                                case 7: return [3 /*break*/, 10];
                                case 8: return [4 /*yield*/, Promise.all([
                                        this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                        this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                        this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                        this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise()
                                    ])];
                                case 9:
                                    request = _a.sent();
                                    _a.label = 10;
                                case 10: return [4 /*yield*/, Promise.all([
                                        this.farmerService.getFarmRegQuestions().toPromise(),
                                        this.farmerService.GetFarmerMaster().toPromise()
                                    ])];
                                case 11:
                                    farmer_requests = _a.sent();
                                    this.farmerRegQuestions = farmer_requests[0].data;
                                    masterData = farmer_requests[1].data;
                                    this.masterDataFarmer = masterData.filter(function (t) { return t.item_tab == "farmer_reg"; });
                                    if (!(request[0]['msg'] !== 'success' || request[1]['msg'] !== 'success' || request[2]['msg'] !== 'success')) return [3 /*break*/, 12];
                                    this.commonService.showToast('error', 'generic_error', {});
                                    return [3 /*break*/, 31];
                                case 12:
                                    this.project = tslib_1.__assign({}, request[0].data[0], request[1].data[0], request[2].data[0]);
                                    this.canDoFarmerMgmtFn();
                                    this.setCurrencyCode();
                                    if (request[3]['message'] === 'notActive') {
                                        this.notActiveSurvey = 1;
                                        this.commonService.showToast('warning', 'No Active Survey Found', {});
                                    }
                                    else if (request[3]['message'] === 'notReleasedForLanguage') {
                                        this.notReleasedSurveyInLanguage = 1;
                                    }
                                    else {
                                        this.questionnaire = request[3]['message'];
                                        this.setQuestionnaireAttributes();
                                        this.notActiveSurvey = 0;
                                    }
                                    result = void 0;
                                    request3 = void 0;
                                    if (!(this.farmerId != 0)) return [3 /*break*/, 23];
                                    if (!navigator.onLine) return [3 /*break*/, 13];
                                    result = request[4];
                                    //If online and farmer id then set farmer consent history
                                    if (request[7] && request[7].msg == 'success') {
                                        this.consentHistory = request[7].data[0];
                                    }
                                    if (this.consentHistory && this.consentHistory.farmer_consent_history.length > 1) {
                                        this.consentHistory.farmer_consent_history.reverse();
                                    }
                                    return [3 /*break*/, 22];
                                case 13:
                                    // If offline, get answers from getQAnswersForFarmer (for this.farmerId) - this is coming from request2
                                    result = { code: 'success' };
                                    qAnswersForFarmer = void 0;
                                    if (!(request2 && request2[0] && request2[0]['message'])) return [3 /*break*/, 14];
                                    // If request2[0] has a message attribute, getQAnswersForFarmer has been called for this farmer before
                                    qAnswersForFarmer = request2[0];
                                    return [3 /*break*/, 21];
                                case 14:
                                    paginationLimitsArray = request[4].message;
                                    paginationLimits = this.getPaginationLimits(paginationLimitsArray, this.farmerId);
                                    if (!this.campaignId) return [3 /*break*/, 16];
                                    return [4 /*yield*/, Promise.all([
                                            this.dynamicQuestionnaireService.getQAnswersForCampaignPaginated(this.campaignId, paginationLimits[0], paginationLimits[1]).toPromise()
                                        ])];
                                case 15:
                                    request3 = _a.sent();
                                    return [3 /*break*/, 20];
                                case 16: return [4 /*yield*/, Promise.all([
                                        this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                                    ])];
                                case 17:
                                    surveyReq = _a.sent();
                                    activeSurveyId = void 0;
                                    if (surveyReq[0]['code'] === 'success' && surveyReq[0]['message'].length > 0) {
                                        activeSnapshot = void 0;
                                        snapshotSurveys = surveyReq[0]['message'].filter(function (item) { return item.survey_type == 'snapshot'; });
                                        if (snapshotSurveys && snapshotSurveys.length > 0) {
                                            activeSnapshot = snapshotSurveys[0]['survey_stage_changelogs'][0]['change_type'] == 'active' ? snapshotSurveys[0] : undefined;
                                            if (activeSnapshot) {
                                                activeSurveyId = activeSnapshot.id;
                                            }
                                        }
                                    }
                                    return [4 /*yield*/, Promise.all([
                                            this.dynamicQuestionnaireService.getSurveyQAssetIdsDashboard(activeSurveyId).toPromise()
                                        ])];
                                case 18:
                                    getQIds = _a.sent();
                                    if (!(getQIds[0]['code'] === 'success')) return [3 /*break*/, 20];
                                    return [4 /*yield*/, Promise.all([
                                            this.dynamicQuestionnaireService.getQAnswersForProjectPaginatedDashboard(this.projectId, paginationLimits[0], paginationLimits[1], getQIds[0]['message']).toPromise()
                                        ])];
                                case 19:
                                    request3 = _a.sent();
                                    _a.label = 20;
                                case 20:
                                    // If getQAnswersForFarmer has not been called for this farmer, get the answer values from getQAnswersForProject by filtering on farmerId
                                    result['message'] = request3[0]['msg'] !== 'blank' ? request3[0]['message'].filter(function (item) { return item.farmer_id === _this.farmerId; }) : [];
                                    _a.label = 21;
                                case 21:
                                    // If getQAnswersForFarmer has been called for this farmer before, compare the timestamp of the two API calls (farmer specific & project-wide data fetch)
                                    // Set the results array as the latest API data fetch
                                    // if (qAnswersForFarmer) {
                                    //       // getQAnswersForFarmer fetch      getQAnswersForProject fetch
                                    //   if (qAnswersForFarmer['ts'] > request3[0]['ts']) {
                                    //     // Set as farmer-specific API fetch
                                    //     console.log("Inside if...");
                                    //     result['message'] = qAnswersForFarmer['message'];
                                    //   } else {
                                    //     // Set as project-wide fetch, filtered by farmerId
                                    //     console.log("request3[0]['message']:", request3[0]['message']);
                                    //     result['message'] = request3[0]['message'].filter(item => item.farmer_id === this.farmerId);
                                    //   }
                                    // }
                                    if (qAnswersForFarmer && request3) {
                                        // getQAnswersForFarmer fetch      getQAnswersForProject fetch
                                        if (qAnswersForFarmer['ts'] > request3[0]['ts']) {
                                            // Set as farmer-specific API fetch
                                            result['message'] = qAnswersForFarmer['message'];
                                        }
                                        else {
                                            // Set as project-wide fetch, filtered by farmerId
                                            result['message'] = request3[0]['message'].filter(function (item) { return item.farmer_id === _this.farmerId; });
                                        }
                                    }
                                    else if (qAnswersForFarmer && !request3) {
                                        result['message'] = qAnswersForFarmer['message'];
                                    }
                                    else if (!qAnswersForFarmer && request3) {
                                        result['message'] = request3[0]['message'].filter(function (item) { return item.farmer_id === _this.farmerId; });
                                    }
                                    _a.label = 22;
                                case 22: return [3 /*break*/, 24];
                                case 23:
                                    result = [];
                                    _a.label = 24;
                                case 24:
                                    if (!(this.farmerId !== 0 && request[5]['msg'] === 'success')) return [3 /*break*/, 28];
                                    if (!navigator.onLine) return [3 /*break*/, 25];
                                    this.farmer = request[5]['data'];
                                    return [3 /*break*/, 27];
                                case 25:
                                    this.farmer = request[5]['data'].find(function (item) { return item.id === _this.farmerId; });
                                    return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                                case 26:
                                    farmertmpobj = _a.sent();
                                    tempFarmer = farmertmpobj.find(function (x) { return x.id === _this.farmerId; });
                                    if (tempFarmer) {
                                        this.farmer = tempFarmer;
                                    }
                                    _a.label = 27;
                                case 27:
                                    this.setVCMapping();
                                    return [3 /*break*/, 30];
                                case 28: return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                                case 29:
                                    farmertmpobj = _a.sent();
                                    rawFarmerKey_1 = this.rawFarmerId.toString() + "0";
                                    tempFarmer = farmertmpobj.find(function (x) { return x.tmp_id === rawFarmerKey_1; });
                                    if (tempFarmer) {
                                        this.farmer = tempFarmer;
                                    }
                                    else {
                                        this.farmer = farmertmpobj.find(function (x) { return x.tmp_id === _this.rawFarmerId; });
                                    }
                                    _a.label = 30;
                                case 30:
                                    if (this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
                                        this.notes = this.farmer.FarmerQuickNotes[0];
                                    }
                                    else {
                                        this.notes = { notes: '', farmer_id: this.farmerId };
                                    }
                                    // Set quick notes
                                    this.setFarmerQuickNotes();
                                    this.setVillageName();
                                    // Set the qAnswers array
                                    console.log("result:", result);
                                    this.setQAnswersAndInitialize(result);
                                    _a.label = 31;
                                case 31:
                                    this.spinner.hide();
                                    if (navigator.onLine && this.farmerId !== 0 && request[6] && request[6]['code'] === 'success') {
                                        this.setSurveysList(request[6].message);
                                    }
                                    if (this.isCompare) {
                                        this.notActiveSurvey = 0;
                                        requestVal = localStorage.getItem('compareSnapshotsRevisionRequestObject');
                                        requestVal = JSON.parse(requestVal);
                                        if (this.revisionType === 'target') {
                                            this.selectedRevision = parseInt(requestVal['targetRevisionId']);
                                        }
                                        else {
                                            this.selectedRevision = parseInt(requestVal['sourceRevisionId']);
                                        }
                                        if (this.selectedRevision !== 0) {
                                            this.changeRevision();
                                        }
                                    }
                                    isDisabled = this.farmer.is_discontinued;
                                    if (isDisabled) {
                                        this.discontinuedFarmer = true;
                                        this.farmerDiscontinueStatus = true;
                                    }
                                    //discontinued farmer survey mapping        
                                    if (navigator.onLine && request[8].msg === 'success') {
                                        this.discontinuedFarmerMapping = request[8].data.filter(function (item) { return item.farmer_id == _this.farmer.id; });
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                });
                return [2 /*return*/];
            });
        });
    };
    Vpd2Component.prototype.setQAnswersAndInitialize = function (result, oldRevisionData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var indexedDBEntries, farmertmpobj, qAnswersForThisFarmerId, i, i, _loop_1, this_1, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.qAnswers = [];
                        indexedDBEntries = [];
                        if (!!oldRevisionData) return [3 /*break*/, 2];
                        return [4 /*yield*/, idbApp.getAllProjects('dq_profiling')];
                    case 1:
                        farmertmpobj = _a.sent();
                        if (farmertmpobj && farmertmpobj.length > 0) {
                            qAnswersForThisFarmerId = farmertmpobj.filter(function (item) { return item.tmp_id === _this.rawFarmerId; });
                            if (qAnswersForThisFarmerId && qAnswersForThisFarmerId.length > 0) {
                                for (i = 0; i < qAnswersForThisFarmerId.length; i++) {
                                    indexedDBEntries = indexedDBEntries.concat(JSON.parse(qAnswersForThisFarmerId[i].values));
                                }
                            }
                        }
                        _a.label = 2;
                    case 2:
                        // Cached values
                        if (result.code === 'success' && result.message.length > 0) {
                            for (i = 0; i < result.message.length; i++) {
                                this.qAnswers.push({
                                    id: result.message[i].id.toString(),
                                    key: result.message[i].questionnaire_asset_id.toString(),
                                    sak_of_modified: result.message[i].sak_of_modified,
                                    secondary_asset_key: result.message[i].secondary_asset_key,
                                    value: result.message[i].answer.toString(),
                                    flex3: result.message[i].flex3,
                                    deleted: result.message[i].deleted,
                                    imgBase64: result.message[i].imgBase64
                                });
                            }
                        }
                        if (!oldRevisionData) {
                            // IndexedDB values
                            if (indexedDBEntries.length > 0) {
                                _loop_1 = function (i) {
                                    // If the indexedDB entry has an id, find the same id from qAnswers and update the value, mark as touched
                                    if (indexedDBEntries[i].id) {
                                        var x = this_1.qAnswers.find(function (item) { return item.id == indexedDBEntries[i].id; });
                                        if (x) {
                                            var xIndex = this_1.qAnswers.indexOf(x);
                                            if (this_1.validIndex(xIndex)) {
                                                this_1.qAnswers[xIndex]['value'] = indexedDBEntries[i]['value'];
                                                this_1.qAnswers[xIndex]['touched'] = true;
                                                if (indexedDBEntries[i]['checked']) {
                                                    this_1.qAnswers[xIndex]['checked'] = indexedDBEntries[i]['checked'];
                                                }
                                                if (indexedDBEntries[i]['unchecked']) {
                                                    this_1.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['unchecked'];
                                                }
                                                if (indexedDBEntries[i]['deleted']) {
                                                    this_1.qAnswers[xIndex]['unchecked'] = indexedDBEntries[i]['deleted'];
                                                    this_1.qAnswers[xIndex]['deleted'] = indexedDBEntries[i]['deleted'];
                                                }
                                                this_1.qAnswers[xIndex]['flex3'] = indexedDBEntries[i]['flex3'];
                                            }
                                        }
                                    }
                                    else {
                                        // Push into qAnswers
                                        var answerToInsert = {
                                            key: indexedDBEntries[i].key.toString(),
                                            sak_of_modified: indexedDBEntries[i].sak_of_modified,
                                            secondary_asset_key: indexedDBEntries[i].secondary_asset_key,
                                            value: indexedDBEntries[i].value.toString(),
                                            touched: true,
                                            checked: indexedDBEntries[i].checked,
                                            flex3: indexedDBEntries[i].flex3,
                                            deleted: indexedDBEntries[i].deleted
                                        };
                                        if (indexedDBEntries[i]['checked']) {
                                            answerToInsert['checked'] = indexedDBEntries[i]['checked'];
                                        }
                                        if (indexedDBEntries[i]['unchecked']) {
                                            answerToInsert['unchecked'] = indexedDBEntries[i]['unchecked'];
                                        }
                                        if (indexedDBEntries[i]['deleted']) {
                                            answerToInsert['unchecked'] = indexedDBEntries[i]['deleted'];
                                        }
                                        this_1.qAnswers.push(answerToInsert);
                                    }
                                };
                                this_1 = this;
                                for (i = 0; i < indexedDBEntries.length; i++) {
                                    _loop_1(i);
                                }
                                this.askUserToConfirmExit = false;
                            }
                        }
                        this.qAnswers = this.qAnswers.filter(function (item) { return item.flex3 === "A" && !item.deleted; });
                        if (this.notActiveSurvey === 0) {
                            if (this.questionnaire && this.questionnaire.length > 0) {
                                // Initialize the questionnaire
                                this.initialize();
                                // Set the sak_of_modified values in this.secondary_asset_key__options object (which checkbox impacted which row / column uuid in a table)
                                this.setSecondaryAssetKeyOptionValues();
                                // Set dynamic options (which crop intercropping questions)
                                this.setDynamicOptionsObject();
                                if (this.questionnaire && this.questionnaire.length > 0) {
                                    this.setAnswerValues();
                                    this.setDisplay();
                                }
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // Naviate to 1st section inside the clicked tab
    Vpd2Component.prototype.tabClicked = function (tabIndex, isFarmerTab) {
        if (!isFarmerTab) {
            this.currentTab = this.questionnaire[tabIndex];
        }
        this.currentTabIndex = tabIndex;
        this.showSummaryForCurrentTabFn();
        this.runTabSummaryCalculate();
        this.scrollToTop();
    };
    // Initialize the questionnaire by navigating to the 1st tab
    Vpd2Component.prototype.initialize = function () {
        this.farmerDetailsTabIndex = this.questionnaire.length + 1;
        this.currentTabIndex = this.farmerDetailsTabIndex;
        this.tabClicked(this.currentTabIndex, true);
    };
    Vpd2Component.prototype.canDoFarmerMgmtFn = function () {
        var _this = this;
        if (this.project && this.project.supplier_project_services_components_mapping && this.project.supplier_project_services_components_mapping.length > 0) {
            this.usersServiceComponentMappings = this.project.supplier_project_services_components_mapping;
        }
        if (this.project && this.project.ecosystem_services_components_mapping && this.project.ecosystem_services_components_mapping.length > 0) {
            this.ecosystemServicesComponentsMapping = this.project.ecosystem_services_components_mapping;
        }
        if (this.currentUser.role.id > 3 && this.usersServiceComponentMappings) {
            var canDoFarmerMgmt = this.usersServiceComponentMappings.filter(function (item) { return (item.services_id == 2 && item.user_type_id == _this.currentUser.role.id); });
            if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
                this.canDoFarmerMgmt = true;
            }
        }
        else if (this.currentUser.role.id <= 3 && this.ecosystemServicesComponentsMapping) {
            var canDoFarmerMgmt = this.ecosystemServicesComponentsMapping.filter(function (item) { return item.services_id == 2; });
            if (canDoFarmerMgmt && canDoFarmerMgmt.length > 0) {
                this.canDoFarmerMgmt = true;
            }
        }
    };
    Vpd2Component.prototype.showNoActiveSnapshotMessage = function () {
        var _this = this;
        this.canDoFarmerMgmtFn();
        setTimeout(function () { return _this.toastr.info(_this.thereNoActiveSnapshotForThisProject); }, 0);
    };
    Vpd2Component.prototype.showSummaryForCurrentTabFn = function () {
        if (this.currentTabIndex != this.farmerDetailsTabIndex && this.currentTab && this.currentTab.displayFunction && this.currentTab.displayFunction.indexOf('showSummary') > -1) {
            this.showSummaryForCurrentTab = true;
        }
        else {
            this.showSummaryForCurrentTab = false;
        }
    };
    Vpd2Component.prototype.runTabSummaryCalculate = function () {
        var _this = this;
        if (this.showSummaryForCurrentTab) {
            this.createProfitabilitySummaryObject();
            if (this.currentSection && this.farmProfitabilitySummaryArray && this.farmProfitabilitySummaryArray.length > 0) {
                var x = this.farmProfitabilitySummaryArray.find(function (item) { return item.sectionId == _this.currentSection.id; });
                var indexOfCurrentSection = this.farmProfitabilitySummaryArray.indexOf(x);
                var sections = JSON.parse(JSON.stringify(this.farmProfitabilitySummaryArray));
                if (indexOfCurrentSection > 0) {
                    sections.splice(indexOfCurrentSection);
                    sections.reverse();
                }
                for (var i = 0; i < this.farmProfitabilitySummaryArray.length; i++) {
                    if (this.currentSection.id == this.farmProfitabilitySummaryArray[i].sectionId) {
                        if (this.currentSection.isIncome) {
                            this.farmProfitabilitySummary.income = {
                                source: this.farmProfitabilitySummaryArray[i].label,
                                amount: this.farmProfitabilitySummaryArray[i].amount
                            };
                            var expenseSection = sections.find(function (item) { return item.isExpense; });
                            if (expenseSection) {
                                this.farmProfitabilitySummary.expense = {
                                    source: expenseSection.label,
                                    amount: expenseSection.amount
                                };
                            }
                        }
                        else if (this.currentSection.isExpense) {
                            this.farmProfitabilitySummary.expense = {
                                source: this.farmProfitabilitySummaryArray[i].label,
                                amount: this.farmProfitabilitySummaryArray[i].amount
                            };
                            var incomeSection = sections.find(function (item) { return item.isIncome; });
                            if (incomeSection) {
                                this.farmProfitabilitySummary.income = {
                                    source: incomeSection.label,
                                    amount: incomeSection.amount
                                };
                            }
                        }
                    }
                }
            }
        }
    };
    Vpd2Component.prototype.createProfitabilitySummaryObject = function () {
        this.farmProfitabilitySummaryArray = [];
        var sections = this.currentTab.sections;
        var totalIncome = 0;
        var totalExpense = 0;
        var agriIncome = 0;
        var otherIncome = 0;
        if (sections && sections.length > 0) {
            for (var i = 0; i < sections.length; i++) {
                if (sections[i].isIncome || sections[i].isExpense) {
                    var amount = this.getSummaryAmount(sections[i]);
                    var amountToSet = amount ? amount : 0;
                    var obj = {
                        index: i,
                        sectionId: sections[i].id,
                        label: sections[i]['survey_q_asset_labels'][0].label,
                        amount: amountToSet,
                        isIncome: sections[i]['isIncome'],
                        isExpense: sections[i]['isExpense'],
                        isAgriIncome: sections[i]['isAgriIncome'],
                        isOtherIncome: sections[i]['isOtherIncome']
                    };
                    this.farmProfitabilitySummaryArray.push(obj);
                    if (obj.isIncome) {
                        totalIncome = totalIncome + parseFloat(amountToSet);
                    }
                    if (obj.isExpense) {
                        totalExpense = totalExpense + parseFloat(amountToSet);
                    }
                    if (obj.isAgriIncome) {
                        agriIncome = agriIncome + parseFloat(amountToSet);
                    }
                    if (obj.isOtherIncome) {
                        otherIncome = otherIncome + parseFloat(amountToSet);
                    }
                }
                this.farmProfitabilitySummary.totalIncome = totalIncome.toString();
                this.farmProfitabilitySummary.totalExpense = totalExpense.toString();
                this.farmProfitabilitySummary.totalProfit = (totalIncome - totalExpense).toString();
                this.farmProfitabilitySummary.agriIncome = agriIncome.toString();
                this.farmProfitabilitySummary.otherIncome = otherIncome.toString();
            }
        }
    };
    Vpd2Component.prototype.resetFarmProfitabilitySummary = function () {
        this.showSummaryForCurrentTab = false;
        this.farmProfitabilitySummary = { income: { source: 'NA', amount: 'NA' }, expense: { source: 'NA', amount: 'NA' }, totalIncome: 'NA', totalExpense: 'NA', totalProfit: 'NA', agriIncome: 'NA', otherIncome: 'NA' };
        this.farmProfitabilitySummaryArray = [];
    };
    Vpd2Component.prototype.getSummaryAmount = function (section) {
        var answerToReturn;
        if (section.displayFunction) {
            var x_1 = JSON.parse(section.displayFunction);
            if (x_1) {
                if (x_1['answerQAsset']) {
                    var y = this.qAnswers.find(function (item) { return item.key == x_1['answerQAsset'] && !item.deleted; });
                    if (y) {
                        answerToReturn = y.value;
                    }
                }
                // This is required for the workers expenditure section
                // Grand total of the daily workers expenses must be added to 12 * number of workers * avg monthly wate
                if (x_1['multiplyFollowing']) {
                    if (x_1['key1'] && x_1['key2']) {
                        var y1 = this.qAnswers.find(function (item) { return item.key == x_1['key1'] && !item.deleted; });
                        var y2 = this.qAnswers.find(function (item) { return item.key == x_1['key2'] && !item.deleted; });
                        if (y1 && y2) {
                            var t = x_1['multiplyFollowing'] * y1.value * y2.value;
                            answerToReturn = answerToReturn ? parseInt(answerToReturn) + t : t;
                        }
                    }
                }
            }
        }
        return answerToReturn;
    };
    Vpd2Component.prototype.setQuestionnaireAttributes = function () {
        if (this.questionnaire && this.questionnaire.length > 0) {
            for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (this.questionnaire[i].sections[j].displayFunction) {
                        var x = JSON.parse(this.questionnaire[i].sections[j].displayFunction);
                        if (x.type && x.type === 'income') {
                            this.questionnaire[i].sections[j]['isIncome'] = true;
                        }
                        else if (x.type && x.type === 'expense') {
                            this.questionnaire[i].sections[j]['isExpense'] = true;
                        }
                        if (x.subtype && x.subtype === 'agri') {
                            this.questionnaire[i].sections[j]['isAgriIncome'] = true;
                        }
                        else if (x.subtype && x.subtype === 'other') {
                            this.questionnaire[i].sections[j]['isOtherIncome'] = true;
                        }
                    }
                }
            }
        }
    };
    Vpd2Component.prototype.setCurrencyCode = function () {
        if (this.project.Currency && this.project.Currency.currency_code) {
            this.currencyCode = this.project.Currency.currency_code;
        }
        else {
            this.currencyCode = "";
        }
    };
    Vpd2Component.prototype.setFarmerQuickNotes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rawFarmerIdQuickNotes, x, whichTab, quickNotesFromDB, quickNotesFromIndexedDB, farmertmpobj;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        x = this.rawFarmerId.substring(0, 2);
                        if (x == 'FA') {
                            whichTab = this.rawFarmerId.substring(this.rawFarmerId.length - 2);
                            if (whichTab == '00') {
                                rawFarmerIdQuickNotes = this.rawFarmerId.slice(0, -5) + "QN";
                            }
                            else {
                                rawFarmerIdQuickNotes = this.rawFarmerId.slice(0, -4) + "QN";
                            }
                        }
                        else {
                            // // farmer was registered online
                            // const p = 10 - this.rawFarmerId.length;
                            // let m = '';
                            // for (let i = 0; i < p; i++) {
                            //   m = `${m}0`;
                            // }
                            rawFarmerIdQuickNotes = this.rawFarmerId + "_QN";
                            // console.log('rawFarmerIdQuickNotes', rawFarmerIdQuickNotes);
                        }
                        quickNotesFromDB = this.farmer.FarmerQuickNotes;
                        if (!!navigator.onLine) return [3 /*break*/, 2];
                        return [4 /*yield*/, idbApp.getAllProjects('farmerstmp')];
                    case 1:
                        farmertmpobj = _a.sent();
                        quickNotesFromIndexedDB = farmertmpobj.filter(function (x) { return x.tmp_id === rawFarmerIdQuickNotes; });
                        _a.label = 2;
                    case 2:
                        // console.log('quickNotesFromIndexedDB', quickNotesFromIndexedDB);
                        if (!this.farmer.FarmerQuickNotes) {
                            this.farmer.FarmerQuickNotes = [];
                        }
                        if (quickNotesFromDB) {
                            this.farmer.FarmerQuickNotes = quickNotesFromDB;
                        }
                        if (quickNotesFromIndexedDB && quickNotesFromIndexedDB.length > 0) {
                            this.farmer.FarmerQuickNotes = quickNotesFromIndexedDB;
                        }
                        if (this.farmer.FarmerQuickNotes && this.farmer.FarmerQuickNotes.length > 0) {
                            this.notes = this.farmer.FarmerQuickNotes[0];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // preProcessProjectUOMs() {
    //   this.processedUOMs = {};
    //   if (this.project && this.project.ProjectUOM && this.project.ProjectUOM.length > 0) {
    //     for (let i = 0; i < this.project.ProjectUOM.length; i++) {
    //       if (!this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]) {
    //         this.processedUOMs[this.project.ProjectUOM[i].unit_type_id] = {};
    //         this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['units'] = [];
    //       }
    //       const projectDefaultUnit = this.project.ProjectUOM.find(item => item.is_default && item.unit_type_id === this.project.ProjectUOM[i].unit_type_id);
    //       this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['projectDefaultUnit'] = projectDefaultUnit;
    //       this.processedUOMs[this.project.ProjectUOM[i].unit_type_id]['units'].push({
    //         id: this.project.ProjectUOM[i].id,
    //         international_unit: this.project.ProjectUOM[i].international_unit,
    //         international_value: this.project.ProjectUOM[i].international_value,
    //         uom: this.project.ProjectUOM[i].uom,
    //         conversionFactorToProjectDefault: this.project.ProjectUOM[i].international_value / projectDefaultUnit.international_value
    //       });
    //     }
    //   }
    // }
    Vpd2Component.prototype.validIndex = function (index) {
        if (index != null && index > -1) {
            return true;
        }
        return false;
    };
    // Set the values in this.secondary_asset_key__options given a qAnswers array
    Vpd2Component.prototype.setSecondaryAssetKeyOptionValues = function () {
        for (var i = 0; i < this.qAnswers.length; i++) {
            if (this.qAnswers[i].sak_of_modified != null) {
                this.secondary_asset_key__options[this.qAnswers[i].secondary_asset_key] = this.qAnswers[i].sak_of_modified;
            }
        }
    };
    // Create the set of dynamic options for the two questions
    // crop intercropping with surveyed crop
    // crop intercropping with surveyed crop (field level)
    Vpd2Component.prototype.setDynamicOptionsObject = function () {
        if (this.questionnaire && this.questionnaire !== 'noSurveys' && this.questionnaire.length > 0) {
            this.dynamicOptionsObject = {};
            for (var i = 0; i < this.questionnaire.length; i++) {
                for (var j = 0; j < this.questionnaire[i].sections.length; j++) {
                    if (this.questionnaire[i].sections[j].tables && this.questionnaire[i].sections[j].tables.length > 0) {
                        var _loop_2 = function (k) {
                            if (this_2.questionnaire[i].sections[j].tables[k].displayFunction && this_2.questionnaire[i].sections[j].tables[k].displayFunction.indexOf("addOptionToQ") > -1) {
                                var tableModifyDetails = JSON.parse(this_2.questionnaire[i].sections[j].tables[k].displayFunction);
                                if (tableModifyDetails && tableModifyDetails.addOptionToQ) {
                                    var addOptionToQ = tableModifyDetails.addOptionToQ.split(",");
                                    var qToAddAsOption_1 = tableModifyDetails.qToAddAsOption;
                                    var existingAnswers_1 = this_2.qAnswers.filter(function (item) { return item.key == qToAddAsOption_1; });
                                    if (existingAnswers_1 && existingAnswers_1.length > 0) {
                                        for (var p = 0; p < addOptionToQ.length; p++) {
                                            var _loop_3 = function (q) {
                                                if (!existingAnswers_1[q].deleted) {
                                                    if (!this_2.dynamicOptionsObject[addOptionToQ[p]]) {
                                                        this_2.dynamicOptionsObject[addOptionToQ[p]] = [];
                                                    }
                                                    if (!this_2.dynamicOptionsObject[addOptionToQ[p]].find(function (item) { return item.sak == existingAnswers_1[q].secondary_asset_key; })) {
                                                        this_2.dynamicOptionsObject[addOptionToQ[p]].push({ q: qToAddAsOption_1, sak: existingAnswers_1[q].secondary_asset_key, label: existingAnswers_1[q].value });
                                                    }
                                                }
                                            };
                                            for (var q = 0; q < existingAnswers_1.length; q++) {
                                                _loop_3(q);
                                            }
                                        }
                                    }
                                }
                            }
                        };
                        var this_2 = this;
                        for (var k = 0; k < this.questionnaire[i].sections[j].tables.length; k++) {
                            _loop_2(k);
                        }
                    }
                }
            }
        }
    };
    /**
     * Set answer values for each section, subsection, table
     */
    Vpd2Component.prototype.setAnswerValues = function () {
        for (var _i = 0, _a = this.questionnaire; _i < _a.length; _i++) {
            var tab = _a[_i];
            for (var _b = 0, _c = tab.sections; _b < _c.length; _b++) {
                var section = _c[_b];
                for (var _d = 0, _e = section.questions; _d < _e.length; _d++) {
                    var question = _e[_d];
                    question = this.setAnswerForQuestionNonTable(question);
                }
                for (var _f = 0, _g = section.subsections; _f < _g.length; _f++) {
                    var subsection = _g[_f];
                    for (var _h = 0, _j = subsection.questions; _h < _j.length; _h++) {
                        var qs = _j[_h];
                        qs = this.setAnswerForQuestionNonTable(qs);
                    }
                }
                for (var _k = 0, _l = section.tables; _k < _l.length; _k++) {
                    var table = _l[_k];
                    this.secondary_asset_keys[table.id] = this.getSecondaryAssetKeysForTable(table);
                    for (var _m = 0, _o = table.questions; _m < _o.length; _m++) {
                        var qt = _o[_m];
                        this.setAnswerForQuestionTable(qt, this.secondary_asset_keys[table.id]);
                    }
                    if (table.displayFunction && table.displayFunction.indexOf('addDefaultCropEntry') > -1) {
                        this.sortDefaultCropToTop(table);
                    }
                }
            }
        }
    };
    /**
     * For NON-TABLE questions only
     * Set the answer value of the question, depending on qa_type
     * Set the answer values of the child other_question, uom_questions
     * @param question
     */
    Vpd2Component.prototype.setAnswerForQuestionNonTable = function (question) {
        var temp;
        var answer;
        var otherAnswer;
        var uomAnswer;
        if (question.qa_subtype === 'text') {
            temp = this.qAnswers.find(function (item) { return item.key == question.id; });
            if (temp) {
                answer = temp.value;
            }
        }
        else if (question.qa_subtype === 'number') {
            temp = this.qAnswers.find(function (item) { return item.key == question.id; });
            if (temp) {
                answer = temp.value;
            }
        }
        else if (question.qa_subtype === 'select') {
            var x_2 = this.qAnswers.find(function (item) { return item.key == question.id; });
            if (x_2) {
                var y = question.options.find(function (item) { return item.id == x_2.value; });
                if (y) {
                    answer = y.survey_q_asset_labels[0].label;
                }
            }
        }
        else if (question.qa_subtype === 'date') {
            temp = this.qAnswers.find(function (item) { return item.key == question.id; });
            if (temp) {
                answer = temp.value;
            }
        }
        else if (question.qa_subtype === 'file') {
            temp = this.qAnswers.find(function (item) { return item.key == question.id; });
            if (temp) {
                answer = temp.value;
            }
        }
        else { // multiselect
            var x_3 = this.qAnswers.filter(function (item) { return item.key == question.id; });
            if (x_3 && x_3.length > 0) {
                if (!question.has_dynamic_options) {
                    var y = question.options.filter(function (item) { return x_3.find(function (each) { return each.value == item.id; }); });
                    if (y && y.length > 0) {
                        var z = y.map(function (item) { return item.survey_q_asset_labels[0].label; });
                        answer = z.join(", ");
                    }
                }
                else {
                    var doo = this.dynamicOptionsObject[question.id];
                    if (doo) {
                        var y = doo.filter(function (item) { return x_3.find(function (each) { return each.value == item.q + '__' + item.sak; }); });
                        if (y && y.length > 0) {
                            var z = y.map(function (item) { return item.label; });
                            answer = z.join(", ");
                        }
                    }
                }
            }
        }
        if (answer) {
            question.answer = answer;
        }
        if (question.other_questions && question.other_questions.length > 0) {
            otherAnswer = this.qAnswers.find(function (item) { return item.key == question.other_questions[0].id; });
            if (otherAnswer) {
                question.otherAnswer = otherAnswer.value;
            }
        }
        if (question.uom_questions && question.uom_questions.length > 0) {
            var _loop_4 = function (uomQ) {
                temp = this_3.qAnswers.find(function (item) { return item.key == uomQ.id; });
                if (temp) {
                    var y = this_3.project.ProjectUOM.find(function (item) { return item.id == temp.value; });
                    if (y && question.answer) {
                        uomQ.answer = y.uom;
                    }
                }
            };
            var this_3 = this;
            for (var _i = 0, _a = question.uom_questions; _i < _a.length; _i++) {
                var uomQ = _a[_i];
                _loop_4(uomQ);
            }
        }
        return question;
    };
    /**
     * For TABLE questions
     * Create an answer object with key = secondary_asset_key and the value = the answer
     * @param question
     * @param secondaryAssetKeys
     */
    Vpd2Component.prototype.setAnswerForQuestionTable = function (question, secondaryAssetKeys) {
        var answer = {};
        var uomQAnswer = {};
        var otherQAnswer = {};
        var _loop_5 = function (sak) {
            var temp;
            var otherAnswer = void 0;
            if (question.qa_subtype === 'text') {
                temp = this_4.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == sak; });
                if (temp) {
                    answer[sak] = temp.value;
                }
            }
            else if (question.qa_subtype === 'number') {
                temp = this_4.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == sak; });
                if (temp) {
                    answer[sak] = temp.value;
                }
            }
            else if (question.qa_subtype === 'select') {
                var x_4 = this_4.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == sak; });
                if (x_4) {
                    var y = question.options.find(function (item) { return item.id == x_4.value; });
                    if (y) {
                        answer[sak] = y.survey_q_asset_labels[0].label;
                    }
                }
            }
            else if (question.qa_subtype === 'date') {
                temp = this_4.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == sak; });
                if (temp) {
                    answer[sak] = temp.value;
                }
            }
            else if (question.qa_subtype === 'file') {
                temp = this_4.qAnswers.find(function (item) { return item.key == question.id && item.secondary_asset_key == sak; });
                if (temp) {
                    answer[sak] = temp.value;
                }
            }
            else { // multiselect
                var x_5 = this_4.qAnswers.filter(function (item) { return item.key == question.id && item.secondary_asset_key == sak; });
                if (x_5 && x_5.length > 0) {
                    if (!question.has_dynamic_options) {
                        var y = question.options.filter(function (item) { return x_5.find(function (each) { return each.value == item.id; }); });
                        if (y && y.length > 0) {
                            var z = y.map(function (item) { return item.survey_q_asset_labels[0].label; });
                            answer[sak] = z.join(", ");
                        }
                    }
                    else {
                        var doo = this_4.dynamicOptionsObject[question.id];
                        if (doo) {
                            var y = doo.filter(function (item) { return x_5.find(function (each) { return each.value == item.q + '__' + item.sak; }); });
                            if (y && y.length > 0) {
                                var z = y.map(function (item) { return item.label; });
                                answer[sak] = z.join(", ");
                            }
                        }
                    }
                }
            }
            if (question.other_questions && question.other_questions.length > 0) {
                otherAnswer = this_4.qAnswers.find(function (item) { return item.key == question.other_questions[0].id && item.secondary_asset_key == sak; });
                if (otherAnswer) {
                    otherQAnswer[sak] = otherAnswer.value;
                    question.otherAnswer = otherQAnswer;
                }
            }
            if (question.uom_questions && question.uom_questions.length > 0) {
                var _loop_6 = function (uomQ) {
                    temp = this_4.qAnswers.find(function (item) { return item.key == uomQ.id && item.secondary_asset_key == sak; });
                    if (temp) {
                        var y = this_4.project.ProjectUOM.find(function (item) { return item.id == temp.value; });
                        if (y) {
                            uomQAnswer[sak] = y.uom;
                        }
                    }
                    uomQ.answer = uomQAnswer;
                };
                for (var _i = 0, _a = question.uom_questions; _i < _a.length; _i++) {
                    var uomQ = _a[_i];
                    _loop_6(uomQ);
                }
            }
        };
        var this_4 = this;
        for (var _i = 0, secondaryAssetKeys_1 = secondaryAssetKeys; _i < secondaryAssetKeys_1.length; _i++) {
            var sak = secondaryAssetKeys_1[_i];
            _loop_5(sak);
        }
        question.answer = answer;
        return question;
    };
    /** Not used. This can be deleted */
    Vpd2Component.prototype.existsInQAnswersForTableQuestion = function (question) {
        return this.qAnswers.filter(function (item) { return item.key == question.id && !item.deleted && !item.unchecked; });
    };
    /**
     * For questions in this table, get all the unique values of secondary_asset_keys from the answers array
     * i.e. get the identifiers of each row / column in the table
     * @param table
     */
    Vpd2Component.prototype.getSecondaryAssetKeysForTable = function (table) {
        var sakArray = [];
        if (table.questions && table.questions.length > 0) {
            var qIds_1 = table.questions.map(function (item) { return item.id.toString(); });
            var answersForQIds = this.qAnswers.filter(function (item) { return qIds_1.indexOf(item.key) > -1; });
            if (answersForQIds && answersForQIds.length > 0) {
                var answersForQIdsSaks = answersForQIds.map(function (item) { return item.secondary_asset_key; });
                if (answersForQIdsSaks && answersForQIdsSaks.length > 0) {
                    sakArray = this.getUniqueValues(answersForQIdsSaks);
                }
            }
        }
        return sakArray;
    };
    /**
     * Get unique elements from the given array
     * @param array
     */
    Vpd2Component.prototype.getUniqueValues = function (array) {
        var uniqueArray = [];
        for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
            var item = array_1[_i];
            if (uniqueArray.indexOf(item) < 0) {
                uniqueArray.push(item);
            }
        }
        return uniqueArray;
    };
    /**
     * For the given farmer, set the name of the village collector if it exists
     */
    Vpd2Component.prototype.setVCMapping = function () {
        if (this.farmer) {
            if (this.farmer.vc_farmer_mappings && this.farmer.vc_farmer_mappings.length > 0) {
                var vcName = '';
                for (var _i = 0, _a = this.farmer.vc_farmer_mappings; _i < _a.length; _i++) {
                    var val = _a[_i];
                    if (val.status === 'Active') {
                        if (vcName.length === 0) {
                            vcName = "" + vcName + val.users.name;
                        }
                        else {
                            vcName = vcName + ", " + val.users.name;
                        }
                    }
                }
                // if (this.farmer.vc_farmer_mappings[0].status == 'Active') {
                this.farmer.village_collector = vcName;
                // }
            }
        }
    };
    /**
     * Return the value of multivalue_master array for the given code
     * @param code
     * @param value
     */
    Vpd2Component.prototype.getMasterData = function (code, value) {
        var answer = this.masterDataFarmer.filter(function (v) {
            return v.master_id == value && v.item_code == code;
        });
        if (answer.length > 0) {
            return answer[0].name;
        }
        else {
            return null;
        }
    };
    /**
     * For each question & question's children, check whether it should be displayed or not, depending on defined
     * display logic and other answers
     */
    Vpd2Component.prototype.setDisplay = function () {
        for (var x = 0; x < this.questionnaire.length; x++) {
            for (var y = 0; y < this.questionnaire[x].sections.length; y++) {
                for (var i = 0; i < this.questionnaire[x].sections[y].questions.length; i++) {
                    this.questionnaire[x].sections[y].questions[i].isDisplayed = this.checkAssetDisplay(this.questionnaire[x].sections[y].questions[i]);
                    if (this.questionnaire[x].sections[y].questions[i].other_questions && this.questionnaire[x].sections[y].questions[i].other_questions.length > 0) {
                        this.questionnaire[x].sections[y].questions[i].other_questions[0].isDisplayed = this.checkOtherAnswerQuestionDisplay(this.questionnaire[x].sections[y].questions[i]);
                    }
                }
                for (var k = 0; k < this.questionnaire[x].sections[y].subsections.length; k++) {
                    this.questionnaire[x].sections[y].subsections[k].isDisplayed = this.checkAssetDisplay(this.questionnaire[x].sections[y].subsections[k]);
                    for (var i = 0; i < this.questionnaire[x].sections[y].subsections[k].questions.length; i++) {
                        this.questionnaire[x].sections[y].subsections[k].questions[i].isDisplayed = this.checkAssetDisplay(this.questionnaire[x].sections[y].subsections[k].questions[i]);
                        if (this.questionnaire[x].sections[y].subsections[k].questions[i].other_questions && this.questionnaire[x].sections[y].subsections[k].questions[i].other_questions.length > 0) {
                            this.questionnaire[x].sections[y].subsections[k].questions[i].other_questions[0].isDisplayed = this.checkOtherAnswerQuestionDisplay(this.questionnaire[x].sections[y].subsections[k].questions[i]);
                        }
                    }
                }
                for (var k = 0; k < this.questionnaire[x].sections[y].tables.length; k++) {
                    this.questionnaire[x].sections[y].tables[k].isDisplayed = this.checkAssetDisplay(this.questionnaire[x].sections[y].tables[k]);
                    for (var i = 0; i < this.questionnaire[x].sections[y].tables[k].questions.length; i++) {
                        if (this.questionnaire[x].sections[y].tables[k].questions[i].other_questions && this.questionnaire[x].sections[y].tables[k].questions[i].other_questions.length > 0) {
                            if (!this.tableOtherQuestionsIsDisplayed) {
                                this.tableOtherQuestionsIsDisplayed = {};
                            }
                            for (var p = 0; p < this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id].length; p++) {
                                this.tableOtherQuestionsIsDisplayed[this.questionnaire[x].sections[y].tables[k].questions[i].other_questions[0].id + '_' + this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id][p]]
                                    = this.checkOtherAnswerQuestionDisplay(this.questionnaire[x].sections[y].tables[k].questions[i], this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id][p]);
                            }
                        }
                    }
                    // TODO: Add logic for questions within the table
                    for (var i = 0; i < this.questionnaire[x].sections[y].tables[k].questions.length; i++) {
                        if (this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id] && this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id].length > 0) {
                            for (var j = 0; j < this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id].length; j++) {
                                this.questionnaire[x].sections[y].tables[k].questions[i][this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id][j] + '_isDisplayed'] =
                                    this.checkAssetDisplay(this.questionnaire[x].sections[y].tables[k].questions[i], this.secondary_asset_keys[this.questionnaire[x].sections[y].tables[k].id][j]);
                            }
                        }
                    }
                }
            }
        }
    };
    /**
      * Check for simple toggle logic in deciding whether to display / hide an asset
      * By default, display the asset if there is no toggle logic defined for it
     * @param asset
     * @param secondary_asset_key
     */
    Vpd2Component.prototype.checkAssetDisplay = function (asset, secondary_asset_key) {
        // console.log('checkAssetDisplay secondary_asset_key', secondary_asset_key);
        // If the asset has any defined toggle logic
        if (asset.survey_q_asset_display_logic && asset.survey_q_asset_display_logic.length > 0) {
            var _loop_7 = function (i) {
                // If the condition is of type "value_matches" - i.e. the asset on which this asset's display depends must have a value that exactly matches the condition
                if (asset.survey_q_asset_display_logic[i].logic_type === 'value_matches') {
                    if (this_5.qAnswers.find(function (item) { return !item.unchecked && item.key == asset.survey_q_asset_display_logic[i].display_if_key && item.value == asset.survey_q_asset_display_logic[i].display_if_value && item.secondary_asset_key == secondary_asset_key; })) {
                        return { value: true };
                    }
                }
                // If the condition is of type "value_matches" - i.e. the asset on which this asset's display depends must have any not-null, & not-blank, & non-zero value
                if (asset.survey_q_asset_display_logic[i].logic_type === 'value_exists') {
                    if (this_5.qAnswers.find(function (item) { return !item.unchecked && item.key == asset.survey_q_asset_display_logic[i].display_if_key && item.value && item.value != '' && item.value != 0 && item.secondary_asset_key == secondary_asset_key; })) {
                        return { value: true };
                    }
                }
            };
            var this_5 = this;
            // if (!secondary_asset_key) {
            // For any of the asset's display logic conditions
            for (var i = 0; i < asset.survey_q_asset_display_logic.length; i++) {
                var state_1 = _loop_7(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
            // If after looping through all display logic conditions the function has not returned true already, then return false
            return false;
            // } else {
            // }
        }
        else {
            // Return true in case no display logic is defined for this asset (visible by default)
            return true;
        }
    };
    /**
     * Check whether the "other" question should be disabled
     * @param question
     * @param secondary_asset_key
     */
    Vpd2Component.prototype.checkOtherAnswerQuestionDisplay = function (question, secondary_asset_key) {
        if (question.other_questions && question.other_questions.length > 0 && question.options && question.options.length > 0) {
            var otherOption_1 = question.options.find(function (item) { return item.qa_subtype == 'other'; });
            if (otherOption_1) {
                if (this.qAnswers.find(function (item) { return !item.unchecked && item.key == question.id && item.value == otherOption_1.id && item.secondary_asset_key == secondary_asset_key; })) {
                    return true;
                }
                return false;
            }
        }
    };
    /**
     * Populate the revisions dropdown based on the project's surveys & revisions
     * @param surveys
     */
    Vpd2Component.prototype.setSurveysList = function (surveys) {
        var _this = this;
        this.snapshotsListDataDropdown = [];
        if (surveys && surveys.length > 0) {
            var snapshotSurveys = surveys.filter(function (item) { return _this.campaignId ? item.survey_type === 'campaign' && parseInt(item.flex1) === parseInt(_this.campaignId)
                : item.survey_type === 'snapshot'; });
            for (var _i = 0, snapshotSurveys_1 = snapshotSurveys; _i < snapshotSurveys_1.length; _i++) {
                var survey = snapshotSurveys_1[_i];
                if (survey.revisions && survey.revisions.length > 0) {
                    survey.revisions = survey.revisions.reverse();
                    for (var _a = 0, _b = survey.revisions; _a < _b.length; _a++) {
                        var revision = _b[_a];
                        var temp = {
                            surveyId: survey.id,
                            revisionId: revision.id,
                            snapshot_name: survey.name + " " + survey.survey_type_sequence.toString() + "." + revision.revision_number
                        };
                        this.snapshotsListDataDropdown.push(temp);
                    }
                }
            }
        }
    };
    /**
     * Change the revision (called when current revision is changed from the dropdown)
     */
    Vpd2Component.prototype.changeRevision = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var survey_1, discontinued, request, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.farmerId != 0)) return [3 /*break*/, 8];
                        this.spinner.show();
                        if (!(this.selectedRevision != 0)) return [3 /*break*/, 2];
                        survey_1 = this.snapshotsListDataDropdown.find(function (item) { return item.revisionId == _this.selectedRevision; }).surveyId;
                        discontinued = this.discontinuedFarmerMapping.find(function (item) { return item.survey_id == survey_1; });
                        if (discontinued) {
                            this.discontinuedFarmer = true;
                            this.spinner.hide();
                            return [2 /*return*/];
                        }
                        else {
                            this.discontinuedFarmer = false;
                        }
                        return [4 /*yield*/, Promise.all([
                                this.surveyService.getQAnswersForFarmerByRevision(this.farmerId, this.selectedRevision).toPromise(),
                                this.surveyService.getSurveyQForProfilingService(survey_1).toPromise(),
                            ])];
                    case 1:
                        request = _a.sent();
                        if (request[0].code === 'success' && request[1].code === 'success') {
                            if (request[0].message && request[0].message.length > 0) {
                                if (request[1]['message'] !== 'notReleasedForLanguage') {
                                    this.questionnaire = request[1]['message'];
                                }
                                else {
                                    this.questionnaire = [];
                                }
                                this.setQuestionnaireAttributes();
                                this.setQAnswersAndInitialize(request[0], true);
                            }
                            else {
                                // added following 3 lines to show empty data
                                if (request[1]['message'] !== 'notReleasedForLanguage') {
                                    this.questionnaire = request[1]['message'];
                                }
                                else {
                                    this.questionnaire = [];
                                }
                                this.setQuestionnaireAttributes();
                                this.setQAnswersAndInitialize([], true);
                                this.commonService.showToast('info', 'no_farmer_data_in_revision', {});
                            }
                        }
                        return [3 /*break*/, 7];
                    case 2:
                        request = void 0;
                        this.discontinuedFarmer = this.farmerDiscontinueStatus;
                        if (!this.campaignId) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.campaignQuestionnaireFlowRevision()];
                    case 3:
                        request = _a.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, Promise.all([
                            this.dynamicQuestionnaireService.getQAnswersForFarmer(this.projectId, this.farmerId).toPromise(),
                            this.surveyService.getSurveyQuestionnaireForProject(this.projectId).toPromise(),
                        ])];
                    case 5:
                        request = _a.sent();
                        _a.label = 6;
                    case 6:
                        if (request[0].code === 'success' && request[1].code === 'success') {
                            this.questionnaire = request[1]['message'];
                            this.setQuestionnaireAttributes();
                            this.setQAnswersAndInitialize(request[0], true);
                        }
                        _a.label = 7;
                    case 7:
                        window.scroll(0, 0);
                        this.spinner.hide();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    Vpd2Component.prototype.getBackProjectFilter = function (projectNameVal, projectId) {
        var url = "/dashboard";
        this.router.navigate([url], { queryParams: { filter: projectNameVal, pId: projectId } });
    };
    Vpd2Component.prototype.getBackCompareData = function () {
        var url = 'charts/comparedata/' + this.projectId;
        this.router.navigate([url], { queryParams: { fromAnswer: true } });
    };
    /**
     * Set the village name of the current farmer
     */
    Vpd2Component.prototype.setVillageName = function () {
        var _this = this;
        var villageAreaList;
        if (this.project.ecosystem_region_master) {
            villageAreaList = this.project.ecosystem_region_master.region_villages;
        }
        if (villageAreaList && villageAreaList.length > 0) {
            var p = villageAreaList.find(function (item) { return item.id == _this.farmer.village_area_id; });
            if (p) {
                this.villageName = p.village_name;
            }
        }
    };
    Vpd2Component.prototype.scrollToTop = function () {
        $('html, body').animate({
            scrollTop: $('#mainDiv').scrollTop = 0
        }, 'slow');
    };
    /**
     * Sort the default crop (the ecosystem crop) to the top of the agri income table
     * @param tableId - Number
    */
    Vpd2Component.prototype.sortDefaultCropToTop = function (table) {
        var tableId = table.id;
        var cropName = this.project.Product.name;
        var displayFunction = JSON.parse(table.displayFunction);
        var x = this.qAnswers.find(function (item) { return item.key == displayFunction.setQId && item.value == cropName; });
        if (x) {
            // Set the value of this.defaultCropSak;
            this.defaultCropSak = x.secondary_asset_key;
            if (this.secondary_asset_keys && this.secondary_asset_keys[tableId] && this.secondary_asset_keys[tableId].length > 0) {
                var x_6 = this.secondary_asset_keys[tableId].indexOf(this.defaultCropSak);
                if (x_6) {
                    if (x_6 === 0) {
                        // do nothing
                        return;
                    }
                    else {
                        var temp = JSON.parse(JSON.stringify(this.secondary_asset_keys[tableId]));
                        var p = temp[0];
                        temp[0] = this.defaultCropSak;
                        temp[x_6] = p;
                        this.secondary_asset_keys[tableId] = JSON.parse(JSON.stringify(temp));
                    }
                }
            }
        }
    };
    Vpd2Component.prototype.campaignQuestionnaireFlow = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                                this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                                this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                                this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
                                this.dynamicQuestionnaireService.getQAnswersForFarmerForCampaign(this.campaignId, this.farmerId).toPromise(),
                                this.farmerService.getFarmerBasicDataById(this.farmerId).toPromise(),
                                this.surveyService.getAllSurveysForProject(this.projectId).toPromise()
                            ])];
                    case 1:
                        r = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, Promise.all([
                            this.projectService.getProjectBasicInfo(this.projectId).toPromise(),
                            this.projectService.getProjectProfilingData(this.projectId).toPromise(),
                            this.projectService.getProjectDashboardData(this.projectId).toPromise(),
                            this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
                            this.dynamicQuestionnaireService.getPaginationLimits(this.projectId).toPromise(),
                            this.farmerService.getFarmersByProjectBasicData(this.projectId).toPromise(),
                        ])];
                    case 3:
                        r = _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/, r];
                }
            });
        });
    };
    Vpd2Component.prototype.setBreadcrumbText = function () {
        var _this = this;
        this.translate.get('breadcrumbTitle').subscribe(function (result) {
            if (_this.campaignId) {
                _this.breadcrumbTitle = result['campaign'];
            }
            _this.breadcrumbTitle = result['project'];
        });
    };
    Vpd2Component.prototype.goToProfilingTable = function () {
        if (this.campaignId) {
            this.router.navigate(["/campaign-profiling-farmers/" + this.projectId + "/" + this.campaignId]);
        }
        else {
            this.router.navigate(["/profiling/" + this.projectId]);
        }
    };
    Vpd2Component.prototype.campaignQuestionnaireFlowRevision = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!navigator.onLine) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.dynamicQuestionnaireService.getQAnswersForFarmerForCampaign(this.campaignId, this.farmerId).toPromise(),
                                this.surveyService.getSurveyQuestionnaireForCampaign(this.campaignId).toPromise(),
                            ])];
                    case 1:
                        r = _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, r];
                }
            });
        });
    };
    /**
     * Find which limits range the given farmerId falls in
     * E.g. if limits = [130, 150, 170] and farmerId = 165
     * limitsToReturn = [150, 170] would be returned
     * @param limits
     * @param farmerId
    */
    Vpd2Component.prototype.getPaginationLimits = function (limits, farmerId) {
        // console.log('limits', limits);
        // console.log('farmerId', farmerId);
        var limitsToReturn = [];
        for (var i = 0; i < limits.length; i++) {
            if (limits[i] <= farmerId && limits[i + 1] && limits[i + 1] > farmerId) {
                limitsToReturn[0] = limits[i];
                limitsToReturn[1] = limits[i + 1];
                return limitsToReturn;
            }
            else if (!limits[i + 1]) {
                limitsToReturn[0] = limits[i];
                limitsToReturn[1] = undefined;
                return limitsToReturn;
            }
        }
    };
    //get farmer consent tempalte
    Vpd2Component.prototype.getFarmerConsentTemplate = function (id, template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requests;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.dataConsentService.getConsentTemplate(this.projectId, id).toPromise()
                        ])];
                    case 1:
                        requests = _a.sent();
                        if (requests[0].msg == 'success' && requests[0].data) {
                            this.consentTemplate = this.sanitizer.bypassSecurityTrustHtml(requests[0].data.template_content);
                            this.privacyPolicyModal = this.modalService.show(template, { backdrop: true, ignoreBackdropClick: true, keyboard: false, class: 'modal-lg-firmenich' });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Vpd2Component.prototype.closePrivacyPolicy = function () {
        if (this.privacyPolicyModal) {
            this.privacyPolicyModal.hide();
        }
    };
    Vpd2Component.prototype.onKeydownHandler = function (event) {
        if (event.key === "Escape") {
            this.closePrivacyPolicy();
        }
    };
    //download uploaded image
    Vpd2Component.prototype.downloadImage = function (qid, sak) {
        var answer;
        if (!sak) {
            answer = this.qAnswers.find(function (item) { return item.key == qid; });
        }
        else {
            answer = this.qAnswers.find(function (item) { return item.key == qid && item.secondary_asset_key == sak; });
        }
        if (answer && answer.imgBase64) {
            this.downloadFile(answer.imgBase64, answer.value);
        }
    };
    Vpd2Component.prototype.downloadFile = function (base64String, fileName) {
        var source = base64String;
        var link = document.createElement("a");
        link.href = source;
        link.download = fileName;
        link.click();
    };
    return Vpd2Component;
}());
export { Vpd2Component };
